import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Scrollbar from 'src/components/scrollbar';

const WeeklyPayrolltable = ({ data }) => {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    return (
        <TableContainer component={Paper}>
            <Scrollbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={2} sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Name</TableCell>
                            <TableCell rowSpan={2} sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Backcharge</TableCell>
                            <TableCell rowSpan={2} sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Union No.</TableCell>
                            <TableCell rowSpan={2} sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>SS#</TableCell>
                            <TableCell rowSpan={2} sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Trade</TableCell>
                            {days.map((day, index) => (
                                <TableCell key={index} colSpan={3} sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>
                                    {day} (Date)
                                </TableCell>
                            ))}
                            <TableCell rowSpan={2} sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Total Hrs</TableCell>
                        </TableRow>
                        <TableRow>
                            {days.map(() => (
                                <>
                                    <TableCell sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Time In</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Out</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Hrs</TableCell>
                                </>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell sx={{ border: '1px solid #e0e0e0' }}>{row.Name}</TableCell>
                                <TableCell sx={{ border: '1px solid #e0e0e0' }}>{row.Backcharge}</TableCell>
                                <TableCell sx={{ border: '1px solid #e0e0e0' }}>{row['Union No.']}</TableCell>
                                <TableCell sx={{ border: '1px solid #e0e0e0' }}>{row['SS#']}</TableCell>
                                <TableCell sx={{ border: '1px solid #e0e0e0' }}>{row.Trade}</TableCell>
                                {days.map(() => (
                                    <>
                                        <TableCell sx={{ border: '1px solid #e0e0e0' }}>08:00</TableCell>
                                        <TableCell sx={{ border: '1px solid #e0e0e0' }}>17:00</TableCell>
                                        <TableCell sx={{ border: '1px solid #e0e0e0' }}>8</TableCell>
                                    </>
                                ))}
                                <TableCell sx={{ border: '1px solid #e0e0e0' }}>{row.TotalHrs || '0'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Scrollbar>
        </TableContainer>
    );
};

export default WeeklyPayrolltable;
