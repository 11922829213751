// src/components/content/RiskRegister.js
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent, IconButton,
  MenuItem,
  Table,
  TableBody, TableContainer, TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import Header from '../header';
import VendorsCreate from './create';
import UserTableRow from './vendors-table-row';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { getCsvData } from '../../risk-register/getCsv';
import { CSVLink } from 'react-csv';
import ImportDilaog from './vendors-import-modal';
import { baseURLORG } from 'src/config-global';

const Vendors = () => {
  const navigate = useNavigate();
  const csvLinkRef = useRef();
  const popover = usePopover();
  const popoverDots = usePopover();

  const handleNavigate = () => {
    navigate('/accounts-management/create-vendor');
  };

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredData, setFilteredData] = useState(tableData);

  const [filterss, setFilterss] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    vendor_type: '',
  });

  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const [showCreateView, setShowCreateView] = useState(false);

  const handleCreateClick = () => {
    setShowCreateView(true);
  };

  const handleBackClick = () => {
    setShowCreateView(false);
  };
  const remove = useBoolean();
  const TABLE_HEAD = [
    { id: 'Sr.no', label: 'Sr.no' },
    { id: 'first_name', label: 'First Name' },
    { id: 'last_name', label: 'Last Name' },
    { id: 'email', label: 'Email' },
    { id: 'title', label: 'Title' },
    { id: 'company_name', label: 'Company Name' },
    { id: 'vendor_type', label: 'Type of Vendors' },

  ];

  let data = [...tableData]

  useEffect(() => {
    Object.keys(filterss).forEach((key) => {
      const filterValue = filterss[key].toLowerCase().trim();
      if (filterValue) {
        data = data.filter((item) =>
          (item[key]?.toLowerCase() || "").includes(filterValue)
        );
      }
    });

    console.log('changes:', data)
    setFilteredData(data);
  }, [filterss, tableData]);
  const handleFilterChange = (key, value) => {
    setFilterss((prev) => ({ ...prev, [key]: value }));
  };



  const table = useTable();
  const router = useRouter();
  const confirm = useBoolean();
  const create = useBoolean();

  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: filteredData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );
  useEffect(() => {
    getVendors();
  }, []);

  const adjustPageAfterDeletion = useCallback(() => {
    const currentPageRowCount = dataFiltered.slice(
      table.page * table.rowsPerPage,
      table.page * table.rowsPerPage + table.rowsPerPage
    ).length;

    if (currentPageRowCount === 0 && table.page > 0) {
      table.onChangePage(table.page - 1);
    } else if (dataFiltered.length === 0) {
      table.onChangePage(0);
    }
  }, [dataFiltered.length, table]);

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []);
  };

  const getVendors = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.vendors.get_list);

    console.log('vendors data is:', data);

    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };

  const deleteCallBack = () => {
    confirm.onFalse();
    getVendors();
  };
  const handleDeleteRows = async () => {
    await deleteRequest(
      `${endpoints.risk_management.incident_reporting.DelIncident}/${selectedItem.id}`,
      deleteCallBack
    );
    adjustPageAfterDeletion(); // Adjust the page after deletion
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const handleBulkDelete = async () => {
    const Ids = table.selected;

    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.incident_reporting.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false);
        adjustPageAfterDeletion();
        setSelectedItem({});
        table.onSelectAllRows(false, []);
        getVendors();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  

  const getCsvHeaders = () => {
    return TABLE_HEAD.filter(({ id }) => id !== 'actions')
      .map(({ id, label }) => ({
        label,
        key: id,
      }));
  };

  const handleExport = () => {
    if (csvLinkRef.current) {
      const csvData = getCsvData(tableData);
      csvLinkRef.current.link.click(csvData);
    }
  };

  return (
    <>
      <div>
        <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
          {' '}
          <CardContent>
            {loading ? (
              <Box height={700}>
                <LoadingScreen />
              </Box>
            ) : (
              <>
                <Header name="Vendors" />
                <Box
                  sx={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#F4F6F8',
                      border: '1px solid #FFD700',
                      padding: '10px 10px',
                      borderRadius: '5px',
                      //margin: '10px 0',
                      marginTop: '15px',
                      marginBottom: '35px',
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        marginLeft: '1px',
                        fontSize: '15px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      <strong>Note:</strong>
                      If you want to create new data, &nbsp;
                      <a
                        href={`https://staging.ezelogs.com/vendors`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', fontWeight: 'bold' }}
                      >
                        click here
                      </a>.

                      This will open the project list,
                      where you can select a Project. Once selected, you will be redirected
                      accordingly.
                    </Typography>
                  </div>
                  <Box sx={{ display: 'flex', gap: '10px' }}>

                    <Button
                      onClick={popover.onOpen}
                      className="dark-button"
                      variant="contained"
                      sx={{ mx: 1 }}
                      startIcon={<Iconify icon="mdi:filter" />}
                    >
                      Filter
                    </Button>

                    {/*
                        <Button
                          className="dark-button"
                          variant="contained"
                          startIcon={<Iconify icon="mingcute:add-line" />}
                          onClick={handleNavigate}

                        >
                          Create
                        </Button>
                        */}

                    <IconButton onClick={popoverDots.onOpen}>
                      <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                  </Box>
                  <CustomPopover
                    open={popoverDots.open}
                    onClose={popoverDots.onClose}
                    arrow="right-top"
                    sx={{ width: 140 }}
                  >
                    {/* <MenuItem
                      onClick={() => {
                        create.onTrue();
                        popoverDots.onClose();
                      }}
                    >
                      <Iconify icon="solar:import-bold" />
                      Import
                    </MenuItem> */}
                    <MenuItem
                      onClick={() => {
                        handleExport();
                        popoverDots.onClose();
                      }}
                    >
                      <Iconify icon="solar:export-bold" />
                      Export
                    </MenuItem>
                  </CustomPopover>

                  <CustomPopover
                    open={popover.open}
                    onClose={popover.onClose}
                    arrow="bottom-bottom"
                    sx={{ width: 1200, padding: 2, background: 'white !important' }}
                  >
                    <MenuItem
                      disableRipple
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 2,
                        color: '#000',
                        background: 'white !important',
                        '&:hover': { backgroundColor: 'white !important' },
                      }}
                    >
                      <Iconify
                        icon="mdi:close"
                        onClick={popover.onClose}
                        sx={{
                          width: 20,
                          height: 20,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          backgroundColor: '#F4F6F8',
                          color: '#637381',
                        }}
                      />

                      <TextField
                        label="First Name"
                        onChange={(e) => handleFilterChange('first_name', e.target.value)}
                        value={filterss.first_name}
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                      <TextField
                        label="Last Name"
                        onChange={(e) => handleFilterChange('last_name', e.target.value)}
                        value={filterss.last_name}
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />

                      <TextField
                        label="Email"
                        onChange={(e) => handleFilterChange('email', e.target.value)}
                        value={filterss.email}
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                   
                      <TextField
                        label="Company Name"
                        onChange={(e) => handleFilterChange('company_name', e.target.value)}
                        value={filterss.company_name}
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                      <TextField
                        label="Type of Vendor"
                        onChange={(e) => handleFilterChange('vendor_type', e.target.value)}
                        value={filterss.type_of_vendor}
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />

                    </MenuItem>
                  </CustomPopover>
                </Box>

                <Card
                  sx={{
                    boxShadow: '0px 0px 30px 0px #64748B1A',
                  }}
                >
                  <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <TableSelectedAction
                      dense={table.dense}
                      numSelected={table.selected.length}
                      rowCount={tableData.length}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                      action={
                        <Tooltip title="Delete">
                          <IconButton color="primary" onClick={confirm.onTrue}>
                            <Iconify icon="solar:trash-bin-trash-bold" />
                          </IconButton>
                        </Tooltip>
                      }
                    />

                    <Scrollbar>
                      <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                        <TableHeadCustom
                          order={table.order}
                          orderBy={table.orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={tableData.length}
                          numSelected={table.selected.length}
                          onSort={table.onSort}
                          onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                              checked,
                              tableData.map((row) => row.id)
                            )
                          }
                        />
                        <TableBody>
                          {dataFiltered
                            .slice(
                              table.page * table.rowsPerPage,
                              table.page * table.rowsPerPage + table.rowsPerPage
                            )
                            .map((row, index) => (
                              <UserTableRow
                                key={row.id}
                                row={row}
                                selected={table.selected.includes(row.id)}
                                onSelectRow={() => table.onSelectRow(row.id)}
                                serialNumber={table.page * table.rowsPerPage + index + 1}
                              //onDeleteRow={() => handleDeleteRow(row.id)}
                              //onEditRow={() => handleEditRow(row.id)}
                              />
                            ))}

                          <TableEmptyRows
                            height={denseHeight}
                            emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                          />

                          <TableNoData notFound={notFound} />
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                  <TablePaginationCustom
                    count={dataFiltered.length}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                  />
                </Card>
              </>
            )}
          </CardContent>
        </Card>
        {
          <CSVLink
            ref={csvLinkRef}
            filename="Vendors_Report.csv"
            headers={getCsvHeaders()}
            data={getCsvData(tableData)}
            style={{ display: 'none' }}
          />
        }

        <VendorsCreate
          open={create.value}
          currentItem={selectedItem}
          onClose={create.onFalse}
          reload={() => getVendors()}
          closeform={() => handleCloseForm()}
        />

        <ImportDilaog
          open={create.value}
          onClose={create.onFalse}
          reload={() => { }}
        //url={endpoints.risk_management.risk_register.identification.importFile}
        />
        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content="Are you want to delete selected item?"
          action={
            <Button
              variant="contained"
              className="dark-button"
              onClick={() => {
                handleDeleteRows();
                confirm.onFalse();
              }}
            >
              Delete
            </Button>
          }
        />

        <ConfirmDialog
          open={remove.value}
          onClose={remove.onFalse}
          title="Delete"
          content={<>Are you sure want to delete these items?</>}
          action={
            <LoadingButton
              loading={deleteLoading}
              variant="contained"
              className="dark-button"
              onClick={() => {
                handleBulkDelete();
              }}
            >
              Delete
            </LoadingButton>
          }
        />
      </div>
    </>
  );
};

export default Vendors;
