import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function UserTableRow({ row, projectId, selected, onEditRow, onSelectRow, onDeleteRow, currentItem, serialNumber }) {
  const { id, name, CSI_code } = row;

  const confirm = useBoolean();
  const popover = usePopover();
  const navigate = useNavigate();

  return (
    <>
      <TableRow hover selected={selected} >
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
        <TableCell>{serialNumber}</TableCell>
        <TableCell
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => navigate(`/accounts-management/generate-materials/${projectId}/${row.id}`)}
        >
          {CSI_code}
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEditRow(row);
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure you want to delete?"
        action={
          <Button
            className="dark-button"
            variant="contained" color="error"
            onClick={() => {
              confirm.onFalse();
              onDeleteRow(row.id); // Pass row.id to onDeleteRow
              console.log(">>>>>>>", row.id)
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};