import { Box, InputAdornment } from "@mui/material";
import { RHFTextField } from "src/components/hook-form";
export default function ActivityFourthRow({icon,showUnitFields,calculationofLabourHrs,calculationofDirectCost}) {
  return (
    <>
    {showUnitFields &&  <RHFTextField
        type="number"
        name="labor_unit_price"
        label="Labor Unit Price"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />}
      <RHFTextField
        onChange={(e)=>{
          calculationofDirectCost(e.target.value,'labor_cost')
          calculationofLabourHrs(e.target.value,'labor_cost')}}
        type="number"
        name="labor_cost"
        label="Labor Cost"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />
    {showUnitFields &&  <RHFTextField
        type="number"
        name="material_unit_price"
        label="Material Unit Price"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />}
      <RHFTextField
             onChange={(e)=>{
              calculationofDirectCost(e.target.value,'material_cost')
             }}
        type="number"
        name="material_cost"
        label="Material Cost"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
