// exportCsvData.js

import { dateformation } from "src/components/date-format-mdy";

export const getCsvData = (tableData = {}) => {
    return tableData?.map((row) => {
        console.log("🚀 ~ returntableData?.map ~ tableData:", tableData)
        const filteredRow = {};
        const fields = {
            customer_id: row?.customer_id,
            project: row?.project?.name,
            chart_of_account: row?.account_chart?.code,
            chart_description: row?.account_chart?.name,
            chart_type: row?.account_chart?.account_type?.name,
            invoice_number: row?.invoice_number,
            invoice_date: dateformation(row?.invoice_date),
            due_date: dateformation(row?.due_date),
            description: row?.description,
            total: row?.total,
            outstanding_balance: row?.outstanding_balance,
            note: row?.note,
            status: row?.status === 1 ? "Yes" : "No",
        };
        Object.keys(fields).forEach((key) => {
            if (key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};
