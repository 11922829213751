// exportCsvData.js

export const getCsvData = (tableData = {}) => {
    return tableData?.map((row) => {
        const filteredRow = {};
        const fields = {
            'amount_received': row?.amount_paid,
            'Date of Receipt': row?.date_of_receipt,
            'payment Method': row?.payment_method,
            'file': row?.receipt?.url ? row.receipt.url : 'No File',
            'Cheque Number': row?.cheque_number,
            'date of cheque': row?.date_of_cheque,
            'bank name': row?.bank?.bank_name,
            'notes': row?.notes,
            'balance received': row?.balance_to_paid,
            'days left': row?.days_left,
        };

        Object.keys(fields).forEach((key) => {
            if (key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};
