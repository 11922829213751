import { Box, InputAdornment, MenuItem } from "@mui/material";
import TradeWorkTitle from "src/components/activity-modals/trade-work-title";
import DropDownAddValueCustom from "src/components/drop-down-add-value/drop-down-add-value-custom";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
export default function ActivityThirdRow({ icon, onTradeAdd,showNumberOfWorkers,showTradeWorkAndHourlyRate,calculationofLabourHrs}) {
  const tradeWorkTitle = useBoolean();

  return (
    <>
      {showNumberOfWorkers && <RHFTextField type="number" name="number_of_workers" label="Number of Workers" />}
    {showTradeWorkAndHourlyRate && 
    
    <>
    <DropDownAddValueCustom
        name="trade_work_titles"
        label={`Trade / Work Titles (To Calculate Labor Hrly Rate)`}
        onClick={onTradeAdd}
      // options={projectStageOptions}
      // reload={() => reloadProjectStageOptions()}
      // url={endpoints.risk_management.risk_register.identification.project_stages}
      />
      <RHFSelect name="shift_rate_type" label="Shift Rate Type">
        <MenuItem value="item 1">
          {'Item 1'} {/* map items here */}
        </MenuItem>
      </RHFSelect>
    </>
      }

      <RHFTextField
        disabled={showTradeWorkAndHourlyRate}
        type="number"
        onChange={(e)=>calculationofLabourHrs(e.target.value,'labor_hourly_rate')}
        name="labor_hourly_rate"
        label="Labor Hourly Rate"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />
      <TradeWorkTitle isOpen={tradeWorkTitle.value} handleClose={tradeWorkTitle.onFalse} />
    </>
  );
}
