import { useEffect, useState, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { formatDate, handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
import { DatePicker } from '@mui/x-date-pickers';
import DropDownAddValue from 'src/components/drop-down-add-value/drop-down-add-value';
import EmployeeUserAdd from './employe-drop-down';

const ExpensesCreate = ({ open, onClose, reload, currentItem, closeform,
    employeeOptions,
    departmentsOptions,
    categoryOptions,
    createdby,
    vendorsList,
    projectList,
    costcenterList,
    chartsAccountList, customerList

}) => {
    console.log("🚀 ~ chartsAccountList:", chartsAccountList)
    console.log("🚀 ~ currentItem:", currentItem)
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [charges, setcharges] = useState('Billable');
    const [type, setType] = useState('expense');
    const [debitcreadit, setdebitcreadit] = useState('Debit');



    const NewUserSchema = Yup.object().shape({
        //project_id: Yup.string().required('Project ID is required'),
        //vendor_id: Yup.string().required('Vendor ID is required'),
        //employee_id: Yup.string().required('Employee ID is required'),
        //department_id: Yup.string().required('Department ID is required'),
        //expense_category_id: Yup.string().required('Expense Category ID is required'),
        //expense_subcategory_id: Yup.string().required('Expense Subcategory ID is required'),
        //cost_center_id: Yup.string().required('Cost Center ID is required'),
        //remarks: Yup.string().required('Remarks are required'),
    });

    const defaultValues = useMemo(
        () => ({
            date: currentItem?.date ? new Date(currentItem.date) : new Date(),
            company_name: currentItem?.company_name || '',
            project_id: currentItem?.project || '',
            description: currentItem?.description || '',
            payment_method: currentItem?.payment_method || '',
            debit: currentItem?.debit || '',
            credit: currentItem?.credit || '',
            charges: currentItem?.charges || '',

    employee_id: currentItem?.employee || '',

        department_id: currentItem?.department_id || '',
            expense_category_id: currentItem?.expense_category_id || '',
                expense_subcategory_id: currentItem?.expense_subcategory_id || '',
                    account_chart_id: currentItem?.account_chart_id || '',
                        financial_statement: currentItem?.financial_statement || '',
                            cost_center_id: currentItem?.cost_center_id || '',
                                receipt: currentItem?.receipt || null,
                                    amount: currentItem?.amount || '',
                                        tax_code: currentItem?.tax_code || '',
                                            expense_type: currentItem?.expense_type || '',
                                                approval_required: currentItem?.approval_required || '',
                                                    status: currentItem?.status || '',
                                                        notes: currentItem?.notes || '',
        }),
[currentItem]
    );

const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
});
const {
    reset,
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
} = methods;


const selectedChart = watch('account_chart_id'); // Watching the `payment_method` field
useEffect(() => {
    if (selectedChart) {
        const { name, account_type } = selectedChart;
        setValue("charts_of_description", name || "");
        setValue("account_type_id", account_type?.name || "");
    } else {
        setValue("charts_of_description", "");
        setValue("account_type_id", "");
    }
}, [selectedChart, setValue]);


useEffect(() => {
    reset(defaultValues);
}, [currentItem, reset, defaultValues]);

useEffect(() => {
    getProfile();
}, []);
const getProfile = async () => {
    try {
        const data = await getRequest(endpoints.profile_info);
        setCompanyName(data.company_name || '');
    } catch (error) {
        console.error('Error fetching profile: ', error);
    }
};
useEffect(() => {
    setValue('company_name', companyName);
}, [companyName, setValue, open]);

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const formData = new FormData();
        console.log("🚀 ~ onSubmit ~ formData:", formData);
        formData.append('date', formatDate(data.date));
        formData.append('company_name', data.company_name); // Company Name
        formData.append('project_id', data.project_id.id); // Project Name
        formData.append('description', data.description); // Expense Description
        formData.append('payment_method', data.payment_method); // Payment Method

        if (debitcreadit === 'Credit') {
            formData.append('credit', data.amount || 0); // Pass amount in credit
            formData.append('debit', ''); // Ensure debit is empty
        } else if (debitcreadit === 'Debit') {
            formData.append('debit', data.amount || 0); // Pass amount in debit
            formData.append('credit', ''); // Ensure credit is empty
        } else {
            enqueueSnackbar({
                variant: 'error',
                message: 'Please select either Credit or Debit.',
            });
            setLoading(false);
            return; // Stop submission if neither is selected
        }

        formData.append('charges', data.charges); // Expense Type
        type === 'expense'
            ? formData.append('vendor_id', data?.vendor_id?.id)
            : formData.append('customer_id', data?.customer_id?.id);

        if (data.receipt) {
            formData.append('receipt', data.receipt[0]); // Assuming data.receipt is an array of files
        }
        formData.append('employee_id', data.employee_id);
        formData.append('account_chart_id', data.account_chart_id); // Charts of Account
        if (data.receipt) {
            formData.append('receipt', data.receipt[0]); // Assuming data.receipt is an array of files
        }
        formData.append('employee_id', data.employee_id);

        formData.append('account_chart_id', data.account_chart_id); // Charts of Account

        try {
            const response = await postRequest(
                currentItem?.id
                    ? `${endpoints.accounting.expenses.post_list}/${currentItem.id}?_method=PUT`
                    : endpoints.accounting.expenses.post_list,
                formData,
                true
            );
            if (response.metadata && response.metadata.success) {
                reset();
                onClose();
                closeform();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response?.message || 'An error occurred.');
            }
        } catch (err) {
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });



const cahrgesOptions = [
    { label: 'Billable', value: 'Billable' },
    { label: 'Non-Billable ', value: 'Non-Billable ' },

];

const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
};
const handleType = (e) => {
    setType(e.target.value)
}
const paymentMethod = watch('payment_method'); // Watching the `payment_method` field


return (
    <Dialog
        open={open}
        onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
                onClose();
            }
        }}
        fullWidth
        maxWidth="md"
        sx={{
            borderRadius: 'none',
            padding: '60px',
            fontSize: '13px',
            fontFamily: 'arial',
        }}
        transitionDuration={{
            enter: theme.transitions.duration.shortest,
            exit: theme.transitions.duration.shortest - 80,
        }}
    >
        <FormProvider methods={methods} onSubmit={onSubmit}>

            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <span
                        className='cardColor'
                        style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                        &nbsp;&nbsp;  Expenses  &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  
                </Box>
                <IconButton onClick={onClose}>
                    <Iconify icon="mdi:close" />
                </IconButton>
            </DialogTitle>


            <DialogContent
                sx={{
                    borderRadius: '1px',
                }}
            >
                  <RHFSelect name="type" value={type} sx={{ width: '100px', height: '40px', '& .MuiInputBase-root': { height: '40px' ,marginTop:"2px"} }} onChange={handleType}>
                        <MenuItem value='expense'>Expense</MenuItem>
                        <MenuItem value='income'>Income</MenuItem>
                    </RHFSelect>
                <h2>Base Fields</h2>
                <Divider />
                <Box
                    mt={2}
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                    }}
                >
                    <Controller
                        name="date"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <DatePicker
                                label={`Date ${error ? '*' : ''}`}
                                value={field.value}
                                onChange={(newValue) => {
                                    field.onChange(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: !!error,
                                        helperText: error?.message,
                                    },
                                }}
                            />
                        )}
                    />
                    <RHFTextField name="company_name" label="Company Name" disabled />
                    <RHFAutocomplete
                        name="project_id"
                        label="Project Name"
                        options={projectList}
                        getOptionLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) => option === value}
                        renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                    />

                    <RHFTextField name="description" label="Expenses / Income Description" />
                    {paymentMethod === 'Credit Card' ? (
                        <RHFTextField name="credit" label="Amount" type="number" />
                    ) : (
                        <RHFTextField name="debit" label=" Amount" type="number" />
                    )}
                    <RHFAutocomplete
                        name="payment_method"
                        label="Payment Method"
                        options={['Cash', 'Check', 'Credit Card', 'Bank Transfer', 'Debit Card', 'Mobile Pameent(eg, PayPal,Venmo)']}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) => option === value}
                        renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                        error={!!methods.formState.errors.approval_status}
                        helperText={methods.formState.errors.approval_status?.message}
                    />
                    <RHFAutocomplete
                        name="credit"
                        label="Credit/Debit"
                        options={['Credit', 'Debit']}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) => option === value}
                        renderOption={(props, option) => (
                            <li {...props} key={option}>
                                {option}
                            </li>
                        )}
                        value={debitcreadit}
                        onChange={(_, value) => {
                            setdebitcreadit(value); // Update state
                            methods.setValue('credit', value); // Update React Hook Form value
                        }}
                        error={!!methods.formState.errors.credit}
                        helperText={methods.formState.errors.credit?.message}
                    />

                    <RHFAutocomplete
                        name="charges"
                        label="Charges"
                        options={cahrgesOptions}
                        value={cahrgesOptions.find(option => option.value === charges)}
                        getOptionLabel={(option) => option?.label || ''}
                        onChange={(e, value) => setcharges(value ? value.value : '')}
                    />

                    {type === "expense" ?
                        (
                            <RHFAutocomplete
                                name="vendor_id"
                                label="Vendor Name"
                                options={vendorsList}
                                getOptionLabel={(option) => option?.name || ''}
                                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                renderOption={(props, option) => option?.name ? <li {...props} key={option.name}>{option.name}</li> : null}
                                error={!!methods.formState.errors.vendor_id}
                                helperText={methods.formState.errors.vendor_id?.message}
                            />
                        ) : (

                            <RHFAutocomplete
                                name="customer_id"
                                label="Customer List"
                                options={customerList}
                                getOptionLabel={(option) => option?.name || ''}
                                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                renderOption={(props, option) => option?.name ? <li {...props} key={option.name}>{option.name}</li> : null}
                                error={!!methods.formState.errors.vendor_id}
                                helperText={methods.formState.errors.vendor_id?.message}
                            />
                        )}


                    <div >
                        <div>
                            <input
                                type="file"
                                name="receipt"
                                label="Receipt"
                                className="form-control"
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    fontSize: '14px',
                                    marginTop: '0px',
                                    width: '400px',
                                    height: '50px',
                                }}
                                onChange={(e) => setValue('receipt', e.target.files)} // Update form value with selected file
                            />

                        </div>
                    </div>
                    <EmployeeUserAdd
                        name="employee_id"
                        label="Employee Name"
                        options={employeeOptions}
                    // reload={() => reloadRiskOwnersOptions()}
                    //url={endpoints.risk_management.risk_register.identification.risk_owners}
                    // onRiskOwnerChange={handleOwnerChange}
                    // onChange={handleOwnerChange}
                    //value={currentItem?.risk_owner?.id}
                    />
                    {/*<RHFAutocomplete
                            name="employee_id"
                            label="Paid by"
                            options={createdby}
                            getOptionLabel={(option) => option?.name || ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                        />*/}
                </Box>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        marginTop: '35px',
                        marginLeft: '25px',
                    }}
                >
                    <span
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                        }}
                    >
                        Additional Information
                    </span>
                    <input
                        type="checkbox"
                        style={{ width: '16px', height: '16px' }}
                        onChange={handleCheckboxChange}
                        checked={isChecked}
                    />
                </div>
                {isChecked && (
                    <>
                        <Box

                            mt={2}
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            style={{ marginTop: '10px' }}
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                            }}
                        >

                            <RHFTextField name="employee_id" label="Employee ID" type='number' />
                            <DropDownAddValue
                                name="department_id"
                                label={`Department ${methods.formState.errors.project_stage ? '*' : ''}`}
                                options={departmentsOptions}
                                onChange={(selectedOption) => {
                                    methods.setValue('department_id', Number(selectedOption?.id || 0)); // Set department_id as a number
                                }}
                                url={endpoints.accounting.expenses.add_departments_list}
                            />
                            <DropDownAddValue
                                name="expense_category_id"
                                label={`Category ${methods.formState.errors.project_stage ? '*' : ''}`}
                                options={categoryOptions}
                                onChange={(selectedOption) => {
                                    methods.setValue('expense_category_id', Number(selectedOption?.id || 0)); // Ensure numeric ID
                                }}
                                url={endpoints.accounting.expenses.add_category_list}
                            />
                            <DropDownAddValue
                                name="expense_subcategory_id"
                                label={`Sub Category ${methods.formState.errors.project_stage ? '*' : ''}`}
                                onChange={(selectedOption) => {
                                    methods.setValue('expense_subcategory_id', Number(selectedOption?.id || 0));
                                }}
                                url={endpoints.risk_management.risk_register.identification.project_stages}
                            />
                            <RHFAutocomplete
                                name="account_chart_id"
                                label="Charts of Account"
                                options={chartsAccountList}
                                getOptionLabel={(option) => `${option?.code} - ${option?.name}` || ''}
                                isOptionEqualToValue={(option, value) => option?.code === value?.code}
                                renderOption={(props, option) =>
                                    option?.code ? (
                                        <li {...props} key={option.code}>
                                            {option.code}
                                        </li>
                                    ) : null
                                }
                                onChange={(_, value) => {
                                    methods.setValue("charts_of_description", value?.description || ""); // Update description
                                    methods.setValue("account_type_id", value?.accountType || "");       // Update account type
                                }}
                                error={!!methods.formState.errors.account_chart_id}
                                helperText={methods.formState.errors.account_chart_id?.message}
                            />

                            <RHFTextField name="charts_of_description" label="Charts of Description" />
                            <RHFTextField name="account_type_id" label="Account Type" />

                            <RHFTextField name="financial_statement" label="Financial Statement" />
                            <DropDownAddValue
                                name="cost_center_id"
                                label={`Cost Center (if Applicable) ${methods.formState.errors.project_stage ? '*' : ''}`}
                                options={costcenterList}
                                onChange={(selectedOption) => {
                                    methods.setValue('department_id', Number(selectedOption?.id || 0)); // Set department_id as a number
                                }}
                                url={endpoints.accounting.expenses.add_departments_list}
                            />

                            <RHFTextField name="tax_code"
                                label="Tax Code" />
                            <RHFAutocomplete
                                name="expense_type"
                                label="Expense Type"
                                options={['(none)', 'Travel Expense', 'Office Supplies',
                                    'Meals and Entertainment', 'Traning & Education', 'Rent & Utilities',
                                    'Equipment Maintenance', 'Material Miantenance', 'Other']}
                                getOptionLabel={(option) => option}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                                error={!!methods.formState.errors.approval_status}
                                helperText={methods.formState.errors.approval_status?.message}
                            />
                            <RHFAutocomplete
                                name="approval_required"
                                label="Approval Required"
                                options={[
                                    { id: 1, label: 'Yes' },
                                    { id: 0, label: 'No' },
                                ]}
                                getOptionLabel={(option) => option?.label || ''}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.label}
                                    </li>
                                )}
                                onChange={(_, value) => {
                                    methods.setValue('approval_required', value?.id); // Pass the ID
                                }}
                                value={methods.getValues('approval_required') || null}
                                error={!!methods.formState.errors.approval_required}
                                helperText={methods.formState.errors.approval_required?.message}
                            />

                            <RHFAutocomplete
                                name="status"
                                label="Status"
                                options={[
                                    { id: 1, label: 'Yes' },
                                    { id: 0, label: 'No' },
                                ]}
                                getOptionLabel={(option) => option?.label || ''}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.label}
                                    </li>
                                )}
                                onChange={(_, value) => {
                                    methods.setValue('status', value?.id); // Pass the ID
                                }}
                                value={methods.getValues('status') || null}
                                error={!!methods.formState.errors.status}
                                helperText={methods.formState.errors.status?.message}
                            />



                        </Box>

                        <Box style={{ marginTop: '10px' }}>
                            <RHFTextField
                                name="notes"
                                label="Notes/Remarks"
                                multiline
                                rows={4}
                            />
                        </Box>
                    </>
                )}

            </DialogContent>

            <DialogActions>
                <Button variant="outlined" color="inherit" onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton type="submit" variant="contained" className='dark-button'
                    loading={isSubmitting}>
                    {currentItem.id ? "Upadte" : "Submit"}
                </LoadingButton>
            </DialogActions>
        </FormProvider>
    </Dialog>
);
};
export default ExpensesCreate;