// components/GanttView.js
import { useCallback, useEffect, useRef, useState } from 'react';
import { gantt } from '@dhx/trial-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import "../../assets/gant/dhtmlxgantt.css"
import AddEditTask from './new-task-popup';
import AddEditCategory from './new-category-popup';
import { Box, Button, Card, Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useRouter } from 'src/routes/hooks';
import Iconify from '../iconify';
import Label from '../label';
import { USER_STATUS_OPTIONS } from 'src/_mock';
import { defaultConfigureGantt } from './components/gantt-configs/GanttConfigDefault';
import { configureZoom } from './components/GanttZoom';
import { configureLightbox } from './components/GanttLightbox';
import { allColumns, generateColumns } from './components/GanttColumns';
import { histogramConfigureGantt } from './components/gantt-configs/GanttConfigHistogram';
export default function GanttView({ gantt_resources, reload }) {
  const router = useRouter();
  const container = useRef();
  const [categoryModal, setCategoryModal] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [resourceView, setResourceView] = useState('week');
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [anchorColsEl, setAnchorColsEl] = useState(null);
  const openColsMenu = Boolean(anchorColsEl);
  const [currentTab, setCurrentTab] = useState('week');
  const [currentTab2, setCurrentTab2] = useState('Default View');
  const [visibleColumns, setVisibleColumns] = useState({
    wbs: false,
    text: true,
    start_date: true,
    finish_date: false,
    duration: true,
    total_duration: false,
    add: true,
    predecessor: false,
    successor: false,
    budgeted_amount: false,
    budgeted_hours: false,
    performed_amount: false,
    performed_hours: false,
    percentage: false,
    totalSlack: false,
    freeSlack: false,
    actual_start_date: false,
  });



  useEffect(() => {
    gantt.plugins({ wbs: true, resource: true });  
    gantt.config.columns = generateColumns(visibleColumns, gantt);
    gantt.config.resource_store = 'resources';
    gantt.config.resource_render = 'tree';
    defaultConfigureGantt(gantt, () => generateColumns(visibleColumns, gantt));
 
    configureZoom(gantt, resourceView);
    configureLightbox(gantt, setSelectedTask, setCategoryModal, router, setTaskModal);

    gantt.init(container.current);
    gantt.parse(gantt_resources);

    return () => {
      gantt.clearAll();
    };
  }, [gantt_resources, resourceView, router]);

  useEffect(() => {
    gantt.config.columns = generateColumns(visibleColumns, gantt);
    gantt.render();
  }, [visibleColumns]);

  const handleZoomIn = () => {
    gantt.ext.zoom.zoomIn();
  };

  const handleZoomOut = () => {
    gantt.ext.zoom.zoomOut();
  };

  const handleSwitchToDayView = () => {
    setResourceView('day');
    gantt.ext.zoom.setLevel('day');
    gantt.render();
  };

  const handleSwitchToWeekView = () => {
    setResourceView('week');
    gantt.ext.zoom.setLevel('week');
    gantt.render();
  };

  const handleSwitchToMonthView = () => {
    setResourceView('month');
    gantt.ext.zoom.setLevel('month');
    gantt.render();
  };

  const handleCloseCategoryModal = () => {
    setCategoryModal(false);
    setSelectedTask(null);
  };

  const handleCloseTaskModal = () => {
    setTaskModal(false);
    setSelectedTask(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewSelect = (viewHandler) => {
    viewHandler();
    handleMenuClose();
  };

  const handleColsMenuClick = (event) => {
    setAnchorColsEl(event.currentTarget);
  };

  const handleColsMenuClose = () => {
    setAnchorColsEl(null);
  };

  const handleColumnChange = (event) => {
    setVisibleColumns({
      ...visibleColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const TABS = [
    {
      value: 'day',
      icon: <Iconify icon="solar:phone-bold" width={24} />,
      label: 'Day View',
    },
    {
      value: 'week',
      icon: <Iconify icon="solar:heart-bold" width={24} />,
      label: 'Week View',
    },
    {
      value: 'month',
      icon: <Iconify icon="solar:heart-bold" width={24} />,
      label: 'Month View',
    },
  ];

  
    const handleChangeTab = useCallback((event, newValue) => {
      if(newValue=='day'){
        handleViewSelect(handleSwitchToDayView)
      }else if(newValue=='week'){
        console.log('week')
        handleViewSelect(handleSwitchToWeekView)
      }else{
        handleViewSelect(handleSwitchToMonthView)
      }
      setCurrentTab(newValue);
    }, []);

    useEffect(() => {
      if(currentTab2=='Default View'){
        defaultConfigureGantt(gantt, () => generateColumns(visibleColumns, gantt));
      }else{
        histogramConfigureGantt(gantt, () => generateColumns(visibleColumns, gantt));
      }
      gantt.resetLayout();
    }, [currentTab2]);

    const handleChangeTab2 = useCallback((event, newValue) => {
      setCurrentTab2(newValue);
    }, []);
  return (
    <>
      <Card
          sx={{
            width: '100%',
            margin: 'auto',
            mt: 4,
            // p: 2,
            borderRadius:"5px",
            // borderLeft:"10px solid #FFBDAD",
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
        <Box  sx={{display:"flex",justifyContent:"space-between",p:2}}>
        <Typography variant="h4">Gantt Chart</Typography>
        <Box display="flex" alignItems="center" gap={2}>
        <Box display="flex" alignItems="center">
       <IconButton
          variant="outline"
          onClick={handleColsMenuClick}
          aria-label="Columns"
        >
          <Iconify icon="material-symbols:filter-list-rounded" width="24" height="24" />
        </IconButton>
          <Typography ml={1} fontSize={16}>Settings</Typography>

          <Menu
          anchorEl={anchorColsEl}
          open={openColsMenu}
          onClose={handleColsMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {allColumns.map(col => (
            <MenuItem key={col.name} style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibleColumns[col.name]}
                    onChange={handleColumnChange}
                    name={col.name}
                  />
                }
                label={col.label}
              />
            </MenuItem>
          ))}
        </Menu>
       </Box>
       <hr style={{height:"20px"}}/>
          <Box>
          <IconButton
          variant="contained"
          onClick={handleZoomIn}
          aria-label="Zoom In"
        >
          <Iconify icon="material-symbols:zoom-in" width="24" height="24" />
        </IconButton>

        <IconButton
          variant="contained"
          onClick={handleZoomOut}
          aria-label="Zoom Out"
        >
          <Iconify icon="material-symbols:zoom-out" width="24" height="24" />
        </IconButton>
     
          </Box>
    
        </Box>
        </Box>
        
        <Stack m={0.5} spacing={2} sx={{ width: 1,pl:2}}>
              <Tabs value={currentTab} onChange={handleChangeTab}>
                {TABS.map((tab) => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>


            </Stack>
            <Stack sx={{position:"relative"}}>
              <Box sx={{position:"absolute",zIndex:"1",top:"57.5%",left:"20px"}}>
              <Tabs value={currentTab2} onChange={handleChangeTab2}>
                {[{label:"Default View",value:'Default View'},{label:"Histogram View",value:'Histogram View'}].map((tab) => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>
              </Box>
           <Box className="pink-gantt" ref={container} sx={{ width: '100%',     height: '800px',}}></Box>
            </Stack>
          </Card>
      <AddEditCategory open={categoryModal} onClose={handleCloseCategoryModal} item={selectedTask} reload={reload} />
      <AddEditTask open={taskModal} onClose={handleCloseTaskModal} />
    </>
  );
}
