import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { IconButton } from '@mui/material';
import FileAttachment from 'src/components/file-thumbnail/file-attachments';

// ----------------------------------------------------------------------


export default function UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, serialNumber }) {
    const {
        number,
        floor,
        name_of_room,
        qr_and_barcode,
        iot_details,
        description_of_work,
        frequency_of_maintenance,
        supplier,
        parts_of_replace,
        what_tools_are_needed,
        what_consumeables_are_needed,
        location_of_parts,
        performed_by,
        estimated_time_to_perform,
        do_you_need_to_schedule_third_party,
        remarks,
        asset_file
    } = row;
    const confirm = useBoolean();
    const popover = usePopover();
    return (
        <>
            <TableRow hover selected={selected} >
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow} />
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell>{number}</TableCell>
                <TableCell>{floor}</TableCell>
                <TableCell>{name_of_room}</TableCell>
                <TableCell>{qr_and_barcode}</TableCell>
                <TableCell>{iot_details}</TableCell>
                <TableCell>{description_of_work}</TableCell>
                <TableCell>{frequency_of_maintenance}</TableCell>
                <TableCell>{supplier?.name}</TableCell>
                <TableCell>{parts_of_replace}</TableCell>
                <TableCell>{ }</TableCell>
                <TableCell>{what_consumeables_are_needed}</TableCell>
                <TableCell>{what_tools_are_needed}</TableCell>
                <TableCell>{location_of_parts}</TableCell>
                <TableCell>{performed_by?.name}</TableCell>
                <TableCell>{estimated_time_to_perform}</TableCell>
                <TableCell>{do_you_need_to_schedule_third_party}</TableCell>
                <TableCell>{remarks}</TableCell>
                <TableCell >
                    {asset_file?.url ? (
                        <a href={asset_file?.url} target="_blank" rel="noopener noreferrer">
                            <FileAttachment imageView file={asset_file?.url} sx={{ width: 28, height: 28 }} />
                        </a>
                    ) : (
                        "No File"
                    )}
                </TableCell>
                <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>
            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
            >
                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onEditRow(row);
                        console.log(">>>>>>>", row)
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>
            </CustomPopover>
            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content="Are you sure you want to delete?"
                action={
                    <Button
                        variant="contained" color="error"
                        onClick={() => {
                            confirm.onFalse();
                            onDeleteRow(row.id);
                            console.log(">>>>>>>", row.id)
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
}
UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};