import { dateformation } from "src/components/date-format-mdy";

// exportCsvData.js
export const getCsvData = (tableData = {}) => {
    return tableData?.map((row) => {
        console.log("🚀 ~ returntableData?.map ~ tableData:", tableData)
        const filteredRow = {};
        const fields = {
            'period_thru': dateformation(row?.period_thru),
            'scheduled_amount': row?.sov_grand_total?.schedule_value,
            'payment_due': dateformation(row?.payment_due),
            'previous_periods_amount': row?.sov_grand_total?.work_completed_before,
            'this_period_amount': row?.sov_grand_total?.work_completed_this_duration,
            'work_stored_materials': row?.sov_grand_total?.materials_stock,
            'total_completed_and_stored': row?.sov_grand_total?.total_completed_plus_stock,
            'comp': `${row?.sov_grand_total?.percentage_of_planned_work}%`,
            'balance_to_completion': row?.sov_grand_total?.balance_to_finish,
            'retainage': row?.sov_grand_total?.retainage_of_work_completed,
        };
        Object.keys(fields).forEach((key) => {
            if (key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};
