// src/components/content/RiskRegister.js
import { useEffect, useState, useRef } from 'react';

import Header from '../header';
import {
  Select,
  MenuItem,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TextField,
  TableContainer,
  Tooltip,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getInvoiceByProjectName, getRequest, getRequestDummy, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate, useParams } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// import { getCsvData } from './getCsv';

import { LoadingButton } from '@mui/lab';

import { Typography } from '@mui/material';
// import SettingModal from './invoice/settings-modal';
import UserTableRow from './generate-material-table-row';
import ProjectList from '../../project-list/project-list-modal';
import ImportDilaog from '../../import-modal';
import GenerateMaterialCreate from './generate-material-create';

const GenerateMaterial = () => {
  const navigate = useNavigate();

  const { id, projectId } = useParams();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [projectList, setprojectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('All');

  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const popover = usePopover();
  const popoverDots = usePopover();

  const TABLE_HEAD = [
    { id: 'No.#', label: 'No.#' },
    { id: 'CSI Code', label: 'CSI Code' },
    { id: 'Material Code', label: 'Material Code' },
    { id: 'Material Description', label: 'Material Description' },
    { id: 'Unit', label: 'Unit' },
    { id: 'Quantity', label: 'Quantity' },
    { id: 'Unit Price', label: 'Unit Price' },
    { id: 'Material Cost', label: 'Material Cost' },
    { id: 'Type', label: 'Type' },
    { id: 'Submittle Number', label: 'Submittal Number' },
    { id: 'Status', label: 'Status' },
    //{ id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();
  const add = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();
  const modal = useBoolean();



  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });

  const csvLinkRef = useRef();

  const dataFiltered = applyFilter({
    inputData: tableData.length > 0 ? tableData : [],
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  console.log("🚀 ~ Customers ~ tableData:", tableData)
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  console.log("🚀 ~ Customers ~ dataInPage:", dataInPage)
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;


  useEffect(() => {
    getReportList(selectedProject);
    // getProjects();

  }, []);

  const getInvoice = async (projectId) => {
    setLoading(true);
    const endpoint = projectId && projectId !== 'All'
      ? `${endpoints.accounting.account_receiveable.invoice.get_list}?project_id=${projectId}`
      : endpoints.accounting.account_receiveable.invoice.get_list;

    const data = await getRequest(endpoint);
    console.log('🚀 ~    ~ data:', data);
    setLoading(false);
    if (data) {
      setTableData(data?.invoices);
    }
  };

  const getByProjectList = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.account_receiveable.invoice.get_list);
    console.log('🚀 ~ getInvoice ~ data:', data);
    setLoading(false);
    if (data) {
      setTableData(data?.invoices);
    }
  };

  const getReportList = async () => {
    setLoading(true)
    const data = await getRequestDummy(`/performance/performance-material-categories/${id}/performance-materials`, projectId);
    console.log('🚀 ~ getProjets ~ data:', data);
    setLoading(false)
    if (data) {
      setTableData(data);

    }
  };

  const handleChange = (event) => {
    const projectId = event.target.value;
    setSelectedProject(projectId);
    getInvoice(projectId);
  };
  const deleteCallBack = () => {
    confirm.onFalse();
    getInvoice();
  };

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []);
  };

  const handleDeleteRows = async (id) => {
    await deleteRequest(
      `${endpoints.risk_management.risk_change_request.crud}/${selectedItem.id}`,
      deleteCallBack
    );
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  };

  // const getCsvHeaders = () => {
  //   return TABLE_HEAD.filter(({ id }) => id !== 'actions').map(({ id, label }) => ({
  //     label,
  //     key: id,
  //   }));
  // };

  // const handleExport = () => {
  //   if (csvLinkRef.current) {
  //     const csvData = getCsvData(tableData);
  //     csvLinkRef.current.link.click(csvData);
  //   }
  // };

  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log('🚀 ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_change_request.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false);
        getInvoice();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  return (
    <>
      <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
        {' '}
        <CardContent>
          {loading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
              <Header name="Generate Material" />
              {/* <div
                style={{
                  backgroundColor: '#F4F6F8',
                  border: '1px solid #FFD700',
                  padding: '10px 10px',
                  borderRadius: '5px',
                  //margin: '10px 0',
                  marginTop: '15px',
                  marginBottom: '35px',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    marginLeft: '1px',
                    fontSize: '15px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  <strong>Note:</strong>
                  If you want to create new data,{' '}
                  <span
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => add.onTrue()}
                  >
                    Click here
                  </span>{' '}
                  This will open the project list, where you can select a Project. Once selected, you will be redirected accordingly.
                </Typography>

              </div> */}

              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '20px',
                  justifyContent: 'space-between',
                  gap: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '5px 0',
                  }}
                >
                  {/* <h5
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#333',
                      margin: '0',
                      textTransform: 'none',
                    }}
                  >
                    Project Name
                  </h5>
                  <Select
                    sx={{ minWidth: 65, height: 35 }}
                    value={selectedProject}
                    onChange={handleChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {projectList?.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select> */}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: '20px',
                    justifyContent: 'flex-end',
                    gap: '12px',
                  }}
                >
                  {/* <Button
                    onClick={popover.onOpen}
                    className="dark-button"
                    variant="contained"
                    sx={{ mx: 1 }}
                    startIcon={<Iconify icon="mdi:filter" />}
                  >
                    Filter
                  </Button> */}
                  <Button
                    onClick={create.onTrue}
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                  >
                    Create
                  </Button>
                  <IconButton onClick={popoverDots.onOpen}>
                    <Iconify icon="eva:more-vertical-fill" />
                  </IconButton>
                </Box>
              </Box>
              <CustomPopover
                open={popoverDots.open}
                onClose={popoverDots.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
              >
                {/* <MenuItem
                  onClick={() => {
                    create.onTrue();
                    popoverDots.onClose();
                  }}
                >
                  <Iconify icon="solar:import-bold" />
                  Import
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    // handleExport();
                    popoverDots.onClose();
                  }}
                >
                  <Iconify icon="solar:export-bold" />
                  Export
                </MenuItem>
              </CustomPopover>

              <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="bottom-bottom"
                sx={{ width: 1000, padding: 2, background: 'white !important' }}
              >
                <MenuItem
                  disableRipple
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    color: '#000',
                    background: 'white !important',
                    '&:hover': { backgroundColor: 'white !important' },
                  }}
                >
                  <Iconify
                    icon="mdi:close"
                    onClick={popover.onClose}
                    sx={{
                      width: 20,
                      height: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      backgroundColor: '#F4F6F8',
                      color: '#637381',
                    }}
                  />

                  <TextField
                    label="Customer ID"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    sx={{
                      flex: 1,
                      background: 'white !important',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />

                  <TextField
                    label="First Name"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Last Name"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />

                  <TextField
                    label="Email"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Phone No."
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />
                </MenuItem>
              </CustomPopover>

              <Card
                sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                }}
              >
                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                  <TableSelectedAction
                    dense={table.dense}
                    numSelected={table.selected.length}
                    rowCount={tableData.length}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                    action={
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={confirm.onTrue}>
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                  <Scrollbar>
                    <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                      <TableHeadCustom
                        order={table.order}
                        orderBy={table.orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={tableData.length}
                        numSelected={table.selected.length}
                        onSort={table.onSort}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                      />
                      <TableBody>
                        {dataFiltered
                          .slice(
                            table.page * table.rowsPerPage,
                            table.page * table.rowsPerPage + table.rowsPerPage
                          )
                          .map((row, index) => (
                            <UserTableRow
                              key={row.id}
                              row={row}
                              selected={table.selected.includes(row.id)}
                              onSelectRow={() => table.onSelectRow(row.id)}
                              // onDeleteRow={() => handleDeleteRow(row.id)}
                              // onEditRow={() => handleEditRow(row)}
                              serialNumber={table.page * table.rowsPerPage + index + 1}
                            />
                          ))}
                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                        />
                        <TableNoData notFound={notFound} />
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
        </CardContent>
      </Card>
      <ProjectList
        open={add.value}
        onClose={add.onFalse}
      />
      <GenerateMaterialCreate
        open={create.value}
        onClose={create.onFalse}
        // reload={() => getInvoice()}
        currentItem={selectedItem}
        closeform={() => handleCloseForm()}
      />

      {/* <ImportDilaog
        open={create.value}
        onClose={create.onFalse}
        reload={() => { }}
        url={endpoints.risk_management.risk_register.identification.importFile} /> */}
      {/* <SettingModal
        open={modal.value}
        onClose={modal.onFalse}
      //onSave={handleSaveSettings}
      /> */}
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you want to delete selected items?"
        action={
          <Button
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
      <ConfirmDialog
        open={remove.value}
        onClose={remove.onFalse}
        title="Delete"
        content={<>Are you sure want to delete these items?</>}
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleBulkDelete();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};
export default GenerateMaterial;
