import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import AddEditActivity from 'src/components/Gantt/activity-page/activity-page';
import MainGantt from 'src/components/Gantt/main';
import { LoadingScreen } from 'src/components/loading-screen';
import MainDashboardLayout from 'src/layouts/dashboard/main-layout';

const contentComponents = [
  { name: "Activity", route: "/create-edit-activity", component: AddEditActivity },
];

export const ganttChartRoutes = [
  {
    path: '/gantt-chart',
    element: (

        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
    ),
    children: [
      { element:
      <MainDashboardLayout isFixed={true}>
        
        <MainGantt />
      </MainDashboardLayout>
        , index: true },
      ...contentComponents.flatMap(({ route, component: Component, subRoutes }) => {
        const mainRoute = {
          path: route.slice(1),
          element: (
            <MainDashboardLayout>
        
        <Component />
          </MainDashboardLayout>
           
          ),
        };

        const subRouteElements = subRoutes ? subRoutes.map(({ route: subRoute, component: SubComponent }) => ({
          path: `${route.slice(1)}${subRoute}`,
          element: (
            <MainDashboardLayout>
        
        <SubComponent />
          </MainDashboardLayout>
            
          ),
        })) : [];
        return [mainRoute, ...subRouteElements];
      }),
    ],
  },
];