import { Switch, Button } from '@mui/material';
import { useState } from 'react';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router';

const CreateEmployee = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [is_Checked, setIs_Checked] = useState(false);
  const [isCheckedOne, setIsCheckedOne] = useState(false);
  const [riskIds, setRiskIds] = useState([]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/accounts-management/employees');
  };

  const handleCheckboxChangeOne = (event) => {
    setIsCheckedOne(event.target.checked);
  };

  const handleToggle = (event) => {
    setIsCheckedOne(event.target.checked); // Update the state
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckbox_Change = (event) => {
    setIs_Checked(event.target.checked);
  };

  const NewUserSchema = Yup.object().shape({
    incident_id: Yup.string().required('Incident Id is required'),
    incident_description: Yup.string().required('Incident Description is required'),
    date_and_time_of_incident: Yup.mixed().required('Date And Time Of Inciden is required'),
    impacted_areas: Yup.string().required('Impacted Areas is required'),
    immediate_actions_taken: Yup.string().required('Immediate Actions Taken is required'),
    incident_severity: Yup.string().required('Incident Severity is required'),
  });

  const defaultValues = useMemo(() => {
    const relatedRisk = { id: '', risk_id: 'None' };
    return {
      incident_id: '',
      incident_description: '',
      date_and_time_of_incident: new Date(),
      impacted_areas: '',
      immediate_actions_taken: '',
      incident_severity: '',
      is_related: relatedRisk,
    };
  }, [riskIds]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // Reset form values when `currentItem` changes
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const values = watch();

  useEffect(() => {
    reset({
      incident_id: '',
      incident_description: '',
      date_and_time_of_incident: new Date(),
      impacted_areas: '',
      immediate_actions_taken: '',
      incident_severity: '',
      is_related: { id: '', risk_id: 'None' },
    });
  }, [reset]);
  const onSubmit = handleSubmit(async (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);
  });
  return (
    <div style={{ marginLeft: '15px' }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <h2>Base Field</h2>
          <Button variant="contained" className="dark-button" onClick={handleNavigate}>
            Back
          </Button>
        </div>
      
      </FormProvider>
    </div>
  );
};
export default CreateEmployee;
