import React, { useEffect, useState, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { MenuItem } from '@mui/material';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
import { useParams } from 'react-router';
import { simpleHeader } from 'src/utils/axios';
import { baseURL } from 'src/config-global';
import axios from 'axios';
const GenerateEquipmentCreate = ({ open, onClose, reload, currentItem, closeform }) => {

    const theme = useTheme();

    const { projectId } = useParams()

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [riskIds, setRiskIds] = useState([]);
    const [TradeWorkers, setTradeWorkers] = useState([]);

    const NewUserSchema = Yup.object().shape({
        CSI_code: Yup.string().required('CSI Code is required'),
        equipment_code: Yup.string().required('Equipment Code is required'),
        equipment_description: Yup.string().required('Equipment Description is required'),
        unit: Yup.string().required('Unit is required'),
        unit_price: Yup.number().required('Unit Price is required'),
        quantity: Yup.number().required('Quantity is required'),
        cost_type: Yup.string().required('Cost Type is required'),
        operating_cost: Yup.number().required('Operating Cost is required'),
        rental_cost: Yup.number().nullable(),
        own_cost: Yup.number().nullable(),
        odometer_reading: Yup.string().nullable(),
        serial_number: Yup.string().nullable(),
        location: Yup.string().required('Location is required'),
        operated_by: Yup.string().required('Operator is required'),
        hours: Yup.number().required('Hours is required'),
    });


    const defaultValues = useMemo(
        () => ({
            CSI_code: currentItem?.CSI_code || '',
            equipment_code: currentItem?.equipment_code || '',
            equipment_description: currentItem?.equipment_description || '',
            unit: currentItem?.unit || '',
            unit_price: currentItem?.unit_price || '',
            quantity: currentItem?.quantity || '',
            cost_type: currentItem?.cost_type || '',
            operating_cost: currentItem?.operating_cost || '',
            rental_cost: currentItem?.rental_cost || '',
            own_cost: currentItem?.own_cost || '',
            odometer_reading: currentItem?.odometer_reading || '',
            serial_number: currentItem?.serial_number || '',
            location: currentItem?.location || '',
            operated_by: currentItem?.operated_by || '',
            hours: currentItem?.hours || '',
        }),
        [currentItem]
    );
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [currentItem, reset, defaultValues]);

    useEffect(() => {
        getTradeWorkers(projectId)
    }, []);
 
    //fetching tradeWorkers
    const getTradeWorkers = (projectId) => {
        axios.get(`${baseURL}/trade-worker-titles?project_id=${projectId}`, {
            headers: {
                ...simpleHeader,
                currentProjectId: projectId
            }
        })
            .then(response => {
                const mytradeworkers = response.data.payload.map(item => ({
                    id: item.id,
                    name: item.name
                }));
                setTradeWorkers(mytradeworkers);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };


    // Handle form submission
    const onSubmit = handleSubmit(async (data) => {

        setLoading(true);
        const formData = new FormData();

        formData.append('risk_id', data.risk_id.id);
        formData.append('request_id', data.request_id);
        formData.append('requested_changes', data.requested_changes);
        formData.append('reason_for_change', data.reason_for_change);
        formData.append('requested_by', data.requested_by);
        formData.append('approval_status', data.approval_status);
        try {
            const response = await postRequest(
                currentItem?.id
                    ? `${endpoints.risk_management.risk_change_request.crud}/${currentItem.id}?_method=PUT`
                    : endpoints.risk_management.risk_change_request.crud,
                formData,
                true
            );
            if (response.metadata && response.metadata.success) {
                reset();
                onClose();
                closeform();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response?.message || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });

    const closeAndReset = () => {
        reset();
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeAndReset();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>

                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
                    <span
                        className='cardColor'
                        style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                        &nbsp;&nbsp;  CREATE EQUIPMENTS  &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <IconButton onClick={closeAndReset}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        borderRadius: '1px',
                    }}
                >
                    <Box
                        mt={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >

                        <RHFTextField name="CSI_code" label="CSI Code" required />
                        <RHFTextField name="equipment_code" label="Equipment Code" required />
                        <RHFTextField name="equipment_description" label="Equipment Description" required />
                        <RHFTextField name="unit" label="Unit" required />
                        <RHFTextField name="serial_number" label="Serial Number(if applicable)" />
                        <RHFTextField name="unit_price" type="number" label="Unit Price" required />
                        <RHFSelect name="cost_type" label="Cost Type">
                            <MenuItem value="rental">Rental</MenuItem>
                            <MenuItem value="own">Own</MenuItem>
                        </RHFSelect>
                        <RHFTextField name="rental_cost" type="number" label="Rental Cost" />
                        <RHFTextField name="own_cost" type="number" label="Own Cost" />
                        <RHFTextField name="operating_cost" type="number" label="Operating Cost" required />
                        <RHFTextField name="odometer_reading" label="Odometer Reading" />
                        <RHFTextField name="quantity" type="number" label="Quantity" required />
                        <RHFSelect
                            name="operated_by"
                            label="Operated By"
                        >
                            {TradeWorkers.map((worker) => (
                                <MenuItem key={worker.id} value={worker.id}
                                    sx={{ width: '400px' }}
                                >
                                    {worker.name}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField name="hours" type="number" label="Hours" required />
                        <RHFSelect name="location" label="Location">
                            <MenuItem value="office">Office Address</MenuItem>
                            <MenuItem value="yard">Yard Address</MenuItem>
                            <MenuItem value="job_site">Job Site Address</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </RHFSelect>

                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={closeAndReset}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" className='dark-button'
                        loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
export default GenerateEquipmentCreate;