import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import Checkbox from '@mui/material/Checkbox';
import { useRouter } from 'src/routes/hooks';
import ActivityFirstRow from './activity-first-row';
import ActivitySecondRow from './activity-second-row';
import ActivityThirdRow from './activity-third-row';
import ActivityFourthRow from './activity-fourth-row';
import ActivityFifthRow from './activity-fifth-row';
import ActivitySixthRow from './activity-sixth-row';
import ActivityCalculationRow from './activity-calculation-row ';
import ActivityScheduleRow from './activity-schedule-row ';
import ActivityAssigntaskRow from './activity-assign-task-row ';
import DiscountModal from 'src/components/activity-modals/discount-modal';
import { useBoolean } from 'src/hooks/use-boolean';
import MarkupModal from 'src/components/activity-modals/markup-modal';
import EscalationModal from 'src/components/activity-modals/escalation-modal';
import TaxModal from 'src/components/activity-modals/tax-modal';
import ContingencyModal from 'src/components/activity-modals/contingency-modal';
import TradeModal from 'src/components/activity-modals/trades-modal';
import TradeWorkTitle from 'src/components/activity-modals/trade-work-title';
import axios from 'axios';
import { LoadingScreen } from 'src/components/loading-screen';
import { baseURLStaging } from 'src/config-global';
import { calculationOfDirectCost, calculationOfDirectUnitPrice, calculationOfLabourHrs, calculationOfMarkupBidUnitPrice, calculationOfMarkupCost } from './utils/calculation';
import { addDays } from 'date-fns';
// ----------------------------------------------------------------------
export default function AddEditActivity() {
  const [loading, setLoading] = useState(false);

  const markup = useBoolean();
  const contingency = useBoolean();
  const escalation = useBoolean();
  const tax = useBoolean();
  const discount = useBoolean();
  const trade = useBoolean();
  const tradeWork = useBoolean();
  const [settingLoading, setSettingLoading] = useState(false);
  const [allow, setAllow] = useState(null);
  const [checkBoxOptions, setCheckBoxOptions] = useState({
    isCalculation: false,
    isSchedule: false,
    isAssignedTask: false,
  });
  let AttachMoneyIcon = (
    <Iconify icon="mdi:dollar" style={{ fontSize: '25px', marginTop: '7px' }} />
  );
  let PercentIcon = (
    <Iconify icon="mdi:percent-outline" style={{ fontSize: '25px', marginTop: '7px' }} />
  );
  let AccessTimeIcon = '';
  const router = useRouter();

  // Validation schema
  const NewUserSchema = Yup.object().shape({
    activity: Yup.string().required('Cost Code Activity is required'),
    description: Yup.string().required('Cost Code Task Description is required'),
    quantity: Yup.string().required('Quantity is required'),
    unit: Yup.string().required('Unit is required'),
    labor_hourly_rate: Yup.string().nullable(),
    labor_cost: Yup.string().nullable(),
    material_cost: Yup.string().nullable(),
    equipment_cost: Yup.string().nullable(),
    subcontractor_cost: Yup.string().nullable(),
    other_cost: Yup.string().nullable(),
    mark_up_percent: Yup.string().nullable(),
    labor_hours: Yup.string().nullable(),
    direct_unit_price: Yup.string().nullable(),
    direct_cost: Yup.string().nullable(),
    mark_up_cost: Yup.string().nullable(),
    bid_amount: Yup.string().nullable(),
    bid_unit_price: Yup.string().nullable(),
    predecessor_activities: Yup.string().nullable(),
    successor: Yup.string().nullable(),
    duration:
      checkBoxOptions.isSchedule === true
        ? Yup.string().required('Duration is required')
        : Yup.string().nullable(),
    start_date: Yup.string().nullable(),
    end_date: Yup.string().nullable(),
  });

  const defaultValues = useMemo(
    () => ({
      // name: '',
      activity: '',
      description: '',
      quantity: '',
      unit: '',
      labor_hourly_rate: '',
      labor_cost: '',
      material_cost: '',
      equipment_cost: '',
      subcontractor_cost: '',
      other_cost: '',
      mark_up_percent: '',
      labor_hours: '',
      direct_unit_price: '',
      direct_cost: '',
      mark_up_cost: '',
      bid_amount: '',
      bid_unit_price: '',
      predecessor_activities: '',
      successor: '',
      duration: 1,
      start_date: new Date(),
      end_date: new Date(),
    }),
    []
  );

  function handleCheckBox(e) {
    const name = e.target.name;
    const isChecked = e.target.checked;
    name === 'calculation'
      ? setCheckBoxOptions({ ...checkBoxOptions, isCalculation: isChecked })
      : name === 'schedule'
      ? setCheckBoxOptions({ ...checkBoxOptions, isSchedule: isChecked })
      : name === 'assign' && setCheckBoxOptions({ ...checkBoxOptions, isAssignedTask: isChecked });
  }

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);



  const onSubmit = handleSubmit(async (value) => {
    console.log(value);
  });
  useEffect(() => {
    getCostCodeSettings();
  }, []);


  const getCostCodeSettings = async () => {
    setSettingLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiNDc1ZDNhNDczZmNjY2VhZDJhM2IyNDIyMzk5YzM0NWEzOTk4NDZkMGQwNmMwNmI0NmQ1Zjc3NDkzZTU2NWU3NDRjOTgzZDg5NDIxMTdjMDIiLCJpYXQiOjE3MzQ1ODk5MzYuNDg5NjY0LCJuYmYiOjE3MzQ1ODk5MzYuNDg5NjY4LCJleHAiOjE3NjYxMjU5MzYuNDgxNzE4LCJzdWIiOiIyNjIwIiwic2NvcGVzIjpbXX0.i9s3SM4O0F9y4ONo1RZyFUWg6ZoKdw1NZzfxuSzibFFg27tKI67pCJmuGvuYVpbsblpaeZI1O7hfiAq5pvyt26hz-SXSe5xp9IOYqtX3ER0beXdVkQZZKrxUwPHL6eUIBSSMNIxkLDlwIKNRiXKmwAxICly8Z1IqU0-WQeqBjfrpwWynjk_7uVzAJGBdozZpAh43P2k5dQ06d-cqs8qrQ5PNbVkE8vUP0I5J8-EAXRRn-BnX6_v8JBZdF6KTubN3FTvARG8csWFTU1b_dJPYMTslm3XP_hd4M_cIc7gMC71cy24Nh70iro9PaeJoiz9m1HNZSuO-y6P9L7lspCwyvWXAWEYpCHpdtuZ-DKknTXq4EV_qjrOmBHegjAfcqyigFtFyTfH1xL52FPM2AlagwOr0MGlNAEJnT5lgOFvN-vCyNpWpSKfDoNrW1lb_P3hoOtmJqPYn6Ie5zb3QJNCJ1fOHqKO56sZqaKNPYvnYrwkeuPEaqyvxDxYSTZ8Vh0ZFtNeLZ6x1vTD-8J1jivf5dsgXYzV7OTNeIS39JRhboe2b-3uurMLx_MwphEcH0kkljXpnNcQKVJKnTioTyxA03uXT5Gaokp0GMGbYAsSPmiy_zwHjfHpkVPenmQRBDmKblAR0rvMPPnD2Ao9G1OHrTQkZru8O7soULWdvVt0pOU0`,
          currentProjectId: 1297,
          currentCompanyId: 2620,
        },
      };
      const response = await axios.get(`${baseURLStaging}/gantt-chart-cost-codes`, config);

      if (response.data && response.data.payload) {
        setSettingLoading(false);
        let apiData = response.data.payload;
        setAllow(apiData?.metadata?.cost_code_settings);
      }
    } catch (error) {
      console.error('Error fetching Gantt chart data:', error);
    } finally {
      setSettingLoading(false);
    }
  };


  const calculationofLabourHrs = useCallback(
    calculationOfLabourHrs(setValue, getValues),
    [setValue, getValues]
  );
  const calculationofDirectCost = useCallback(
    calculationOfDirectCost(setValue, getValues),
    [setValue, getValues]
  );
  const calculationofDirectUnitPrice = useCallback(
    calculationOfDirectUnitPrice(setValue, getValues),
    [setValue, getValues]
  );
  const calculationofMarkupCost = useCallback(
    calculationOfMarkupCost(setValue, getValues),
    [setValue, getValues]
  );
  const calculationofMarkupBidUnitPrice = useCallback(
    calculationOfMarkupBidUnitPrice(setValue, getValues),
    [setValue, getValues]
  );

  const handleChangeDuration = useCallback(
    (event, index) => {
      const duration = Number(event.target.value);
      if (!isNaN(duration) && typeof duration === 'number') {
        console.log(duration,"here is dureatiom")
        const startDate = getValues('start_date');
        if (startDate) {
          const endDate = addDays(new Date(startDate), duration);
          setValue('duration', duration);
          setValue('end_date', endDate);
        } else {
        }
      } else {
      }
    },
    [setValue, getValues]
  );
  const handleStartDateChange = useCallback(
    (value) => {
      const duration=getValues('duration')
      if (!isNaN(duration) && typeof duration === 'number') {
        const startDate = getValues('start_date');
        if (startDate) {
          const endDate = addDays(value, duration);
          setValue('start_date', value);
          setValue('end_date', endDate);
        } else {
        }
      } else {
        setValue('end_date', value);

      }
    },
    [setValue, getValues]
  );
  
  
  return (
    <>
    {
      settingLoading || !allow ? <LoadingScreen/> : 

 <>
 <FormProvider methods={methods} onSubmit={onSubmit}>
        <Card
          sx={{
            width: '100%',
            margin: 'auto',
            mt: 4,
            p: 3,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className="heading1">
                Cost Code Category & Cost Codes
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button
                  startIcon={<Iconify icon="ic:round-keyboard-backspace" />}
                  onClick={router.back}
                  variant="text"
                  className="riskbutton"
                >
                  Go Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box
            mt={2}
            mb={2}
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(2, 1fr)',
              lg: 'repeat(4, 1fr)',
            }}
          >
            <ActivityFirstRow calculationofMarkupBidUnitPrice={calculationofMarkupBidUnitPrice} calculationofDirectUnitPrice={calculationofDirectUnitPrice}/>
            {allow.type_labor_hours_calculation != 'Lumpsum' &&<ActivitySecondRow />}
            <ActivityThirdRow 
            calculationofLabourHrs={calculationofLabourHrs}
            showTradeWorkAndHourlyRate={allow.type_hourly_rate_calculation !='Manual'} showNumberOfWorkers={allow.type_labor_hours_calculation != 'Lumpsum'}  icon={AttachMoneyIcon} onTradeAdd={tradeWork.onTrue} />
            <ActivityFourthRow
            calculationofDirectCost={calculationofDirectCost}
            calculationofLabourHrs={calculationofLabourHrs}
              showUnitFields={allow.type_activity_breakdown_calculation == 'Unit Price' ? true : false}
              icon={AttachMoneyIcon}
            />
            <ActivityFifthRow
            calculationofDirectCost={calculationofDirectCost}
              showUnitFields={allow.type_activity_breakdown_calculation == 'Unit Price' ? true : false}
              icon={AttachMoneyIcon}
            />
            <ActivitySixthRow
            calculationofMarkupCost={calculationofMarkupCost}
            calculationofDirectCost={calculationofDirectCost}
            enableDropdown={allow.markup_setup_values}
            showDropdowns={allow.type_markup_setup !='Markup'}
              icon={AttachMoneyIcon}
              onMarkupAdd={markup.onTrue}
              onContingencyAdd={contingency.onTrue}
              onEscalationAdd={escalation.onTrue}
              onTaxAdd={tax.onTrue}
              onDiscountAdd={discount.onTrue}
            />
          </Box>
          <Card
            mt={2}
            gap={4}
            sx={{ p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h6" sx={{ fontSize: '0.2rem' }}>
                Calculation
              </Typography>
              <Checkbox
                name="calculation"
                label="Calculation"
                onClick={handleCheckBox}
                value={checkBoxOptions.isCalculation}
              />
            </Box>
            <Box ml={5} display="flex" alignItems="center">
              <Typography variant="h6" sx={{ fontSize: '0.2rem' }}>
                Schedule
              </Typography>
              <Checkbox
                name="schedule"
                label="Schedule"
                onClick={handleCheckBox}
                value={checkBoxOptions.isSchedule}
              />
            </Box>
            <Box ml={5} display="flex" alignItems="center">
              <Typography variant="h6" sx={{ fontSize: '0.2rem' }}>
                Assign Task
              </Typography>
              <Checkbox
                name="assign"
                label="Assign Task"
                onClick={handleCheckBox}
                value={checkBoxOptions.isAssignedTask}
              />
            </Box>
            <Box ml={5} display="flex" alignItems="center">
              <Typography variant="h6" sx={{ fontSize: '0.2rem' }}>
                Save it for Future or data base
              </Typography>
              <Checkbox name="save" label="Save it for Future or data base" />
            </Box>
          </Card>
          {checkBoxOptions.isCalculation === true && (
            <ActivityCalculationRow showMarkupCost={allow.type_markup_setup =='Markup'} icon={AttachMoneyIcon} AccessTimeIcon={AccessTimeIcon} />
          )}
          {checkBoxOptions.isSchedule === true && (
            <ActivityScheduleRow
            handleStartDateChange={handleStartDateChange}
            handleChangeDuration={handleChangeDuration}
              control={control}
              setValue={setValue}
              setError={setError}
              methods={methods}
              clearErrors={clearErrors}
            />
          )}
          {checkBoxOptions.isAssignedTask === true && (
            <ActivityAssigntaskRow onTradeAdd={trade.onTrue} />
          )}
          <Box display="flex" justifyContent="end" gap={1} mt={5}>
            <Button variant="outlined" className="danager" onClick={router.back}>
              Cancel
            </Button>
            <LoadingButton loading={loading} variant="contained" onClick={onSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Card>
      </FormProvider>
      <MarkupModal isOpen={markup.value} handleClose={markup.onFalse} PercentIcon={PercentIcon} />
      <ContingencyModal
        isOpen={contingency.value}
        handleClose={contingency.onFalse}
        PercentIcon={PercentIcon}
      />
      <EscalationModal
        isOpen={escalation.value}
        handleClose={escalation.onFalse}
        PercentIcon={PercentIcon}
      />
      <TradeModal isOpen={trade.value} handleClose={trade.onFalse} PercentIcon={PercentIcon} />
      <TradeWorkTitle
        isOpen={tradeWork.value}
        handleClose={tradeWork.onFalse}
        PercentIcon={PercentIcon}
        AttachMoneyIcon={AttachMoneyIcon}
      />
      <TaxModal isOpen={tax.value} handleClose={tax.onFalse} PercentIcon={PercentIcon} />
      <DiscountModal
        isOpen={discount.value}
        handleClose={discount.onFalse}
        PercentIcon={PercentIcon}
      />
 </>
    }
      
    </>
  );
}
