import { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages, formatDate } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
import { DatePicker } from '@mui/x-date-pickers';
import { useBoolean } from 'src/hooks/use-boolean';
import { MultiFilePreview, Upload } from 'src/components/upload';
import WorkOrderFollowUpHistory from './work-order-follow-up-history';
const WorkOrderFollowUp = ({ open, onClose, reload, currentItem, closeform, WONumber, WOId, chartsAccountList, bankInformation, balance }) => {

    const theme = useTheme();
    const modal = useBoolean()
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [filePreview, setFilePreview] = useState([]);
    const [isFileDeleted, setIsFileDeleted] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('Cheque');
    const [amountPaidError, setAmountPaidError] = useState('');
    const [debitcreadit, setdebitcreadit] = useState('Debit');

    const NewUserSchema = Yup.object().shape({
        // invoice_number: Yup.string().required('Invoice Number is required'),
        // purchase_order_number: Yup.string().required('Purchase Order Number is required'),
        // date_of_cheque: Yup.date().required('Date of Cheque is required'),
        // date_of_receipt: Yup.date().required('Date of Receipt is required'),
        // amount_paid: Yup.number().required('Amount Paid is required').positive('Amount Paid must be a positive number'),
        // payment_method: Yup.string().oneOf(['Cash', 'Check', 'Credit Card', 'Bank Transfer', 'Debit Card', 'Mobile Payment(eg, PayPal,Venmo)'], 'Invalid Payment Method').required('Payment Method is required'),
        // cheque_number: Yup.number().required('Cheque Number is required'),
        // operating_cost: Yup.string().required('Name of Bank is required'),
        // balance_to_paid: Yup.number().required('Balance to Paid is required').positive('Balance to Paid must be a positive number'),
        // days_left: Yup.number().required('Days Left is required').positive('Days Left must be a positive number'),
        // notes: Yup.string().nullable()
    });
    const defaultValues = useMemo(
        () => ({
            invoice_number: currentItem?.invoice_number || '',
            purchase_order_number: WONumber || '',
            date_of_cheque: currentItem?.date_of_cheque ? new Date(currentItem.date_of_cheque) : null,
            date_of_receipt: currentItem?.date_of_receipt ? new Date(currentItem.date_of_receipt) : null,
            amount_paid: currentItem?.amount_paid || '',
            payment_method: currentItem?.payment_method || '',
            cheque_number: currentItem?.cheque_number || '',
            operating_cost: currentItem?.operating_cost || '',
            account_chart_id: currentItem?.account_chart_id || '',
            balance_to_paid: balance || 0,
            days_left: currentItem?.days_left || '',
            notes: currentItem?.notes || '',
            receipt: currentItem?.receipt || null,
        }),
        [currentItem, WONumber, balance]
    );
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        setValue,
        control,
        formState: { isSubmitting },
    } = methods;

    const amountPaid = useWatch({ control, name: 'amount_paid' });
    const initialBalanceToPaid = defaultValues.balance_to_paid;

    useEffect(() => {
        const newBalance = Math.max(0, initialBalanceToPaid - (Number(amountPaid) || 0));
        setValue('balance_to_paid', newBalance.toFixed(2));
        if (Number(amountPaid) > initialBalanceToPaid) {
            setAmountPaidError('Amount Paid cannot be greater than the Balance to Paid');
        } else {
            setAmountPaidError('');
        }
    }, [amountPaid, initialBalanceToPaid, setValue]);

    useEffect(() => {
        reset(defaultValues);
    }, [currentItem, reset, defaultValues]);

    const handleRemoveFile = (inputFile) => {
        setIsFileDeleted(true);
        setFiles([]);
        setFilePreview([]);
    };

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }));
            setFiles([...newFiles]);
            // setValue('receipt', newFiles[0]);
        },
        [files]
    );

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        console.log(data, 'data-----------------------');
        const formData = new FormData();
        formData.append('invoice_number', data.invoice_number);
        formData.append('purchase_order_number', data.purchase_order_number);
        formData.append('date_of_receipt', formatDate(data.date_of_receipt));
        formData.append('amount_paid', data.amount_paid);
        formData.append('payment_method', data.payment_method);
        formData.append('balance_to_paid', data.balance_to_paid);
        formData.append(data.credit === 'Credit' ? 'credit' : 'debit', 1);

        //formData.append('credit', data.credit === 'Credit' ? 0 : 1);
        //formData.append('debit', data.credit === 'Credit' ? 0 : 1);
        formData.append('days_left', data.days_left);
        formData.append('notes', data.notes)
        if (data.payment_method === 'Cheque') {
            formData.append('cheque_number', data.cheque_number);
            formData.append('date_of_cheque', formatDate(data.date_of_cheque));
        } else if (data.payment_method === 'Credit Card' || data.payment_method === 'Debit Card') {
            formData.append(
                data.payment_method === 'Credit Card' ? 'credit_card' : 'debit_card',
                data.payment_method === 'Credit Card' ? data.credit_card : data.debit_card
            );
            formData.append('date_expire', formatDate(data.date_expire));
        } else if (data.payment_method === 'ACH') {
            if (data.account_chart_id?.id) {
                formData.append('account_chart_id', data.account_chart_id.id);
            }
            if (data.bank?.id) {
                formData.append('bank_information_id', data.bank.id);
            }
        }
        if (isFileDeleted) {
            formData.append('is_file_deleted', true);
        }
        if (files.length > 0) {
            formData.append('receipt', files[0]);
        }
        try {
            const response = await postRequest(
                WOId && `${endpoints.accounting.account_payable.work_order_invoice.post_list}${WOId}/wo-invoices`,
                formData,
                true
            );
            if (response.metadata && response.metadata.success) {
                reset();
                onClose();
                // closeform();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response?.message || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });

    const closeAndReset = () => {
        reset();
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeAndReset();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>

                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, alignItems: 'center', boxSizing: 'content-box' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <span
                            className='cardColor'
                            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                            &nbsp;&nbsp;  Work Order Follow Up  &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <Button
                            variant="contained"
                            className="dark-button"
                            onClick={modal.onTrue}
                        >
                            History
                        </Button>
                    </Box>
                    <IconButton onClick={closeAndReset}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        borderRadius: '1px',
                    }}
                >
                    <Box
                        mt={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >
                        <RHFTextField name="invoice_number" label="Invoice Number" type="number" />
                        <RHFTextField name="purchase_order_number" value={WONumber} label="Work Order Number" disabled />
                        <Controller
                            name="date_of_receipt"
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    label="Date of Receipt"
                                    {...field}
                                    inputFormat="yyyy-MM-dd"
                                    // disableFuture
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                        <RHFTextField
                            name="amount_paid"
                            label="Amount Paid"
                            type="number"
                            error={!!amountPaidError}
                            helperText={amountPaidError}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value < 0) {
                                    setAmountPaidError(!!amountPaidError);
                                } else {
                                    setAmountPaidError('');
                                }
                                setValue('amount_paid', value >= 0 ? value : 0)
                            }}
                        />

                        <RHFAutocomplete
                            name="payment_method"
                            label="Payment Method"
                            options={['Cash', 'Cheque', 'Credit Card', 'Debit Card', 'ACH']}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                            value={paymentMethod}
                            onChange={(_, value) => setPaymentMethod(value)} // Update state on change
                            error={!!methods.formState.errors.approval_status}
                            helperText={methods.formState.errors.approval_status?.message}
                        />
                        <RHFAutocomplete
                            name="credit"
                            label="Credit/Debit"
                            options={['Credit', 'Debit']}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => (
                                <li {...props} key={option}>
                                    {option}
                                </li>
                            )}
                            value={debitcreadit}
                            onChange={(_, value) => {
                                setdebitcreadit(value); // Update state
                                methods.setValue('credit', value); // Update React Hook Form value
                            }}
                            error={!!methods.formState.errors.credit}
                            helperText={methods.formState.errors.credit?.message}
                        />
                    </Box>
                    <Box
                        mt={4}
                        rowGap={3}
                        display="grid"
                        sx={{ width: '100%' }}
                    >
                        <Typography variant="subtitle2">Upload Attachment</Typography>
                        <Upload onDrop={handleDrop} name='receipt' onRemove={handleRemoveFile} />
                    </Box>
                    <Box sx={{ my: 3 }}>
                        <MultiFilePreview
                            files={files?.length > 0 ? files : filePreview}
                            onRemove={handleRemoveFile}
                            setFileDeleted={setIsFileDeleted}
                        />
                    </Box>


                    {paymentMethod !== 'Cash' && (
                        <Box
                            mt={2}
                            mb={2}
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                            }}
                        >
                            {(paymentMethod === 'Credit Card' || paymentMethod === 'Debit Card') && (
                                <>
                                    {paymentMethod === 'Debit Card' ? (
                                        <RHFTextField name="debit_card" type="number" label="Debit Card Number" />
                                    ) : (
                                        <RHFTextField name="credit_card" type="number" label="Credit Card Number" />
                                    )}
                                    <Controller
                                        name="date_expire"
                                        render={({ field, fieldState: { error } }) => (
                                            <DatePicker
                                                label="Card Expiration Date"
                                                {...field}
                                                inputFormat="yyyy-MM-dd"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    <RHFAutocomplete
                                        name="account_chart_id"
                                        label="Account Type"
                                        options={chartsAccountList}
                                        getOptionLabel={(option) => option?.code || ''}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>{option.code}</li>
                                        )}
                                    />
                                </>
                            )}

                            {paymentMethod === 'Cheque' && (
                                <>
                                    <RHFTextField name="cheque_number" type="number" label="Cheque Number" />
                                    <Controller
                                        name="date_of_cheque"
                                        render={({ field, fieldState: { error } }) => (
                                            <DatePicker
                                                label="Date of Cheque"
                                                {...field}
                                                inputFormat="yyyy-MM-dd"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />

                                </>
                            )}
                            {paymentMethod === 'ACH' && (
                                <RHFAutocomplete
                                    name="account_chart_id"
                                    label="Account Type"
                                    options={chartsAccountList}
                                    getOptionLabel={(option) => option?.code || ''}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>{option.code}</li>
                                    )}
                                />
                            )}
                            <RHFAutocomplete
                                name="bank"
                                label="Name of Bank"
                                options={bankInformation}
                                getOptionLabel={(option) => option?.bank_name || ''}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>{option.bank_name}</li>
                                )}
                            />
                        </Box>
                    )}
                    <RHFTextField
                        name="notes"
                        label="Note / Remarks"
                        multiline
                        minRows={3}
                        fullWidth
                    />
                    <Box
                        mt={2}
                        mb={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >
                        <RHFTextField name="balance_to_paid" type="number" label="Balance to paid" disabled />
                        <RHFTextField name="days_left" type="number" label="Days Left" />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={closeAndReset}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" className='dark-button'
                        loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
            <WorkOrderFollowUpHistory
                open={modal.value}
                onClose={modal.onFalse}
                WOId={WOId}
            />
        </Dialog>
    );
};
export default WorkOrderFollowUp;