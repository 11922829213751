// getData.js
export function getData() {
  const tasks = {
    data:[
      {
        id: "10",
        text: "Project #1",
        start_date: "01-04-2025",
        duration: 3,
        order: 10,
        progress: 0.4,
        open: true,

      },
      {
        id: "1",
        text: "Task #1",
        start_date: "01-04-2025",
        duration: 1,
        order: 10,
        progress: 0.6,
        parent: "10",
      },
      {
        id: "2",
        text: "Task #2",
        start_date: "02-04-2025",
        duration: 2,
        order: 20,
        progress: 0.6,
        parent: "10",
      },
      {
        id: "3",
        text: "Task #3",
        start_date: "12-04-2025",
        duration: 2,
        order: 20,
        progress: 0.6,
        parent: "10",
      },
      {
        id: "100",
        text: "Project 2",
        start_date: "01-03-2025",
        duration: 5,
        order: 10,
        progress: 0.4,
        open: true,
      },
      {
        id: "6",
        text: "Task 1",
        start_date: "01-04-2025",
        duration: 1,
        order: 10,
        progress: 0.6,
        parent: "100",
      },
      {
        id: "8",
        text: "Task 2",
        start_date: "02-04-2025",
        duration: 2,
        order: 20,
        progress: 0.6,
        parent: "100",
      },
      {
        id: "9",
        text: "Task 3",
        start_date: "12-04-2025",
        duration: 2,
        order: 20,
        progress: 0.6,
        parent: "100",
      },
    ],
    links: [{ id: 1, source: 1, target: 2, type: "0" }],
     resources :[
      { id: "labour", text: "Labour", type: "group",budgetedHrs: 4, assignedHrs:40,open: false, }, // Parent resource
      { id: 2, text: "John", budgetedHrs: 4, assignedHrs: 6, capacity: 8, parent: "labour" },
      { id: 7, text: "Mike", budgetedHrs: 0, assignedHrs: 0, capacity: 8, parent: "labour" },
      { id: 8, text: "Anna", budgetedHrs: 0, assignedHrs: 0, capacity: 8, parent: "labour" },
      { id: 9, text: "Bill", budgetedHrs: 0, assignedHrs: 0, capacity: 8, parent: "labour" },
    ],
    assignments: [{id:1, resource_id:2, task_id: 1, value: 8,budgetedHrs:4,assignedHrs:6},{id:2, resource_id:2, task_id: 2, value: 8,budgetedHrs:8,assignedHrs:8}]
  };



  return { tasks };
}
