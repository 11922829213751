// src/components/content/RiskRegister.js
import { useCallback, useEffect, useState } from 'react';
import Header from '../header';
import {
  Select, MenuItem,
  Box,
  Button,
  Card,
  CardContent, IconButton,
  Table,
  TableBody, TableContainer, Tooltip
} from '@mui/material';
import Iconify from 'src/components/iconify';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { paths } from 'src/routes/paths';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, getRequestDummy, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate, useParams } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';

import EquipmentCreate from './equipment-create';

import { LoadingButton } from '@mui/lab';


import UserTableRow from './equipments-table-row';

const Equipment = () => {
  const navigate = useNavigate();

  const { id } = useParams()

  const handleNavigate = () => {
    navigate('/generate-equipment');
  };

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [ProjectNames, setProjectNames] = useState([]);
  const [projectList, setprojectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(id);
  const [tableData, setTableData] = useState([]);
  const [getLoading, setGetLoading] = useState(false);


  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'No.#', label: 'No.#' },
    { id: 'Equipment Code', label: 'Equipment Code' },
    { id: 'Name', label: 'Name' },
    { id: 'Action', label: 'Action' },
  ];

  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();



  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback(
    (id) => {
      setTableData((prevTableData) => {
        const deleteRow = prevTableData.filter((row) => row.id !== id);
        table.onUpdatePageDeleteRow(dataInPage.length);
        return deleteRow;
      });
    },
    [dataInPage.length, table]
  );

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  useEffect(() => {
    getEquipments(selectedProject);
    getProjects();
  }, []);

  const getEquipments = async (pId) => {
    setLoading(true);
    try {
      const data = await getRequestDummy(`${endpoints.accounting.inventory.equipments.get_list}${pId}/performance-equipment-categories`, pId);
      console.log('🚀 ~ getEquipments ~ data:', data);
      setTableData(data || []);
    } catch (error) {
      console.error('Error fetching equipments:', error);
    } finally {
      setLoading(false);
    }
  };


  const deleteCallBack = () => {
    confirm.onFalse();
  };

  const getProjects = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_project_list);
    console.log('🚀 ~ getProjets ~ data:', data);
    if (data) {
      setprojectList(data);

    }
  };
  const handleChange = (event) => {
    setSelectedProject(event.target.value);
    console.log('Selected Project ID:', event.target.value);
  };


  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []);
  };

  const handleDeleteRows = async (id) => {
    await deleteRequest(
      `${endpoints.risk_management.risk_change_request.crud}/${selectedItem.id}`,
      deleteCallBack
    );
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  };

  const handleBulkDelete = async () => {
    const Ids = table.selected;

    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_change_request.bulk_delete,
          { ids: Ids }
        );

        remove.onFalse();
        setActionVisible(false);
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  return (
    <>
      <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
        {' '}
        <CardContent>
          {loading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
              <Header name="Equipments" />

              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '20px',
                  justifyContent: 'space-between',
                  gap: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '5px 0',
                  }}
                >
                  {/* <h5
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#333',
                      margin: '0',
                      textTransform: 'none',
                    }}
                  >
                    Project Name
                  </h5>

                  <Select
                    sx={{ minWidth: 65, height: 35 }}
                    value={selectedProject}
                    onChange={handleChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {projectList?.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select> */}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: '20px',
                    justifyContent: 'flex-end',
                    gap: '12px',
                  }}
                >
                  <Button
                    onClick={() => {
                      setSelectedItem({});
                      create.onTrue();
                    }}
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                  >
                    Create Equipment
                  </Button>
                </Box>
              </Box>

              <Card
                sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                }}
              >
                <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                  {actionVisible && (
                    <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                      <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            setActionVisible(checked),
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                        action={
                          <Tooltip title="Delete">
                            <IconButton color="primary" onClick={remove.onTrue}>
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    </div>
                  )}
                  <Scrollbar>
                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                      <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                        action={
                          <Tooltip title="Delete">
                            <IconButton color="primary" onClick={confirm.onTrue}>
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Tooltip>
                        }
                      />

                      <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                          <TableHeadCustom
                            order={table.order}
                            orderBy={table.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={tableData.length}
                            numSelected={table.selected.length}
                            onSort={table.onSort}
                            onSelectAllRows={(checked) =>
                              table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                              )
                            }
                          />
                          <TableBody>
                            {dataFiltered
                              .slice(
                                table.page * table.rowsPerPage,
                                table.page * table.rowsPerPage + table.rowsPerPage
                              )
                              .map((row, index) => (
                                <UserTableRow
                                  key={row.id}
                                  row={row}
                                  selected={table.selected.includes(row.id)}
                                  onSelectRow={() => table.onSelectRow(row.id)}
                                  onDeleteRow={() => handleDeleteRow(row.id)}
                                  onEditRow={() => handleEditRow(row)}
                                  projectId={id}
                                  serialNumber={table.page * table.rowsPerPage + index + 1} // Calculate the serial number
                                />
                              ))}
                            <TableEmptyRows
                              height={denseHeight}
                              emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                            />

                            <TableNoData notFound={notFound} />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
        </CardContent>
      </Card>
      <EquipmentCreate
        open={create.value}
        onClose={create.onFalse}
        //reload={() => getProjectsRequest()}
        currentItem={selectedItem}
        closeform={() => handleCloseForm()}
      />
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you want to delete selected items?"
        action={
          <Button
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />

      <ConfirmDialog
        open={remove.value}
        onClose={remove.onFalse}
        title="Delete"
        content={<>Are you sure want to delete these items?</>}
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleBulkDelete();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default Equipment;
