import { RHFTextField } from "src/components/hook-form";
export default function ActivitySecondRow() {
  return (
    <>
      <RHFTextField
        type="number"
        name="daily_production_rate_per_shift"
        label="Daily Production Rate per Shift"
      />
      <RHFTextField
        type="number"
        name="difficulty_factor"
        label="Difficulty factor (More Difficulty Enter Less Then 1)"
      />
      <RHFTextField type="number" name="no_of_shifts" label="No of Shifts" />
      <RHFTextField type="number" name="shift_hours" label="Shift Hours" />
    </>
  );
}
