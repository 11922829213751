import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Paper } from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { LoadingScreen } from 'src/components/loading-screen';

const SettingModal = ({ open, onClose, onSave }) => {
    const theme = useTheme();
    // Updated invoicelables to hold objects with 'name' and 'value' properties
    const [invoicelables, setInvoiceLables] = useState([
        { name: 'Purchase Order', value: true },
        { name: 'Subcontractor Amounts', value: true },
        { name: 'Work Orders', value: true }
    ]);
    const [loading, setLoading] = useState(false);

    const handleCheckboxChange = (index) => (event) => {
        const updatedLabels = [...invoicelables];
        updatedLabels[index].value = event.target.checked;
        setInvoiceLables(updatedLabels);
    };

    const handleSubmit = async () => {
        setLoading(true);
        // Example of submitting the data - you can add your logic for the POST request here
        console.log('Submitted values:', invoicelables);
        setLoading(false);
    };

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'Inter, Arial, sans-serif',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <DialogTitle sx={{ justifyContent: 'space-evenly', boxSizing: 'content-box' }}>
                <span style={{ padding: '7px', marginLeft: '-25px', fontWeight: '700', color: "white" }} className='cardColor'>
                    &nbsp;&nbsp; SETTING &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <Divider />
            </DialogTitle>

            {loading ? (
                <Box height={700}>
                    <LoadingScreen />
                </Box>
            ) : (
                <DialogContent
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: invoicelables.length === 2 ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
                        gap: '20px',
                        padding: '50px',
                        borderRadius: '1px',
                    }}
                >
                    {invoicelables?.map((label, index) => (
                        <Paper
                            key={index}
                            style={{
                                padding: '5px 15px',
                                borderRadius: '4px',
                                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                marginTop: '10px',
                                border: '1px solid #CED4DA',
                            }}
                        >
                            <FormControlLabel
                                control={<Checkbox checked={label.value} onChange={handleCheckboxChange(index)} />}
                                label={label.name}  // Use the 'name' for the checkbox label
                            />
                        </Paper>
                    ))}
                </DialogContent>
            )}
            <DialogActions>
                <Button variant="outlined" color="inherit" onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton type="submit" variant="contained" className='dark-button' onClick={handleSubmit} loading={loading}>
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SettingModal;
