import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
// utils
import { endpoints, fetcher,  } from 'src/utils/axios';
import { token } from 'src/config-global';

// ----------------------------------------------------------------------


const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};




export const payload = [
  {
    "id": 9,
    "project_id": 1298,
    "project_title": "Ground Improvements- Atlantic Terminal",
    "bid_due_date_and_time": "2023-11-30T05:18:01.000000Z"
  },
  {
    "id": 11,
    "project_id": 1297,
    "project_title": "Advanced testing project",
    "bid_due_date_and_time": "2023-12-01T07:39:00.000000Z"
  },
  {
    "id": 14,
    "project_id": 1345,
    "project_title": "check calculation",
    "bid_due_date_and_time": "2023-12-05T12:25:41.000000Z"
  },
  {
    "id": 16,
    "project_id": 1870,
    "project_title": "Ulric Fowler",
    "bid_due_date_and_time": "2023-12-13T14:41:00.000000Z"
  },
  {
    "id": 17,
    "project_id": 1989,
    "project_title": "Keith Mercer",
    "bid_due_date_and_time": "2023-12-15T05:12:00.000000Z"
  }
]



//umar bhai say date formate change karwana hy due date ka
export const leadpayload = [
  {
    "id": 76,
    "title": "Software Sales",
    "ticket_number": "000001",
    "due_date": "2023-12-24T05:12:00.000000Z"
  },
  {
    "id": 78,
    "title": "lead with title test",
    "ticket_number": "000002",
    "due_date": "2023-12-15T05:12:00.000000Z"
  },
  {
    "id": 80,
    "title": "dsd",
    "ticket_number": "000003",
    "due_date": "2023-12-25T05:12:00.000000Z"
  }
]



export const eventsData = payload.map((event) => ({
  ...event,
  textColor: 'black',
  title: event.project_title,
  date: event.bid_due_date_and_time
}));


export const leadsEventsData = leadpayload.map((leadevent) => ({
  ...leadevent,
  textColor: 'lightgreen',
  title: `${leadevent.title} (${leadevent.ticket_number})`,
  date: new Date(leadevent.due_date),
}))




export function useGetEvents() {
  const URL = `https://web.ezelogs.com/api${endpoints.bids_due}`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);


  fetch(URL, {
    method: 'GET',
    headers: {
      Authorization: token,
      currentCompanyId: 2620,
      currentProjectId: 1298,
    },
  }).then(res => res.json())
    .then((data) => {
      console.log(data);
    }).catch(err => {
      console.log(err)
    })




  const memoizedValue = useMemo(() => {
    // const events = data?.payload.map((event) => ({
    //   ...event,
    // textColor: 'black',
    // title: event.project_title,
    // date: event.bid_due_date_and_time
    // }));
    const events = payload.map((event) => ({
      ...event,
      textColor: 'black',
      title: event.project_title,
      date: event.bid_due_date_and_time
    }));

    return {
      events: events || [],
      eventsLoading: isLoading,
      eventsError: error,
      eventsValidating: isValidating,
      eventsEmpty: !isLoading && !data?.events.length,
    };
  }, [data?.events, error, isLoading, isValidating]);

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function createEvent(eventData) {
  /**
   * Work on server
   */
  // const data = { eventData };
  // await axios.post(URL, data);

  /**
   * Work in local
   */
  mutate(
    URL,
    (currentData = { events: [] }) => { // Ensure currentData has events property
      const events = [...(currentData.events || []), eventData]; // Use currentData.events or an empty array if undefined

      return {
        ...currentData,
        events,
      };
    },
    false
  );
}


// ----------------------------------------------------------------------

export async function updateEvent(eventData) {
  /**
   * Work on server
   */
  // const data = { eventData };
  // await axios.put(endpoints.calendar, data);

  /**
   * Work in local
   */
  mutate(
    URL,
    (currentData) => {
      const events = currentData.events.map((event) =>
        event.id === eventData.id ? { ...event, ...eventData } : event
      );

      return {
        ...currentData,
        events,
      };
    },
    false
  );
}

// ----------------------------------------------------------------------

export async function deleteEvent(eventId) {
  /**
   * Work on server
   */
  // const data = { eventId };
  // await axios.patch(endpoints.calendar, data);

  /**
   * Work in local
   */
  mutate(
    URL,
    (currentData) => {
      const events = currentData.events.filter((event) => event.id !== eventId);

      return {
        ...currentData,
        events,
      };
    },
    false
  );
}
