export const configureZoom = (gantt, resourceView) => {
  gantt.config.scale_height = 80;
    gantt.ext.zoom.init({
      levels: [
        {
          name: 'day',
          scales: [
            { unit: 'day', step: 1, format: '%d %M' },
            { unit: 'hour', step: 1, format: '%H:%i' },
          ],
          min_column_width: 100,
          scroll_step: 24,
        },
        {
          name: 'week',
          scales: [
            { unit: 'week', step: 1, format: 'Week #%W' },
            { unit: 'day', step: 1, format: '%d %M' },
          ],
          min_column_width: 100,
          scroll_step: 24,
        },
        {
          name: 'month',
          scales: [
            { unit: 'month', step: 1, format: '%F %Y' },
            { unit: 'week', step: 1, format: 'Week #%W' },
          ],
          min_column_width: 100,
          scroll_step: 24,
        },
      ],
    });
  
    gantt.ext.zoom.setLevel(resourceView);
  };
  