// src/components/content/RiskRegister.js
import { useEffect, useState, useRef } from 'react';
import Header from '../header';
import {
  Select,
  TextField,
  MenuItem,
  Box,
  Button,
  Card,
  CardContent, IconButton,
  Table,
  TableBody, TableContainer, Tooltip,
  Typography
} from '@mui/material';
import Iconify from 'src/components/iconify';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
// import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import { LoadingButton } from '@mui/lab';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { getCsvData } from './getCsv_workorder';
import ImportDilaog from '../../import-modal';
import ProjectList from '../../project-list/project-list-modal';
import SettingModal from './settings-modal-AP';
import WorkOrderCreate from './work-order-create';
import UserTableRow from './workorder-table-row';
import { CSVLink } from 'react-csv';

const WorkOrder = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [projectList, setprojectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('All');
  const [chartsAccountList, setchartsAccount] = useState([]);
  const [bankInformation, setBankInformation] = useState([]);



  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const popover = usePopover();
  const popoverDots = usePopover();

  const csvLinkRef = useRef();

  const getCsvHeaders = () => {
    return TABLE_HEAD.filter(({ id }) => id !== 'action' && id !== 'serial_number').map(({ id, label }) => ({
      label,
      key: id,
    }));
  };

  const handleExport = () => {
    if (csvLinkRef.current) {
      const csvData = getCsvData(tableData);
      csvLinkRef.current.link.click(csvData);
    }
  };

  const TABLE_HEAD = [
    { id: 'serial_number', label: 'Sr.no' },
    { id: 'project_name', label: 'Project Name' },
    { id: 'work_order_number', label: 'Work Order Id' },
    { id: 'work_order_date', label: 'Date Issued' },
    { id: 'subcontractor_name', label: 'Subcontractor Name' },
    { id: 'date', label: 'Date' },
    { id: 'total_amount', label: 'Total Amount' },
    { id: 'balance', label: 'Balance' },
    { id: 'remarks', label: 'Notes/Remarks' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action' },
  ];

  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();
  const confirm = useBoolean();
  const create = useBoolean();
  const add = useBoolean();
  const modal = useBoolean();
  const order = useBoolean();

  const [tableData, setTableData] = useState([]);

  const [filters, setFilters] = useState({
    project_name: "",
    work_order_number: "",
    subcontractor_name: "",
    total_amount: "",
    balance: "",
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleCloseFilters = () => {
    popover.onClose();
    setFilters({
      project_name: "",
      work_order_number: "",
      subcontractor_name: "",
      total_amount: "",
      balance: null,
    })
  }


  const dataFiltered = applyFilter({
    inputData: tableData?.length > 0 ? tableData : [],
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;


  useEffect(() => {
    getWorkOrderList(selectedProject);
    getProjects();
    getChartsOfAccount();
    getBankInformation();
  }, []);


  const getWorkOrderList = async (projectId) => {
    setLoading(true);
    const endpoint = projectId && projectId !== 'All'
      ? `${endpoints.accounting.account_payable.work_order.get_list}?project_id=${projectId}`
      : endpoints.accounting.account_payable.work_order.get_list;

    const data = await getRequest(endpoint);
    console.log('🚀 ~    ~ data:', data);
    if (data) {
      setTableData(data);
    }
    setLoading(false);
  };

  const getProjects = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_project_list);
    console.log('🚀 ~ getProjets ~ data:', data);
    if (data) {
      setprojectList(data);

    }
  };
  const getBankInformation = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.bank_information.get_list);
    console.log(' getBankInformation ~ data:', data);
    if (data) {
      setBankInformation(data);
    }
    setLoading(false);
  };

  const getChartsOfAccount = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.expenses.charts_of_account_list);
    console.log("🚀 ~ getCostCenter ~ data:", data)
    if (data) {
      setchartsAccount(data);
    }
    setLoading(false);

  };
  const handleChange = (event) => {
    const projectId = event.target.value
    setSelectedProject(projectId);
    getWorkOrderList(projectId)
    console.log('Selected Project ID:', event.target.value);
  };
  const deleteCallBack = () => {
    confirm.onFalse();
    getWorkOrderList();
  };

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const handleDeleteRows = async (id) => {
    await deleteRequest(
      `${endpoints.risk_management.risk_change_request.crud}/${selectedItem.id}`,
      deleteCallBack
    );
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  };

  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log('🚀 ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_change_request.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getWorkOrderList();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  return (
    <>
      <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
        {' '}
        <CardContent>
          {loading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
              <Header name="Work Order" />
              <div
                style={{
                  backgroundColor: '#F4F6F8',
                  border: '1px solid #FFD700',
                  padding: '10px 10px',
                  borderRadius: '5px',
                  //margin: '10px 0',
                  marginTop: '15px',
                  marginBottom: '35px',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    marginLeft: '1px',
                    fontSize: '15px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  <strong>Note:</strong>
                  If you want to create new data,{' '}
                  <span
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => add.onTrue()}
                  >
                    Click here
                  </span>{' '}
                  This will open the project list, where you can select a Project. Once selected, you will be redirected accordingly.
                </Typography>

              </div>

              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '20px',
                  justifyContent: 'space-between',
                  gap: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '5px 0',
                  }}
                >
                  <h5
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#333',
                      margin: '0',
                      textTransform: 'none',
                    }}
                  >
                    Project Name
                  </h5>
                  <Select
                    sx={{ minWidth: 65, height: 35 }}
                    value={selectedProject}
                    onChange={handleChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {projectList?.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: '20px',
                    justifyContent: 'flex-end',
                    gap: '12px',
                  }}
                >
                  <Button
                    onClick={popover.onOpen}
                    className="dark-button"
                    variant="contained"
                    sx={{ mx: 1 }}
                    startIcon={<Iconify icon="mdi:filter" />}
                  >
                    Filter
                  </Button>
                  <Button
                    onClick={modal.onTrue}
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                  >
                    Settings
                  </Button>
                  <Button
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                    onClick={() => {
                      order.onTrue();
                    }}
                  >
                    Create
                  </Button>
                  <IconButton onClick={popoverDots.onOpen}>
                    <Iconify icon="eva:more-vertical-fill" />
                  </IconButton>
                </Box>
              </Box>

              <CustomPopover
                open={popoverDots.open}
                onClose={popoverDots.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
              >
                {/* <MenuItem
                  onClick={() => {
                    create.onTrue();
                    popoverDots.onClose();
                  }}
                >
                  <Iconify icon="solar:import-bold" />
                  Import
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    handleExport();
                    popoverDots.onClose();
                  }}
                >
                  <Iconify icon="solar:export-bold" />
                  Export
                </MenuItem>
              </CustomPopover>

              <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="bottom-bottom"
                sx={{
                  width: 1000,
                  padding: 2,
                  background: 'white !important',
                  left: '310px !important',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem
                  disableRipple
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    color: '#000',
                    background: 'white !important',
                    '&:hover': { backgroundColor: 'white !important' },
                  }}
                >
                  <Iconify
                    icon="mdi:close"
                    onClick={handleCloseFilters}
                    sx={{
                      width: 20,
                      height: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      backgroundColor: '#F4F6F8',
                      color: '#637381',
                    }}
                  />

                  <TextField
                    label="Project Name"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    value={filters.project_name}
                    onChange={(e) => handleFilterChange('project_name', e.target.value)}
                    sx={{
                      flex: 1,
                      background: 'white !important',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />

                  <TextField
                    label="Work order Id"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    value={filters.work_order_number}
                    onChange={(e) => handleFilterChange('work_order_number', e.target.value)}
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Sub Contractor Name"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    value={filters.subcontractor_name}
                    onChange={(e) => handleFilterChange('subcontractor_name', e.target.value)}
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />

                  <TextField
                    label="Total Amount"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    value={filters.total_amount}
                    onChange={(e) => handleFilterChange('total_amount', e.target.value)}
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Balance"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    value={filters.balance}
                    onChange={(e) => handleFilterChange('balance', e.target.value)}
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />
                </MenuItem>
              </CustomPopover>

              <Card
                sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                }}
              >
                <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                  {actionVisible && (
                    <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                      <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={tableData?.length}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            setActionVisible(checked),
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                        action={
                          <Tooltip title="Delete">
                            <IconButton color="primary" onClick={remove.onTrue}>
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    </div>
                  )}
                  <Scrollbar>
                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                     

                      <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                          <TableHeadCustom
                            order={table.order}
                            orderBy={table.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={tableData.length}
                            numSelected={table.selected.length}
                            onSort={table.onSort}
                            onSelectAllRows={(checked) =>
                              table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                              )
                            }
                          />
                          <TableBody>
                            {dataFiltered
                              .slice(
                                table.page * table.rowsPerPage,
                                table.page * table.rowsPerPage + table.rowsPerPage
                              )
                              .map((row, index) => (
                                <UserTableRow
                                  key={row.id}
                                  row={row}
                                  selected={table.selected.includes(row.id)}
                                  onSelectRow={() => table.onSelectRow(row.id)}
                                  //onDeleteRow={() => handleDeleteRow(row.id)}
                                  //onEditRow={() => handleEditRow(row)}
                                  bankInformation={bankInformation}
                                  chartsAccountList={chartsAccountList}
                                  reload={() => getWorkOrderList()}
                                  WOId={row.id}
                                  serialNumber={table.page * table.rowsPerPage + index + 1} // Calculate the serial number
                                />
                              ))}
                            <TableEmptyRows
                              height={denseHeight}
                              emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                            />

                            <TableNoData notFound={notFound} />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
          {
            <CSVLink
              ref={csvLinkRef}
              filename="Work Orders.csv"
              headers={getCsvHeaders()}
              data={getCsvData(tableData)}
              style={{ display: 'none' }}
            />
          }
        </CardContent>
      </Card>

      <SettingModal
        open={modal.value}
        onClose={modal.onFalse}
      //onSave={handleSaveSettings}
      />
      <ImportDilaog
        open={create.value}
        onClose={create.onFalse}
        reload={() => { }}
      //url={endpoints.risk_management.risk_register.identification.importFile}
      />
      <ProjectList
        open={add.value}
        onClose={add.onFalse}
      />
      <WorkOrderCreate
        open={order.value}
        onClose={order.onFalse}
      //reload={() => getRiskChangeRequest()}
      //currentItem={selectedItem}
      //closeform={() => handleCloseForm()}
      />
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you want to delete selected items?"
        action={
          <Button
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />

      <ConfirmDialog
        open={remove.value}
        onClose={remove.onFalse}
        title="Delete"
        content={<>Are you sure want to delete these items?</>}
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleBulkDelete();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default WorkOrder;

function applyFilter({ inputData, comparator, filters }) {
  const {
    project_name,
    work_order_number,
    subcontractor_name,
    total_amount,
    balance,
  } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);

  if (project_name) {
    inputData = inputData.filter((row) =>
      row.project?.name.toString().toLowerCase().includes(project_name.toLowerCase())
    );
  }

  if (work_order_number) {
    inputData = inputData.filter((row) =>
      row.work_order_number.toString().toLowerCase().includes(work_order_number.toLowerCase())
    );
  }

  if (subcontractor_name) {
    inputData = inputData.filter((row) =>
      row.created_by?.name.toString().toLowerCase().includes(subcontractor_name.toLowerCase())
    );
  }

  if (total_amount) {
    inputData = inputData.filter((row) =>
      row.total.toString().toLowerCase().includes(total_amount.toLowerCase())
    );
  }

  if (balance) {
    inputData = inputData.filter((row) =>
      row.balance ? row.balance.toString().toLowerCase().includes(balance.toLowerCase()) :
        false
    );
  }

  return inputData;
}
