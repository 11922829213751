// src/components/content/RiskRegister.js
import { useCallback, useEffect, useState, useRef } from 'react';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { getCsvData } from './getCsv';
import Header from '../header';
import {
  MenuItem,
  Box,
  Button,
  Card,
  CardContent, IconButton,
  Table,
  TableBody, TableContainer, Tooltip,
  Select
} from '@mui/material';
import Iconify from 'src/components/iconify';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { paths } from 'src/routes/paths';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import EmployeesCreate from './employees-info-create';
import { LoadingButton } from '@mui/lab';
import UserTableRow from './employee-table-row';

const Employees = () => {
  const navigate = useNavigate();
  const popover = usePopover();
  const popoverDots = usePopover()
  const csvLinkRef = useRef();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [countries, setcountries] = useState();


  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'No.#', label: 'No.#' },
    { id: 'First Name', label: 'First Name' },
    { id: 'Last Name', label: 'Last Name' },
    { id: 'Email', label: 'Email' },
    { id: 'Mobile', label: 'Mobile' },
    //{ id: 'Last 4 Digits of SS No.', label: 'Last 4 Digits of SS No.' },
    { id: 'Address', label: 'Address' },
    //{ id: 'City', label: 'City' },
    //{ id: 'State', label: 'State' },
    //{ id: 'Zip', label: 'Zip' },
    //{ id: 'List of Project Working', label: 'List Project Working' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();
  const add = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  console.log('🚀 ~ RiskChangeRequest ~ dataInPage:', dataInPage);
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;





  // const handleEditRow = useCallback(
  //   (id) => {
  //     router.push(paths.dashboard.user.edit(id));
  //   },
  //   [router]
  // );

  const handleEditRow = (item) => {
    setSelectedItem(item); // Set the selected item for editing
    create.onTrue()
  };

  useEffect(() => {
    getEmployeeDetails();
    getCountries();
  }, []);

  const getEmployeeDetails = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.employee.get_list);
    console.log('🚀 ~ getEmployeeDetails ~ data:', data);
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };


  const getCountries = async () => {
    setGetLoading(true);
    const data = await getRequest(endpoints.accounting.customers.get_countries);
    setGetLoading(false);
    if (data) {
      setcountries(data?.countries);
    }
  };

  const getCsvHeaders = () => {
    return TABLE_HEAD.filter(({ id }) => id !== 'actions').map(({ id, label }) => ({
      label,
      key: id,
    }));
  };
  const handleExport = () => {
    if (csvLinkRef.current) {
      const csvData = getCsvData(tableData);
      csvLinkRef.current.link.click(csvData);
    }
  };
  const deleteCallBack = () => {
    confirm.onFalse();
    getEmployeeDetails();
  };

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };



  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log('🚀 ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_change_request.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getEmployeeDetails();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };


  const handleDeleteRow = async (id) => {
    setDeleteLoading(true)
    await deleteRequest(`${endpoints.accounting.employee.delete}/${id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
    setDeleteLoading(false)
  }




  return (
    <>
      <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
        {' '}
        <CardContent>
          {loading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
              <Header name="EMPLOYEES" />

              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '20px',
                  justifyContent: 'space-between',
                  gap: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: -1,
                    padding: '2px 0',
                  }}
                >
                  <h5
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#333',
                      margin: '0',
                      textTransform: 'none',
                    }}
                  >
                    entries per page
                  </h5>

                  <Select sx={{ minWidth: 65, height: 35 }}>
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Project 1">Project 1</MenuItem>
                    <MenuItem value="Project 2">Project 2</MenuItem>
                    <MenuItem value="Project 3">Project 3</MenuItem>
                  </Select>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: '20px',
                    justifyContent: 'flex-end',
                    gap: '12px',
                  }}
                >
                  <Button
                    //onClick={handleNavigate}
                    onClick={() => {
                      setSelectedItem({});
                      create.onTrue();
                    }}
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                  >
                    Create Employee
                  </Button>
                  <IconButton onClick={popoverDots.onOpen}>
                    <Iconify icon="eva:more-vertical-fill" />
                  </IconButton>
                </Box>
              </Box>

              <CustomPopover
                open={popoverDots.open}
                onClose={popoverDots.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
              >
                {/* <MenuItem
                  onClick={() => {
                    create.onTrue();
                    popoverDots.onClose();
                  }}
                >
                  <Iconify icon="solar:import-bold" />
                  Import
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    handleExport();
                    popoverDots.onClose();
                  }}
                >
                  <Iconify icon="solar:export-bold" />
                  Export
                </MenuItem>
              </CustomPopover>

              <Card
                sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                }}
              >
                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                  <TableSelectedAction
                    dense={table.dense}
                    numSelected={table.selected.length}
                    rowCount={tableData.length}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                    action={
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={confirm.onTrue}>
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                  <Scrollbar>
                    <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                      <TableHeadCustom
                        order={table.order}
                        orderBy={table.orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={tableData.length}
                        numSelected={table.selected.length}
                        onSort={table.onSort}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                      />
                      <TableBody>
                        {dataFiltered
                          .slice(
                            table.page * table.rowsPerPage,
                            table.page * table.rowsPerPage + table.rowsPerPage
                          )
                          .map((row, index) => (
                            <UserTableRow
                              key={row.id}
                              row={row}
                              selected={table.selected.includes(row.id)}
                              onSelectRow={() => table.onSelectRow(row.id)}
                              onDeleteRow={() => handleDeleteRow(row.id)}
                              onEditRow={() => handleEditRow(row)}
                              serialNumber={table.page * table.rowsPerPage + index + 1}
                            />
                          ))}
                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                        />
                        <TableNoData notFound={notFound} />
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
        </CardContent>
      </Card>
      <EmployeesCreate
        open={create.value}
        onClose={create.onFalse}
        reload={() => getEmployeeDetails()}
        currentItem={selectedItem}
        closeform={() => handleCloseForm()}
        countries={countries}
      />
      {/*<ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you want to delete selected items?"
        action={
          <Button
            variant="contained"
            className="dark-button"
            onClick={() => {
              //handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />*/}

      <ConfirmDialog
        open={remove.value}
        onClose={remove.onFalse}
        title="Delete"
        content={<>Are you sure want to delete these items?</>}
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleBulkDelete();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};
export default Employees;