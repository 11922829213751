import { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm, Controller, useWatch } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages, formatDate } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
import { DatePicker } from '@mui/x-date-pickers';
import { useBoolean } from 'src/hooks/use-boolean';
import { MultiFilePreview, Upload } from 'src/components/upload';
import PurchaseOrderFollowUpHistory from './purchase-order-follow-up-history';
import { yupResolver } from '@hookform/resolvers/yup';

const PurchaseOrderFollowUp = ({ open, onClose, reload, currentItem, closeform, PONumber, POId, chartsAccountList, bankInformation, balance }) => {
    const theme = useTheme();
    const modal = useBoolean();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [filePreview, setFilePreview] = useState([]);
    const [isFileDeleted, setIsFileDeleted] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('Cheque');
    const [debitCredit, setDebitCredit] = useState('Debit');
    const [amountPaidError, setAmountPaidError] = useState('');

    const NewUserSchema = Yup.object().shape({
        //invoice_number: Yup.string().required('Invoice Number is required'),
        //purchase_order_number: Yup.string().required('Purchase Order Number is required'),
        //date_of_cheque: Yup.date().required('Date of Cheque is required'),
        date_of_receipt: Yup.date().required('Date of Receipt is required'),
        amount_paid: Yup.number()
            .typeError('Amount Paid must be a number')
            .required('Amount Paid is required')
            .positive('Amount Paid must be a positive number'),
        //payment_method: Yup.string()
        //    .oneOf(['Cash', 'Cheque', 'Credit Card', 'Debit Card', 'ACH'], 'Invalid Payment Method')
        //    .required('Payment Method is required'),
        //cheque_number: Yup.number()
        //    .typeError('Cheque Number must be a number')
        //    .required('Cheque Number is required'),
        //operating_cost: Yup.string().required('Name of Bank is required'),
        //balance_to_paid: Yup.number()
        //    .typeError('Balance to Paid must be a number')
        //    .required('Balance to Paid is required')
        //    .positive('Balance to Paid must be a positive number'),
        //days_left: Yup.number()
        //    .typeError('Days Left must be a number')
        //    .required('Days Left is required')
        //    .positive('Days Left must be a positive number'),
        //notes: Yup.string().nullable(),
        //credit: Yup.string().oneOf(['Credit', 'Debit'], 'Invalid option for Credit/Debit'),
        //account_chart_id: Yup.string().required('Account Type is required'),
        //bank: Yup.string().required('Name of Bank is required'),
        //receipt: Yup.mixed().nullable(),
    });

    const defaultValues = useMemo(
        () => ({
            invoice_number: currentItem?.invoice_number || '',
            purchase_order_number: PONumber || '',
            date_of_cheque: currentItem?.date_of_cheque ? new Date(currentItem.date_of_cheque) : null,
            date_of_receipt: currentItem?.date_of_receipt ? new Date(currentItem.date_of_receipt) : null,
            amount_paid: currentItem?.amount_paid || '',
            payment_method: currentItem?.payment_method || '',
            cheque_number: currentItem?.cheque_number || '',
            operating_cost: currentItem?.operating_cost || '',
            account_chart_id: currentItem?.account_chart_id || '',
            balance_to_paid: balance || 0,
            days_left: currentItem?.days_left || '',
            notes: currentItem?.notes || '',
            receipt: currentItem?.receipt || null,
            credit: currentItem?.credit || 'Debit',
        }),
        [currentItem, PONumber, balance]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isSubmitting, errors },
    } = methods;

    const amountPaid = useWatch({ control, name: 'amount_paid' });
    const initialBalanceToPaid = defaultValues.balance_to_paid;

    useEffect(() => {
        const newBalance = Math.max(0, initialBalanceToPaid - (Number(amountPaid) || 0));
        setValue('balance_to_paid', newBalance.toFixed(2));
        if (Number(amountPaid) > initialBalanceToPaid) {
            setAmountPaidError('Amount Paid cannot be greater than the Balance to Paid');
        } else {
            setAmountPaidError('');
        }
    }, [amountPaid, initialBalanceToPaid, setValue]);

    useEffect(() => {
        reset(defaultValues);
    }, [currentItem, reset, defaultValues]);

    const handleRemoveFile = (inputFile) => {
        setIsFileDeleted(true);
        setFiles([]);
        setFilePreview([]);
    };

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setFiles([...newFiles]);
        },
        []
    );

    // Handle form submission
    const onSubmit = handleSubmit(async (data) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('invoice_number', data.invoice_number);
            formData.append('purchase_order_number', data.purchase_order_number);
            formData.append('date_of_receipt', formatDate(data.date_of_receipt));
            formData.append('payment_method', data.payment_method);
            formData.append('balance_to_paid', data.balance_to_paid);
            formData.append('amount_paid', data.amount_paid);
            formData.append(data.credit === 'Credit' ? 'credit' : 'debit', data.amount_paid);

            if (data.payment_method === 'Cheque') {
                formData.append('cheque_number', data.cheque_number);
                formData.append('date_of_cheque', formatDate(data.date_of_cheque));
            }

            if (isFileDeleted) {
                formData.append('is_file_deleted', true);
            }
            if (files.length > 0) {
                formData.append('receipt', files[0]);
            }

            const response = await postRequest(
                POId ? `${endpoints.accounting.account_payable.purchase_order_invoice.post_list}${POId}/po-invoices` : endpoints.accounting.account_payable.purchase_order_invoice.post_list,
                formData,
                true
            );

            if (response.metadata && response.metadata.success) {
                reset();
                reload();
                onClose();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response?.message || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });

    const closeAndReset = () => {
        reset();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeAndReset();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, alignItems: 'center', boxSizing: 'content-box' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <span
                            className="cardColor"
                            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}
                        >
                            &nbsp;&nbsp; Purchase Order Follow Up &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <Button variant="contained" className="dark-button" onClick={modal.onTrue}>
                            History
                        </Button>
                    </Box>
                    <IconButton onClick={closeAndReset}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ borderRadius: '1px' }}>
                    <Box
                        mt={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >
                        <RHFTextField name="invoice_number" label="Invoice Number" type="number" />
                        <RHFTextField name="purchase_order_number" value={PONumber} label="Purchase Order Number" disabled />
                        <Controller
                            name="date_of_receipt"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    label="Date of Receipt"
                                    {...field}
                                    inputFormat="yyyy-MM-dd"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            )}
                        />

                        <RHFTextField
                            name="amount_paid"
                            label="Amount Paid"
                            type="number"
                            error={!!amountPaidError}
                            helperText={amountPaidError || errors.amount_paid?.message}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value < 0) {
                                    setAmountPaidError('Amount Paid cannot be negative');
                                } else {
                                    setAmountPaidError('');
                                }
                                setValue('amount_paid', value >= 0 ? value : 0);
                            }}
                        />

                        <RHFAutocomplete
                            name="payment_method"
                            label="Payment Method"
                            options={['Cash', 'Cheque', 'Credit Card', 'Debit Card', 'ACH']}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => (
                                <li {...props} key={option}>
                                    {option}
                                </li>
                            )}
                            value={paymentMethod}
                            onChange={(_, value) => setPaymentMethod(value)}
                            error={!!errors.payment_method}
                            helperText={errors.payment_method?.message}
                        />
                        <RHFAutocomplete
                            name="credit"
                            label="Credit/Debit"
                            options={['Credit', 'Debit']}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => (
                                <li {...props} key={option}>
                                    {option}
                                </li>
                            )}
                            value={debitCredit}
                            onChange={(_, value) => {
                                setDebitCredit(value);
                                methods.setValue('credit', value);
                            }}
                            error={!!errors.credit}
                            helperText={errors.credit?.message}
                        />
                    </Box>
                    <Box
                        mt={4}
                        rowGap={3}
                        display="grid"
                        sx={{ width: '100%' }}
                    >
                        <Typography variant="subtitle2">Upload Attachment</Typography>
                        <Upload onDrop={handleDrop} name="receipt" onRemove={handleRemoveFile} />
                    </Box>
                    <Box sx={{ my: 3 }}>
                        <MultiFilePreview
                            files={files?.length > 0 ? files : filePreview}
                            onRemove={handleRemoveFile}
                            setFileDeleted={setIsFileDeleted}
                        />
                    </Box>

                    {paymentMethod !== 'Cash' && (
                        <Box
                            mt={2}
                            mb={2}
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                            }}
                        >
                            {(paymentMethod === 'Credit Card' || paymentMethod === 'Debit Card') && (
                                <>
                                    {paymentMethod === 'Debit Card' ? (
                                        <RHFTextField name="debit_card" type="number" label="Debit Card Number" />
                                    ) : (
                                        <RHFTextField name="credit_card" type="number" label="Credit Card Number" />
                                    )}
                                    <Controller
                                        name="date_expire"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <DatePicker
                                                label="Card Expiration Date"
                                                {...field}
                                                inputFormat="yyyy-MM-dd"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    <RHFAutocomplete
                                        name="account_chart_id"
                                        label="Account Type"
                                        options={chartsAccountList}
                                        getOptionLabel={(option) => option?.code || ''}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.code}
                                            </li>
                                        )}
                                        error={!!errors.account_chart_id}
                                        helperText={errors.account_chart_id?.message}
                                    />
                                </>
                            )}

                            {paymentMethod === 'Cheque' && (
                                <>
                                    <RHFTextField name="cheque_number" type="number" label="Cheque Number" />
                                    <Controller
                                        name="date_of_cheque"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <DatePicker
                                                label="Date of Cheque"
                                                {...field}
                                                inputFormat="yyyy-MM-dd"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </>
                            )}
                            {paymentMethod === 'ACH' && (
                                <RHFAutocomplete
                                    name="account_chart_id"
                                    label="Account Type"
                                    options={chartsAccountList}
                                    getOptionLabel={(option) => option?.code || ''}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option.code}
                                        </li>
                                    )}
                                    error={!!errors.account_chart_id}
                                    helperText={errors.account_chart_id?.message}
                                />
                            )}
                            <RHFAutocomplete
                                name="bank"
                                label="Name of Bank"
                                options={bankInformation}
                                getOptionLabel={(option) => option?.bank_name || ''}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.bank_name}
                                    </li>
                                )}
                                error={!!errors.bank}
                                helperText={errors.bank?.message}
                            />
                        </Box>
                    )}
                    <RHFTextField
                        name="notes"
                        label="Note / Remarks"
                        multiline
                        minRows={3}
                        fullWidth
                        error={!!errors.notes}
                        helperText={errors.notes?.message}
                    />
                    <Box
                        mt={2}
                        mb={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >
                        <RHFTextField name="balance_to_paid" type="number" label="Balance to Paid" disabled />
                        <RHFTextField name="days_left" type="number" label="Days Left" />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={closeAndReset}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" className="dark-button" loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
            <PurchaseOrderFollowUpHistory open={modal.value} onClose={modal.onFalse} POId={POId} />
        </Dialog>
    );
};

export default PurchaseOrderFollowUp;
