import { useState } from 'react';
import {
  IconButton,
  Box
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { RHFAutocomplete } from '../hook-form';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'src/routes/hooks';

const DropDownAddValueCustom = ({ name, label, options = [],onClick, url, reload  }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const searchParams = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('authToken') || '';

 

  // Modal open and close handlers
  const handleOpen = (e) => {
    e.stopPropagation(); // Prevent the parent form from submitting
    onClick();
  };



  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'start' }}>
        <RHFAutocomplete
          sx={{ width: '100%' }}
          name={name}
          label={label} // Use plain label here
          options={options.map((option) => option.name)}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          renderOption={(props, option) => {
            const { name, id } = options.find((item) => item.name === option) || {};
            return (
              <li {...props} key={id}>
                <Iconify width={28} sx={{ mr: 1 }} />
                {name}
              </li>
            );
          }}
        />
        <IconButton
          onClick={handleOpen}
          className='cardColor'
          sx={{
            margin: '5px 10px',
            
            color: '#FFF',
            fontSize: 25,
            padding: '7px',
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: '#7AB13A',
            },
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Iconify icon="mingcute:add-line" />
        </IconButton>
      </Box>

     

    </>
  );
};

export default DropDownAddValueCustom;
