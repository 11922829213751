import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useBoolean } from 'src/hooks/use-boolean';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import PurchaseOrderFollowUp from './purchase-order-follow-up';
// ----------------------------------------------------------------------
export default function  UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, serialNumber, chartsAccountList, bankInformation, reload }) {
    const { purchase_order_number, po_date, remarks, status, vendor, project, id, total, contracted_to_date, contractor, bill_to_terms, balance } = row;

    const confirm = useBoolean();
    const popover = usePopover();
    const create = useBoolean();
    return (
        <>
            <TableRow hover selected={selected} >
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow} />
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={create.onTrue}
                >
                    {id}
                </TableCell>
                <TableCell>{purchase_order_number}</TableCell>
                <TableCell>{vendor?.name}</TableCell>
                <TableCell>{project?.name}</TableCell>
                <TableCell>{po_date}</TableCell>
                <TableCell>{bill_to_terms}</TableCell>
                <TableCell>{contractor?.first_name}{contractor?.last_name}</TableCell>
                <TableCell>{contracted_to_date}</TableCell>
                <TableCell>{total}</TableCell>
                <TableCell>{balance}</TableCell>
                <TableCell>{remarks}</TableCell>
                <TableCell>
                    {{
                        0: "Pending",
                        1: "Approved",
                        2: "Issues",
                        3: "Accepted",
                        4: "Shipped",
                        5: "Received"
                    }[status] || ""}
                </TableCell>
                <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>
            {/*//purchase order follow up*/}
            <PurchaseOrderFollowUp
                open={create.value}
                onClose={create.onFalse}
                PONumber={purchase_order_number}
                POId={id}
                chartsAccountList={chartsAccountList}
                bankInformation={bankInformation}
                balance={balance}
                reload={reload}
            />
            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
            >
                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onEditRow(row);
                        console.log(">>>>>>>", row)
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>
            </CustomPopover>
            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content="Are you sure you want to delete?"
                action={
                    <Button
                        variant="contained" color="error"
                        onClick={() => {
                            confirm.onFalse();
                            onDeleteRow(row.id);
                            console.log(">>>>>>>", row.id)
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
}
UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};