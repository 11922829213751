import { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  DialogActions,
  Button,
  InputAdornment,
  Typography,
  IconButton,
} from '@mui/material';
import { RHFSelect, RHFTextField } from '../hook-form';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from '../hook-form/form-provider';
import { postRequest } from 'src/services';
import * as Yup from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';

import { handleErrorMessages } from '../risk-management/risk-register/feching';
import Iconify from '../iconify';
import { Descriptions } from './dropdown-descirption';

const DiscountModal = ({ isOpen, handleClose, reload, PercentIcon }) => {
  const [loading, setLoading] = useState(false);
  const NewUserSchema = Yup.object().shape({});
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues: {
      discounts: [
        {
          discount_name: '',
          labor_cost: '',
          material_cost: '',
          equipment_cost: '',
          subcontractor_cost: '',
          other_cost: '',
        },
      ],
    },
  });

  const { reset, handleSubmit, control } = methods;
  const { fields, append, remove } = useFieldArray({ control, name: 'discounts' });
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const onClose = () => {
    handleClose();
    reset();
  };

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);
    try {
      const responseData = await postRequest('', formData);
      if (responseData.metadata && responseData.metadata.success) {
        onClose();
        reload();
        enqueueSnackbar('Discount  created successfully', { variant: 'success' });
      } else {
        handleErrorMessages(responseData.metadata.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      enqueueSnackbar('An error occurred while submitting the form.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  });



  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
          <span
            className='cardColor'
            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
            &nbsp;&nbsp; Discounts  &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={handleClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            mt={2}
            mb={2}
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(2, 1fr)',
              lg: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="discount_name" label="Discount  Table Name" />
            <RHFTextField name="discount_code" label="Discount  Code " />
            <RHFTextField
              type="number"
              name="toatl"
              label="Total % of Labour Cost"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {PercentIcon}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField
              type="number"
              name="other_cost"
              label="Total % of Material Cost"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {PercentIcon}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField
              type="number"
              name="other_cost"
              label="Total % of Equipment Cost"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {PercentIcon}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField
              type="number"
              name="other_cost"
              label="Total % of Subcontractor Cost"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {PercentIcon}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField
              type="number"
              name="other_cost"
              label="Total % of Other Cost"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {PercentIcon}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          {fields?.map((field, index) => (
            <Box key={field.id} mt={2} mb={2}>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="subtitle1">
                  Discount  {index + 1}/{fields.length}
                </Typography>
                <Box>
                  {fields.length > 1 && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={() => remove(index)}
                      sx={{ marginRight: 1 }}
                    >
                      Remove
                    </Button>
                  )}
                  <LoadingButton
                    className="dark-button"
                    variant="contained"
                    onClick={() =>
                      append({
                        discount_name: '',
                        labor_cost: '',
                        material_cost: '',
                        equipment_cost: '',
                        subcontractor_cost: '',
                        other_cost: '',
                      })
                    }
                  >
                    Add Discount 
                  </LoadingButton>
                </Box>
              </Box>
              <Box
                gap={3}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(2, 1fr)',
                  lg: 'repeat(2, 1fr)',
                }}
              >
                <RHFSelect native name={`discounts[${index}].description`} label="Description" InputLabelProps={{ shrink: true }}>
                  {Descriptions?.map((description) => (
                    <optgroup key={description.group} label={description.group}>
                      {description.classify.map((classify) => (
                        <option key={classify} value={classify}>
                          {classify}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </RHFSelect>
                <RHFTextField name={`discounts[${index}].discount_name`} label="Discount  Table Name" />
                <RHFTextField
                  type="number"
                  name={`discounts[${index}].labor_cost`}
                  label="% of Labor Cost"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          {PercentIcon}
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <RHFTextField
                  type="number"
                  name={`discounts[${index}].material_cost`}
                  label="% of Material Cost"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          {PercentIcon}
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <RHFTextField
                  type="number"
                  name={`discounts[${index}].equipment_cost`}
                  label="% of Equipment Cost"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          {PercentIcon}
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <RHFTextField
                  type="number"
                  name={`discounts[${index}].subcontractor_cost`}
                  label="% of Subcontractor Cost"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          {PercentIcon}
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <RHFTextField
                  type="number"
                  name={`discounts[${index}].other_cost`}
                  label="% of Other Cost"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          {PercentIcon}
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            className="dark-button"
            disabled={loading}
            loading={loading}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default DiscountModal;