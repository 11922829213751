// src/components/content/RiskRegister.js
import { useEffect, useState } from 'react';
import Header from '../header';
import {
  TextField,
  Select,
  MenuItem,
  Box,
  Button,
  Card,
  CardContent, IconButton, TableContainer, Tooltip
} from '@mui/material';
import Iconify from 'src/components/iconify';
import {
  TableSelectedAction,
  useTable
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import { LoadingButton } from '@mui/lab';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import IncomeStatementTableSheet from './income-statement-sheet-table '


const IncomeStatement = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [projectList, setprojectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('All');
  const [selectedTime, setSelectedTime] = useState("monthly");

  const popover = usePopover();
  const table = useTable();

  const confirm = useBoolean();
  const create = useBoolean();
  const remove = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  //const dataFiltered = applyFilter({
  //  inputData: tableData,
  //  comparator: getComparator(table.order, table.orderBy),
  //  filters,
  //});
  //const dataInPage = dataFiltered.slice(
  //  table.page * table.rowsPerPage,
  //  table.page * table.rowsPerPage + table.rowsPerPage
  //);






  useEffect(() => {
    getIncomeStaetement(selectedProject);
    getProjects();
  }, []);

  const getIncomeStaetement = async (projectId) => {
    setLoading(true);
    const endpoint = projectId && projectId !== 'All'
      ? `${endpoints.accounting.income_statement.get_list}?project_id=${projectId}`
      : `${endpoints.accounting.income_statement.get_list}`;
    const data = await getRequest(endpoint);
    console.log('🚀 ~ getBalacneSheet ~ data:', data);
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };


  const getProjects = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_project_list);
    console.log('🚀 ~ getProjets ~ data:', data);
    if (data) {
      setprojectList(data);

    }
  };


  const handleChange = (event) => {
    const projectId = event.target.value
    setSelectedProject(projectId);
    getIncomeStaetement(projectId)
    console.log('Selected Project ID:', event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };


  const deleteCallBack = () => {
    confirm.onFalse();
    getIncomeStaetement();
  };

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  //const handleDeleteRows = async (id) => {
  //  await deleteRequest(
  //    `${endpoints.risk_management.risk_change_request.crud}/${selectedItem.id}`,
  //    deleteCallBack
  //  );
  //  const updatedTableData = tableData.filter((row) => row.id !== id);
  //  setTableData(updatedTableData);
  //  if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
  //    table.onChangePage(null, table.page - 1);
  //  } else {
  //    table.onUpdatePageDeleteRow(dataInPage.length);
  //  }
  //};

  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log('🚀 ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_change_request.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getIncomeStaetement();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  return (
    <>
      <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
        {' '}
        <CardContent>
          {loading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
                <Header name="Income Statement" />


              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '20px',
                  justifyContent: 'space-between',
                  gap: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '5px 0',
                  }}
                >
                  <h5
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#333',
                      margin: '0',
                      textTransform: 'none',
                    }}
                  >
                    Project Name
                  </h5>
                  <Select
                    sx={{ minWidth: 65, height: 35 }}
                    value={selectedProject}
                    onChange={handleChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {projectList?.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {/*<Select
                    sx={{ minWidth: 65, height: 35 }}
                    value={selectedTime}
                    onChange={handleTimeChange}
                  >
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="quarterly">Quarterly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>*/}

                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: '20px',
                    justifyContent: 'flex-end',
                    gap: '12px',
                  }}
                >
                  {/*<Button
                    onClick={popover.onOpen}
                    className="dark-button"
                    variant="contained"
                    sx={{ mx: 1 }}
                    startIcon={<Iconify icon="mdi:filter" />}
                  >
                    Filter
                  </Button>*/}
                  {/*<Button
                    onClick={() => {
                      navigate('/accounts-management/generate-balancesheet');
                    }}
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                  >
                    Generate Report
                  </Button>*/}
                  <CustomPopover
                    open={popover.open}
                    onClose={popover.onClose}
                    arrow="bottom-bottom"
                    sx={{ width: 1200, padding: 2, background: 'white !important' }}
                  >
                    <MenuItem
                      disableRipple
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 2,
                        color: '#000',
                        background: 'white !important',
                        '&:hover': { backgroundColor: 'white !important' },
                      }}
                    >
                      <Iconify
                        icon="mdi:close"
                        onClick={popover.onClose}
                        sx={{
                          width: 20,
                          height: 20,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          backgroundColor: '#F4F6F8',
                          color: '#637381',
                        }}
                      />

                      <TextField
                        label="First Name"
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                      <TextField
                        label="Last Name"
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />

                      <TextField
                        label="Email"
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                      <TextField
                        label="Phone No."
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                      <TextField
                        label="Companey Name"
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                      <TextField
                        label="Type of Vendor"
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                    </MenuItem>
                  </CustomPopover>
                </Box>
              </Box>

              <Card
                sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                }}
              >
                <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                  {actionVisible && (
                    <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                      <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            setActionVisible(checked),
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                        action={
                          <Tooltip title="Delete">
                            <IconButton color="primary" onClick={remove.onTrue}>
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    </div>
                  )}

                    <IncomeStatementTableSheet data={tableData} />
                  {/*<BalanceSheetTable data={tableData} tableType={selectedTime} />*/}

              

                </TableContainer>

                {/*<TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />*/}
              </Card>
            </>
          )}
        </CardContent>
      </Card>
      {/*<BalanceSheetCreate
        open={create.value}
        onClose={create.onFalse}
        //reload={() => getBalacneSheet()}
        currentItem={selectedItem}
        closeform={() => handleCloseForm()}
      />*/}
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you want to delete selected items?"
        action={
          <Button
            variant="contained"
            className="dark-button"
            onClick={() => {
              //handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />

      <ConfirmDialog
        open={remove.value}
        onClose={remove.onFalse}
        title="Delete"
        content={<>Are you sure want to delete these items?</>}
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleBulkDelete();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default IncomeStatement;
