import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useBoolean } from 'src/hooks/use-boolean';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { dateformation } from 'src/components/date-format-mdy';
// import PurchaseOrderFollowUp from './purchase-order-follow-up';
// ----------------------------------------------------------------------
export default function UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, serialNumber,  reload }) {
    const { status, id, description, cost_codes } = row;

    const confirm = useBoolean();
    const popover = usePopover();
    const create = useBoolean();
    return (
        <>
            <TableRow hover selected={selected} >
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow} />
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell>{id}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell>{cost_codes[0]?.quantity}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{cost_codes[0]?.planned_labor_cost}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{cost_codes[0]?.planned_material_cost}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{cost_codes[0]?.planned_equipment_cost}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{cost_codes[0]?.planned_subcontractor_cost}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{cost_codes[0]?.total_direct_cost}</TableCell>
                <TableCell>{cost_codes[0]?.unit_price}</TableCell>
                <TableCell>{cost_codes[0]?.mark_ups}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{dateformation(cost_codes[0]?.planned_start_date)}</TableCell>
                <TableCell>{dateformation(cost_codes[0]?.planned_finish_date)}</TableCell>
                <TableCell>{cost_codes[0]?.duration}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>
                    {{
                        0: "Pending",
                        1: "Approved",
                        2: "Issues",
                        3: "Accepted",
                        4: "Shipped",
                        5: "Received"
                    }[status] || ""}
                </TableCell>
                <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>
            {/*//purchase order follow up*/}
            {/* <PurchaseOrderFollowUp
                open={create.value}
                onClose={create.onFalse}
                PONumber={purchase_order_number}
                POId={id}
                chartsAccountList={chartsAccountList}
                bankInformation={bankInformation}
                balance={balance}
                reload={reload}
            /> */}
            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
            >
                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onEditRow(row);
                        console.log(">>>>>>>", row)
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>
            </CustomPopover>
            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content="Are you sure you want to delete?"
                action={
                    <Button
                        variant="contained" color="error"
                        onClick={() => {
                            confirm.onFalse();
                            onDeleteRow(row.id);
                            console.log(">>>>>>>", row.id)
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
}
UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};