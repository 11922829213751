import { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import { Typography } from '@mui/material';
import { MultiFilePreview, Upload } from 'src/components/upload';
import FormProvider, { RHFAutocomplete, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { postRequest } from 'src/services';
import axios, { endpoints, simpleHeader } from 'src/utils/axios';
import { handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
const EmployeesCreate = ({ open, onClose, reload, currentItem, closeform, countries }) => {

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [files, setFiles] = useState([]);
    const [filePreview, setFilePreview] = useState([]);
    const [isFileDeleted, setIsFileDeleted] = useState(false);
    const [fileType, setFileType] = useState('')

    const NewUserSchema = Yup.object().shape({
        // first_name: Yup.string().required('First name is required'),
        // last_name: Yup.string().required('Last name is required'),
        // email: Yup.string().nullable(),
        // mobile: Yup.string().nullable(),
        // address1: Yup.string().nullable(),
        // address2: Yup.string().nullable(),
        // suite: Yup.string().nullable(),
        // city: Yup.string().nullable(),
        // state_id: Yup.string().nullable(),
        // zip: Yup.string().nullable(),
        // country_id: Yup.string().nullable(),
        // social_security_number: Yup.string().required('Social security number is required'),
        // signature: Yup.string().required('Signature is required'),
        // phone_country: Yup.string().nullable(),
        // union: Yup.string().required('Union is required'),
    });


    const defaultValues = useMemo(
        () => ({
            first_name: currentItem?.first_name || '',
            last_name: currentItem?.last_name || '',
            email: currentItem?.email || '',
            mobile: currentItem?.mobile || '',
            address1: currentItem?.address1 || '',
            address2: currentItem?.address2 || '',
            suite: currentItem?.suite || '',
            city: currentItem?.city || '',
            state_id: currentItem?.state_id || '',
            zip: currentItem?.zip || '',
            country_id: currentItem?.country_id || '',
            social_security_number: currentItem?.social_security_number || '',
            signature: currentItem?.signature || '',
            union: currentItem?.union || '',
            // phone_country: currentItem?.phone_country || 'US'
        }),
        [currentItem]
    );

    useEffect(() => {
        console.log("🚀 ~ defaultValues------:", defaultValues)
        if (currentItem) {
            if (currentItem.file != null) {
                setFilePreview([{ ...currentItem.file, preview: currentItem.file.url }]);
            } else {
                setFilePreview([]);
            }
        }
    }, [defaultValues]);


    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [currentItem, reset, defaultValues]);


    useEffect(() => {
        if (open && currentItem?.country?.id) {
            fetchStates(currentItem.country.id);
            setSelectedCountry(currentItem.country);
        }
    }, [currentItem, open]);

    const fetchStates = async (countryId) => {
        try {
            const response = await axios.get(
                endpoints.accounting.customers.get_states(countryId),
                {
                    headers: simpleHeader,
                }
            );
            setStates(response.data || []);
        } catch (error) {
            console.error('Error fetching states:', error);
            enqueueSnackbar('Failed to fetch states', { variant: 'error' });
            setStates([]);
        }
    };
    const handleCountryChange = async (event, newCountry) => {
        setSelectedCountry(newCountry);
        setValue('country', newCountry);
        // Clear the state field on country change
        setValue('state', null);
        setStates([]);

        // Fetch states if newCountry has an id
        if (newCountry?.id) {
            await fetchStates(newCountry.id);
        }
    };

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }));
            setFiles([...newFiles]);
        },
        [files]
    );

    const handleRemoveFile = (inputFile) => {
        setIsFileDeleted(true);
        setFiles([]);
        setFilePreview([]);
    };

    const handleFileType = (e) => {
        setFileType(e.target.value)
    }








    // Handle form submission
    const onSubmit = handleSubmit(async (data) => {
        console.log('Form data submitted-----------------:', data);
        setLoading(true);
        const formData = new FormData();
        // console.log("🚀 ~ onSubmit ~riskhange===== :", formData)
        formData.append("first_name", data?.first_name || '');
        formData.append("last_name", data?.last_name || '');
        formData.append("email", data?.email || '');
        formData.append("mobile", data?.mobile || '');
        formData.append("address1", data?.address1 || '');
        formData.append("address2", data?.address2 || '');
        formData.append("suite", data?.suite || '');
        formData.append("city", data?.city || '');
        formData.append("state_id", data?.state_id || '');
        formData.append("zip", data?.zip || '');
        formData.append("country_id", data?.country_id || '');
        formData.append("social_security_number", data?.social_security_number || '');
        // if (isFileDeleted) {
        //     formData.append('is_file_deleted', true);
        // }
        // if (files.length > 0) {
        //     formData.append('signature', files[0]);
        // }
        // formData.append("phone_country", data?.phone_country || 'US');
        formData.append("union", data?.union || '');


        try {
            const response = await postRequest(
                currentItem?.id
                    ? `${endpoints.accounting.employee.post_list}/${currentItem.id}?_method=PUT`
                    : endpoints.accounting.employee.post_list,
                formData,
                true
            );
            if (response.metadata && response.metadata.success) {
                reset();
                onClose();
                closeform();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response?.message || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>

                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
                    <span
                        className='cardColor'
                        style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                        &nbsp;&nbsp;  Employees  &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <IconButton onClick={onClose}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        borderRadius: '1px',
                    }}
                >

                    <span
                        style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                        }}
                    >
                        Base Information
                    </span>
                    <Box
                        mt={2}
                        rowGap={2}
                        columnGap={3}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >
                        <RHFTextField name="first_name" label="First Name" />
                        <RHFTextField name="last_name" label="Last Name" />
                        <RHFTextField type='number' name="social_security_number" label="Last 4 digits SS No." />
                        <RHFTextField type='number' name="union" label="Union Number / Code" />
                        <RHFSelect name="signature_type" label="Signature Type" value={fileType} onChange={handleFileType}>
                            <MenuItem value='Upload File'>Upload File</MenuItem>
                            <MenuItem value='Digital Signature'>Digital Signature</MenuItem>
                        </RHFSelect>
                        {/* <RHFAutocomplete
                            name="signature_type"
                            label="Signature Type"
                            options={['Upload File', 'Digital Signature']}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                            error={!!methods.formState.errors.approval_status}
                            helperText={methods.formState.errors.approval_status?.message}
                        /> */}

                        {/* <div  >
                            <label>
                                <span style={{ color: 'darkgrey' }}>Upload Signature</span>{' '}
                            </label>
                            <div>
                                <input
                                    type="file"
                                    className="form-control"
                                    //required
                                    style={{
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        padding: '8px',
                                        fontSize: '14px',
                                        marginTop: '8px',
                                        width: '400px',
                                        height: '50px',
                                    }}
                                />
                            </div>
                        </div> */}


                    </Box>
                    {fileType === 'Upload File' && <Box
                        mt={4}
                        rowGap={3}
                        display="grid"
                        // gridTemplateColumns={{
                        //     xs: 'repeat(1, 1fr)',
                        // }}
                        sx={{ width: '100%' }}
                    >
                        <Typography variant="subtitle2">Upload Signature</Typography>
                        <Upload onDrop={handleDrop} onRemove={handleRemoveFile} />
                    </Box>}
                    <Box sx={{ my: 3 }}>
                        <MultiFilePreview
                            files={files?.length > 0 ? files : filePreview}
                            onRemove={handleRemoveFile}
                            setFileDeleted={setIsFileDeleted}
                        />
                    </Box>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            //marginTop: '35px',
                        }}
                    >
                        <span
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            Additional Information
                        </span>
                        <input
                            type="checkbox"
                            style={{ width: '16px', height: '16px' }}
                            onChange={handleCheckboxChange}
                            checked={isChecked}
                        />
                    </div>

                    {isChecked && (
                        <>
                            <Box
                                mt={2}
                                rowGap={2}
                                columnGap={3}
                                display="grid"
                                gridTemplateColumns={{
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(2, 1fr)',
                                }}
                            >
                                <RHFTextField name="mobile" label="Phone Number" />
                                <RHFTextField name="email" label="Email" />
                                <RHFTextField name="address1" label="Address Line 1" />
                                <RHFTextField name="address2" label="Address Line 2" />
                                <RHFTextField name="suite" label="Suite" />
                                <RHFTextField name="city" label="City" />
                                <RHFAutocomplete
                                    name="country_id"
                                    label="Country"
                                    options={countries}
                                    getOptionLabel={(option) => option?.name || ''}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id || 'none'}>
                                            {option.name}
                                        </li>
                                    )}
                                    onChange={handleCountryChange}
                                    value={selectedCountry}
                                />
                                <RHFAutocomplete
                                    name="state_id"
                                    label="State"
                                    options={states}
                                    getOptionLabel={(option) => option?.name || ''}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option?.name}
                                        </li>
                                    )}
                                />
                                <RHFTextField name="zip" label="Zip Code" />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        maginLeft: '25px',
                                    }}
                                >
                                    <input type="checkbox" style={{ width: '16px', height: '16px' }} />
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Do you agree to receive text messages regarding the Project or Job and consent to
                                        access location data for completing timesheets ?
                                    </span>
                                </div>
                            </Box>
                        </>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" className='dark-button'
                        loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
export default EmployeesCreate;