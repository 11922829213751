import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { postRequestStaging } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { enqueueSnackbar } from 'notistack';
import { handleErrorMessages } from '../accounts-management/risk-register/feching';
// ----------------------------------------------------------------------
export default function AddEditCategory({ item, open, onClose, reload }) {
  //console.log("🚀 ~ AddEditCategory ~ item:", item)
  const [loading, setLoading] = useState(false);

  const NewUserSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    description: Yup.string().required('Description is required'),
  });

  const extractNumericId = (id) => {
    if (id === null || id === undefined) {
      console.warn('extractNumericId received null or undefined id:', id);
      return null;
    }
    const idStr = typeof id === 'string' ? id : String(id);
    const match = idStr.match(/^(\d+)/);
    if (!match) {
      console.warn('extractNumericId could not extract numeric ID from:', idStr);
    }
    return match ? match[1] : null;
  };
  const parseText = (text) => {
    if (!text) return { code: '', description: '' };
    const [code, description] = text.split('|').map(str => str.trim());
    return { code: code || '', description: description || '' };
  };

  const shouldPassId = useMemo(() => {
    if (!item || typeof item.$no_start === 'undefined') return false;
    return item.$no_start === true || item.$no_start === 'true';
  }, [item]);

  const defaultValues = useMemo(() => {
    if (shouldPassId ?item :"") {
      const { code, description } = parseText(item.text);
      return {
        ...(shouldPassId ? { id: extractNumericId(item.id) || '' } : {}),
        code: code,
        description: description,
      };
    }
    return {
      code: '',
      description: '',
    };
  }, [item, shouldPassId]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = handleSubmit(async (data) => {
    console.log('Form data submitted:', data);
    setLoading(true);
    const formData = new FormData();
    formData.append('code', data.code);
    formData.append('description', data.description);
    if (shouldPassId && data.id) {
      formData.append('id', data.id);
    }
    try {
      const response = await postRequestStaging(
        shouldPassId && data.id
          ? `${endpoints.ganttChart.post_catgory}/${extractNumericId(data.id)}?_method=PUT`
          : endpoints.ganttChart.post_catgory,
        formData,
        true
      );
      if (response.metadata && response.metadata.success) {
        reset();
        reload();
        onClose();
        enqueueSnackbar({
          variant: 'success',
          message: shouldPassId && data.id ? 'Category Updated successfully' : 'Category Created successfully',
        });
      } else {
        handleErrorMessages(response?.message || 'An error occurred.');
      }
    } catch (err) {
      console.error('Error:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    } finally {
      setLoading(false);
    }
  });
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => { onClose(); reset(); }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            p: (theme) => theme.spacing(3, 3, 2, 3),
            background: '#F4F6F8',
            mb: 2,
            position: 'relative', 
          }}
        >
          {shouldPassId ? 'Edit Category' : 'New Category'}
          <Iconify
            icon="ri:close-line"
            style={{
              position: 'absolute',
              top: '16px',
              right: '30px',
              cursor: 'pointer',
            }}
            onClick={() => { onClose(); reset(); }}
          />
        </DialogTitle>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Box
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              lg: 'repeat(1, 1fr)',
            }}
          >
            <RHFTextField name="code" label='Code' />
            <RHFTextField name="description" label='Description' />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => { onClose(); reset(); }}
            className="danager"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit" 
          >
            {shouldPassId ? "Update" : "Submit"}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

// Define prop types for the component
AddEditCategory.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    $no_start: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ]),
    // Include other relevant fields if necessary
  }),
};
