import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'src/routes/hooks';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';

import PayrollInfoTable from './payroll-info-table';

const PayrollView = () => {
  const searchParams = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
  console.log('myToken', token);
  const { id } = useParams();
  const [ChangeDetails, setchnageDetails] = useState();


  const getRiskChangeShow = async () => {
    const data = await getRequest(endpoints.risk_management.risk_change_request.riskchangeview(id));
    console.log('🚀 ~ getIncidentShow ~ data:', data);
    setchnageDetails(data);
  };
  return <PayrollInfoTable data={ChangeDetails} />;
};
export default PayrollView;
