import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FileAttachment from 'src/components/file-thumbnail/file-attachments';
// ----------------------------------------------------------------------
export default function UserTableRow({ row, isMaterial, selected, onEditRow, onSelectRow, onDeleteRow, currentItem, serialNumber, }) {
    const { date_of_receipt, amount_paid, payment_method, cheque_number, balance_to_paid, notes, receipt, bank, } = row;
    return (
        <>
            <TableRow hover selected={selected} >
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow} />
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell>{amount_paid}</TableCell>
                <TableCell>{date_of_receipt}</TableCell>
                <TableCell>{payment_method}</TableCell>
                <TableCell>{cheque_number}</TableCell>
                <TableCell>{bank?.bank_name}</TableCell>
                <TableCell>{ }</TableCell>
                <TableCell>{notes}</TableCell>
                <TableCell align="left">
                    {receipt?.url ? (
                        <a href={receipt?.url} target="_blank" rel="noopener noreferrer">
                            <FileAttachment imageView file={receipt?.url} sx={{ width: 28, height: 28 }} />
                        </a>
                    ) : (
                        "No File"
                    )}
                </TableCell>
                <TableCell>{balance_to_paid}</TableCell>
            </TableRow>
        </>
    );
}
UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};