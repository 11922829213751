/* eslint-disable no-lone-blocks */
import axios from 'axios';
import { apiPostHeader, multipartPartFormDataHeader, simpleHeader } from '../utils/axios';
import { baseURL, baseURLStaging } from '../config-global';
import { handleErrorMessages } from 'src/components/risk-management/risk-register/feching';
import { enqueueSnackbar } from "notistack";

export const postRequest = async (endPoint, data, isMultiPart) => {
  {
    try {
      const response = await axios.post(`${baseURL}${endPoint}`, data, {
        headers: isMultiPart ? multipartPartFormDataHeader : apiPostHeader
      });
      return response.data;
    }
    catch (error) {
      return error.response?.data

    }
  }
}
export const postRequestDummy = async (endPoint, data, isMultiPart, currentItem) => {
  {
    try {
      const response = await axios.post(`${baseURL}${endPoint}`, data, {
        headers: {
          ...simpleHeader,
          //currentprojectid: currentItem
        },
      });
      return response.data;
    }
    catch (error) {
      return error.response?.data

    }
  }
}

export const postRequestStaging = async (endPoint, data) => {
  {
    try {
      const response = await axios.post(
        `${baseURLStaging}${endPoint}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${`eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiNDc1ZDNhNDczZmNjY2VhZDJhM2IyNDIyMzk5YzM0NWEzOTk4NDZkMGQwNmMwNmI0NmQ1Zjc3NDkzZTU2NWU3NDRjOTgzZDg5NDIxMTdjMDIiLCJpYXQiOjE3MzQ1ODk5MzYuNDg5NjY0LCJuYmYiOjE3MzQ1ODk5MzYuNDg5NjY4LCJleHAiOjE3NjYxMjU5MzYuNDgxNzE4LCJzdWIiOiIyNjIwIiwic2NvcGVzIjpbXX0.i9s3SM4O0F9y4ONo1RZyFUWg6ZoKdw1NZzfxuSzibFFg27tKI67pCJmuGvuYVpbsblpaeZI1O7hfiAq5pvyt26hz-SXSe5xp9IOYqtX3ER0beXdVkQZZKrxUwPHL6eUIBSSMNIxkLDlwIKNRiXKmwAxICly8Z1IqU0-WQeqBjfrpwWynjk_7uVzAJGBdozZpAh43P2k5dQ06d-cqs8qrQ5PNbVkE8vUP0I5J8-EAXRRn-BnX6_v8JBZdF6KTubN3FTvARG8csWFTU1b_dJPYMTslm3XP_hd4M_cIc7gMC71cy24Nh70iro9PaeJoiz9m1HNZSuO-y6P9L7lspCwyvWXAWEYpCHpdtuZ-DKknTXq4EV_qjrOmBHegjAfcqyigFtFyTfH1xL52FPM2AlagwOr0MGlNAEJnT5lgOFvN-vCyNpWpSKfDoNrW1lb_P3hoOtmJqPYn6Ie5zb3QJNCJ1fOHqKO56sZqaKNPYvnYrwkeuPEaqyvxDxYSTZ8Vh0ZFtNeLZ6x1vTD-8J1jivf5dsgXYzV7OTNeIS39JRhboe2b-3uurMLx_MwphEcH0kkljXpnNcQKVJKnTioTyxA03uXT5Gaokp0GMGbYAsSPmiy_zwHjfHpkVPenmQRBDmKblAR0rvMPPnD2Ao9G1OHrTQkZru8O7soULWdvVt0pOU0`}`,
            currentCompanyId:2620,
            currentProjectId: 1297,
          },
        }
      );
      return response.data;
    }
    catch (error) {
      return error.response?.data

    }
  }
}

export const getRequest = async (endPoint, isArray = true) => {
  {
    try {
      const response = await axios.get(`${baseURL}${endPoint}`, {
        headers: simpleHeader
      });
      return response?.data?.payload || null;
    }
    catch (error) {
      return isArray ? [] : {}
    }
  }
}
export const getRequestStaging = async (endPoint, isArray = true) => {
  {
    try {
      const response = await axios.get(`${baseURL}${endPoint}`, {
        headers: simpleHeader
      });
      return response?.data?.payload || null;
    }
    catch (error) {
      return isArray ? [] : {}
    }
  }
}
export const getRequestDummy = async (endPoint, id, isArray = true) => {

  {
    try {
      const response = await axios.get(`${baseURLStaging}${endPoint}`, {
        headers: {
          ...simpleHeader,
          currentProjectId: 1297
        }
      }
      );
      return response?.data?.payload || null;
    }
    catch (error) {
      return isArray ? [] : {}
    }
  }
}


export const deleteRequest = async (endPoint, callBack) => {
  {
    try {
      const response = await axios.delete(`${baseURL}${endPoint}`, {
        headers: simpleHeader
      });
      if (response?.data) {
        const myMetaData = response?.data?.metadata
        if (myMetaData.success) {
          callBack()
          enqueueSnackbar({
            variant: 'success',
            message: myMetaData.message,
          });
        } else {
          handleErrorMessages(myMetaData.message);
        }
      }
      return response?.data
    }
    catch (error) {
      enqueueSnackbar({
        variant: 'error',
        message: 'Oops, something went wrong',
      });
      return error
    }
  }
}