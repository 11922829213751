import { useEffect, useState, useMemo, useCallback } from 'react';
import {
    Dialog, DialogContent, DialogTitle, IconButton, Card, TableContainer, Tooltip,
    Table,
    TableBody,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider from 'src/components/hook-form';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
import { useParams } from 'react-router';
import Scrollbar from 'src/components/scrollbar';
import { isEqual } from 'lodash';
import {
    emptyRows,
    getComparator,
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TablePaginationCustom,
    TableSelectedAction,
    useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import UserTableRow from './subcontractor-follow-up-history-table-row';
const SubContractorFollowUpHistory = ({ open, onClose, reload, currentItem, closeform, SANumber, SAId }) => {

    const theme = useTheme();
    const table = useTable();

    const remove = useBoolean()
    const confirm = useBoolean()

    const TABLE_HEAD = [
        { id: 'No.#', label: 'Serial Number', minWidth: 150 },
        { id: 'amount received', label: 'Amount paid', minWidth: 150 },
        { id: 'Project Name', label: 'Date of Receipt', minWidth: 150 },
        { id: 'Equipments Availability', label: 'Payment Method', minWidth: 150 },
        { id: 'Equipments Availability', label: 'Cheque/Account Number', minWidth: 200 },
        { id: 'Equipments Availability', label: 'Bank Name', minWidth: 150 },
        { id: 'Equipments Availability', label: 'DescrCheque/Card Expiry Date Option', minWidth: 300 },
        { id: 'Equipments Availability', label: 'Remarks / Notes', minWidth: 150 },
        { id: 'Equipments Availability', label: 'Attachment', minWidth: 150 },
        { id: 'Equipments Availability', label: 'Remaining Balance', minWidth: 170 },
        // { id: 'Action', label: 'Action' },
    ];

    const { projectId } = useParams()

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [riskIds, setRiskIds] = useState([]);
    const [TradeWorkers, setTradeWorkers] = useState([]);
    const [files, setFiles] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [actionVisible, setActionVisible] = useState(true);
    const [totalBalance, setTotalBalance] = useState(0);

    const NewUserSchema = Yup.object().shape({
        CSI_code: Yup.string().required('CSI Code is required'),
        equipment_code: Yup.string().required('Equipment Code is required'),
        equipment_description: Yup.string().required('Equipment Description is required'),
        unit: Yup.string().required('Unit is required'),
        unit_price: Yup.number().required('Unit Price is required'),
        quantity: Yup.number().required('Quantity is required'),
        cost_type: Yup.string().required('Cost Type is required'),
        operating_cost: Yup.number().required('Operating Cost is required'),
        rental_cost: Yup.number().nullable(),
        own_cost: Yup.number().nullable(),
        odometer_reading: Yup.string().nullable(),
        serial_number: Yup.string().nullable(),
        location: Yup.string().required('Location is required'),
        operated_by: Yup.string().required('Operator is required'),
        hours: Yup.number().required('Hours is required'),
    });


    const defaultValues = useMemo(
        () => ({
            CSI_code: currentItem?.CSI_code || '',
            equipment_code: currentItem?.equipment_code || '',
            equipment_description: currentItem?.equipment_description || '',
            unit: currentItem?.unit || '',
            unit_price: currentItem?.unit_price || '',
            quantity: currentItem?.quantity || '',
            cost_type: currentItem?.cost_type || '',
            operating_cost: currentItem?.operating_cost || '',
            rental_cost: currentItem?.rental_cost || '',
            own_cost: currentItem?.own_cost || '',
            odometer_reading: currentItem?.odometer_reading || '',
            serial_number: currentItem?.serial_number || '',
            location: currentItem?.location || '',
            operated_by: currentItem?.operated_by || '',
            hours: currentItem?.hours || '',
        }),
        [currentItem]
    );
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const [filters, setFilters] = useState({
        name: '',
        role: [],
        status: 'all',
    });

    const dataFiltered = applyFilter({
        inputData: tableData.length > 0 ? tableData : [],
        comparator: getComparator(table.order, table.orderBy),
        filters,
    });
    const dataInPage = dataFiltered.slice(
        table.page * table.rowsPerPage,
        table.page * table.rowsPerPage + table.rowsPerPage
    );

    const denseHeight = table.dense ? 52 : 72;
    const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [currentItem, reset, defaultValues]);

    useEffect(() => {
        getInvoiceHistory();
        // getTradeWorkers(projectId)   
    }, []);


    const getInvoiceHistory = async () => {
        setLoading(true)
        const data = await getRequest(`${endpoints.accounting.account_payable.subcontractor_invoice.get_list}${SAId}/sa-invoices`);
        setLoading(false)
        if (data) {
            setTableData(data.invoices)
            setTotalBalance(data.total_balance_to_paid)
        }
    }


    // const handleRemoveFile = (inputFile) => {
    //     setIsFileDeleted(true);
    //     setFiles([]);
    //     setFilePreview([]);
    // };

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }));
            setFiles([...newFiles]);
        },
        [files]
    );


    // Handle form submission
    const onSubmit = handleSubmit(async (data) => {

        setLoading(true);
        const formData = new FormData();

        formData.append('risk_id', data.risk_id.id);
        formData.append('request_id', data.request_id);
        formData.append('requested_changes', data.requested_changes);
        formData.append('reason_for_change', data.reason_for_change);
        formData.append('requested_by', data.requested_by);
        formData.append('approval_status', data.approval_status);
        try {
            const response = await postRequest(
                currentItem?.id
                    ? `${endpoints.risk_management.risk_change_request.crud}/${currentItem.id}?_method=PUT`
                    : endpoints.risk_management.risk_change_request.crud,
                formData,
                true
            );
            if (response.metadata && response.metadata.success) {
                reset();
                onClose();
                closeform();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response?.message || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });

    const closeAndReset = () => {
        reset();
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeAndReset();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>

                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, alignItems: 'center', boxSizing: 'content-box' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <span
                            className='cardColor'
                            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                            &nbsp;&nbsp;  Sub Contractor Follow Up History  &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </Box>
                    <IconButton onClick={closeAndReset}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        borderRadius: '1px',
                    }}
                >
                    <Card
                        className='cardColor'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            padding: '10px',
                            boxShadow: '0px 0px 30px 0px #64748B1A'
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Total Balance:</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{totalBalance}</Typography>
                    </Card>
                    <Card
                        sx={{
                            boxShadow: '0px 0px 30px 0px #64748B1A',
                        }}
                    >
                        <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                            {actionVisible && (
                                <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                                    <TableSelectedAction
                                        dense={table.dense}
                                        numSelected={table.selected.length}
                                        rowCount={tableData.length}
                                        onSelectAllRows={(checked) =>
                                            table.onSelectAllRows(
                                                setActionVisible(checked),
                                                checked,
                                                tableData.map((row) => row.id)
                                            )
                                        }
                                        action={
                                            <Tooltip title="Delete">
                                                <IconButton color="primary" onClick={remove.onTrue}>
                                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    />
                                </div>
                            )}
                            <Scrollbar>
                                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                                    <TableSelectedAction
                                        dense={table.dense}
                                        numSelected={table.selected.length}
                                        rowCount={tableData.length}
                                        onSelectAllRows={(checked) =>
                                            table.onSelectAllRows(
                                                checked,
                                                tableData.map((row) => row.id)
                                            )
                                        }
                                        action={
                                            <Tooltip title="Delete">
                                                <IconButton color="primary" onClick={confirm.onTrue}>
                                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    />

                                    <Scrollbar>
                                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                                            <TableHeadCustom
                                                sx={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                                order={table.order}
                                                orderBy={table.orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={tableData.length}
                                                numSelected={table.selected.length}
                                                onSort={table.onSort}
                                                onSelectAllRows={(checked) =>
                                                    table.onSelectAllRows(
                                                        checked,
                                                        tableData.map((row) => row.id)
                                                    )
                                                }
                                            />
                                            <TableBody>
                                                {dataFiltered
                                                    .slice(
                                                        table.page * table.rowsPerPage,
                                                        table.page * table.rowsPerPage + table.rowsPerPage
                                                    )
                                                    .map((row, index) => (
                                                        <UserTableRow
                                                            key={row.id}
                                                            row={row}
                                                            selected={table.selected.includes(row.id)}
                                                            onSelectRow={() => table.onSelectRow(row.id)}
                                                            // onDeleteRow={() => handleDeleteRow(row.id)}
                                                            // onEditRow={() => handleEditRow(row)}
                                                            SANumber={SANumber}
                                                            serialNumber={table.page * table.rowsPerPage + index + 1} // Calculate the serial number
                                                        />
                                                    ))}
                                                <TableEmptyRows
                                                    height={denseHeight}
                                                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                                                />

                                                <TableNoData notFound={notFound} />
                                            </TableBody>
                                        </Table>
                                    </Scrollbar>
                                </TableContainer>
                            </Scrollbar>
                        </TableContainer>
                        <TablePaginationCustom
                            count={dataFiltered.length}
                            page={table.page}
                            rowsPerPage={table.rowsPerPage}
                            onPageChange={table.onChangePage}
                            onRowsPerPageChange={table.onChangeRowsPerPage}
                            dense={table.dense}
                            onChangeDense={table.onChangeDense}
                        />
                    </Card>
                </DialogContent>

                {/* <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={closeAndReset}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" className='dark-button'
                        loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                </DialogActions> */}
            </FormProvider>
        </Dialog>
    );
};

function applyFilter({ inputData, comparator, filters }) {
    const { name, status, role } = filters;
    const stabilizedThis = inputData.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    inputData = stabilizedThis.map((el) => el[0]);
    if (name) {
        inputData = inputData.filter(
            (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
    }
    if (status !== 'all') {
        inputData = inputData.filter((user) => user.status === status);
    }
    if (role.length) {
        inputData = inputData.filter((user) => role.includes(user.role));
    }
    return inputData;
}

export default SubContractorFollowUpHistory;