export const calculationOfLabourHrs = (setValue, getValues) => (data, keyName) => {
  setValue(keyName, data);
  const laborCost = keyName === 'labor_cost' ? data : getValues('labor_cost');
  const laborHourlyRate = keyName === 'labor_hourly_rate' ? data : getValues('labor_hourly_rate');
  const laborHours = laborCost && laborHourlyRate ? laborCost / laborHourlyRate : 0;
  setValue('labor_hours', laborHours);
};

export const calculationOfDirectCost = (setValue, getValues) => (data, keyName) => {
  if (keyName !== 'labor_cost') {
    setValue(keyName, data);
  }

  const costKeys = ['labor_cost', 'material_cost', 'equipment_cost', 'subcontractor_cost', 'other_cost'];

  const directCost = costKeys.reduce((total, costKey) => {
    const value = costKey === keyName ? data : getValues(costKey);
    return total + (parseFloat(value) || 0); // Ensure numbers are parsed, defaulting to 0 if undefined
  }, 0);
  const quantity=getValues('quantity')
  const markUpPercent=getValues('mark_up_percent')
  if(quantity>0){
    setValue('direct_unit_price', directCost/quantity);
  }else{
    setValue('direct_unit_price', 0);
  }
  if(markUpPercent>0){
    setValue('mark_up_cost', (markUpPercent / 100) * directCost);
  }else{
    setValue('mark_up_cost', 0);
  }
  setValue('direct_cost', directCost);
  calculateMarkupBidAmount(setValue,getValues,'direct_cost',directCost)

};

export const calculationOfDirectUnitPrice = (setValue, getValues) => (data, keyName) => {
    setValue(keyName, data);
  const directCost =getValues('direct_cost');
  const directUnitPrice = data  ? directCost / data : 0;
  setValue('direct_unit_price', directUnitPrice);
};
export const calculationOfMarkupCost = (setValue, getValues) => (data, keyName) => {
    setValue(keyName, data);
  const directCost =getValues('direct_cost');
  const directUnitPrice = directCost  ? (data / 100) * directCost : 0;
  setValue('mark_up_cost', directUnitPrice);
  calculateMarkupBidAmount(setValue,getValues,'mark_up_cost',directUnitPrice)
};


const calculateMarkupBidAmount=(setValue,getValues,keyName,data)=>{
  const costKeys = ['direct_cost', 'mark_up_cost'];

  const bidAmount = costKeys.reduce((total, costKey) => {
    const value = costKey === keyName ? data : getValues(costKey);
    return total + (parseFloat(value) || 0); 
  }, 0);
  const quantity=getValues('quantity')
  setValue('bid_amount', bidAmount);
  if(quantity>0){
  setValue('bid_unit_price', bidAmount/quantity);
  }else{
  setValue('bid_unit_price', 0);

  }
}
export const calculationOfMarkupBidUnitPrice=(setValue, getValues) => (data, keyName) =>{
  const markupBidAmout=getValues('bid_amount')
 const bidUnitPrice=markupBidAmout/data
  setValue('bid_unit_price', bidUnitPrice);
}
