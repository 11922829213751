export const allColumns = [
    { name: "wbs", label: "WBS", width: 60, resize: true,className: "align-bottom", template: (gantt) => gantt.getWBSCode },
    { name: "text", label: "Task Name", tree: true, width: "200",align: "start",className: (row) => row.parent ? '' : 'parent-task' },
    { name: "start_date", label: "Start Date", align: "center", width: "100" },
    { name: "finish_date", label: "Finish Date", align: "center", width: "100" },
    { name: "duration", label: "Working Days", align: "center", width: "100" },
    { name: "total_duration", label: "Duration", align: "center", width: "80" },
    { name: "predecessor", label: "Predecessor", align: "center", width: "80" },
    { name: "successor", label: "Successor", align: "center", width: "80" },
    { name: "budgeted_amount", label: "Budgeted Amount", align: "center", width: "80" },
    { name: "budgeted_hours", label: "Budgeted Hours", align: "center", width: "80" },
    { name: "performed_amount", label: "Performed Amount", align: "center", width: "80" },
    { name: "performed_hours", label: "Performed Hours", align: "center", width: "80" },
    { name: "percentage", label: "% of Completion", align: "center", width: "80" },
    { name: "actual_start_date", label: "Actual Start Date", align: "center", width: "120" },
    { name: "add", label: "Create Button", width: 30 },
  ];
  
  export const generateColumns = (visibleColumns, gantt) => {
    return allColumns
      .filter((col) => visibleColumns[col.name])
      .map((col) => ({
        name: col.name,
        label: col.label,
        align: col.align || "left",
        width: col.width,
        template: col.template ? col.template(gantt) : undefined,
        tree: col.tree || false,
        resize: col.resize || false,
      }));
  };
  