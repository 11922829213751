import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
// ----------------------------------------------------------------------

export default function UserTableRow({ row, selected, serialNumber, onEditRow, onSelectRow, onDeleteRow }) {
    const { name, company, role, payroll_number, project_name, sub_contractor, weekend_ending, address, employees_count, employees_sum_gross_amount_earned, employees_sum_net_weekly_paid_wage } = row;

    const confirm = useBoolean();
    const quickEdit = useBoolean();
    const popover = usePopover();
    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow} />
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{serialNumber}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{payroll_number}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{project_name}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{sub_contractor}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{weekend_ending}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{address}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{employees_count}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{employees_sum_gross_amount_earned}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{employees_sum_net_weekly_paid_wage}</TableCell>
                <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <Tooltip title="Quick Edit" placement="top" arrow>
                        <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
                            <Iconify icon="solar:pen-bold" />
                        </IconButton>
                    </Tooltip>

                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>
            {/*<UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />*/}
            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
            >
                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onEditRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>
            </CustomPopover>
            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content="Are you sure want to delete?"
                action={
                    <Button variant="contained" color="error" onClick={onDeleteRow}>
                        Delete
                    </Button>
                }
            />
        </>
    );
}
UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};