
import { Card, CardContent } from '@mui/material';

import {

  Countercards,
 
} from 'src/components/accounts-management';


import HeadingCard from 'src/components/accounts-management/heading-card-dashboard';
  

export default function Dashboard() {
 
  return (
    <>
      <Card sx={{ width: '100%', margin: 'auto', mt: 4, p: 3, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
        <CardContent>
        <HeadingCard/>  
        <Countercards/>
        </CardContent>
      </Card>
    </>
  );
}



