import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import FileAttachment from 'src/components/file-thumbnail/file-attachments';
import { dateformation } from 'src/components/date-format-mdy';

// ----------------------------------------------------------------------

export default function UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, serialNumber }) {
    const { payment_method, date_of_receipt, notes, date_of_cheque, bank, cheque_number, days_left, amount_paid, balance_to_paid } = row;

    const confirm = useBoolean();
    const popover = usePopover();

    return (
        <>
            <TableRow hover selected={selected} >
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow} />
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell>{amount_paid}</TableCell>
                <TableCell>{dateformation(date_of_receipt)}</TableCell>
                <TableCell>{payment_method}</TableCell>
                <TableCell align="left">
                    {row?.receipt?.url ? (
                        <a href={row.receipt.url} target="_blank" rel="noopener noreferrer">
                            <FileAttachment imageView file={row.receipt.url} sx={{ width: 28, height: 28 }} />
                        </a>
                    ) : (
                        "No File"
                    )}
                </TableCell>
                <TableCell>{cheque_number}</TableCell>
                <TableCell>{date_of_cheque}</TableCell>
                <TableCell>{bank?.bank_name}</TableCell>
                <TableCell>{notes}</TableCell>
                <TableCell>{balance_to_paid}</TableCell>
                <TableCell>{days_left}</TableCell>
                <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>
            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
            >
                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        //onEditRow(row);
                        console.log(">>>>>>>", row)
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>
            </CustomPopover>
            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content="Are you sure you want to delete?"
                action={
                    <Button
                        variant="contained" color="error"
                        onClick={() => {
                            confirm.onFalse();
                            onDeleteRow(row.id);
                            console.log(">>>>>>>", row.id)
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
}
UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};