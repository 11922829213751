import { Box, InputAdornment } from "@mui/material";
import DropDownAddValueCustom from "src/components/drop-down-add-value/drop-down-add-value-custom";
import { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
export default function ActivitySixthRow({ icon, onMarkupAdd, onContingencyAdd, onEscalationAdd, onTaxAdd, onDiscountAdd,showDropdowns,enableDropdown,calculationofDirectCost,calculationofMarkupCost }) {
    let PercentIcon = (
      <Iconify icon="mdi:percent-outline" style={{ fontSize: '25px', marginTop: '7px' }} />
    );
  return (
    <>
      <RHFTextField
        onChange={(e)=>{
          calculationofDirectCost(e.target.value,'other_cost')
          }}
        type="number"
        name="other_cost"
        label="Other Cost"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />
   {!showDropdowns &&   <RHFTextField
    onChange={(e)=>{
      calculationofMarkupCost(e.target.value,'mark_up_percent')
      }}
   type='number' name="mark_up_percent" label='Mark Up %' 
           InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box component="span" sx={{ color: 'text.disabled' }}>
                  {PercentIcon}
                </Box>
              </InputAdornment>
            ),
          }}
           />}
           {
            showDropdowns && 
            <>
      {enableDropdown.includes('Markup') &&      <DropDownAddValueCustom
        name="markup"
        label={`Markup`}
        onClick={onMarkupAdd}
      // options={projectStageOptions}
      // reload={() => reloadProjectStageOptions()}
      // url={endpoints.risk_management.risk_register.identification.project_stages}
      />}

    {enableDropdown.includes('Contingency') &&     <DropDownAddValueCustom
        name="contingency"
        label={`Contingency`}
        onClick={onContingencyAdd}
      // options={projectStageOptions}
      // reload={() => reloadProjectStageOptions()}
      // url={endpoints.risk_management.risk_register.identification.project_stages}
      />}

     {enableDropdown.includes('Escalation') &&    <DropDownAddValueCustom
        name="escalation"
        label={`Escalation`}
        onClick={onEscalationAdd}
      // options={projectStageOptions}
      // reload={() => reloadProjectStageOptions()}
      // url={endpoints.risk_management.risk_register.identification.project_stages}
      />
}
     {enableDropdown.includes('Tax') &&    <DropDownAddValueCustom
        name="tax"
        label={`Tax`}
        onClick={onTaxAdd}
      // options={projectStageOptions}
      // reload={() => reloadProjectStageOptions()}
      // url={endpoints.risk_management.risk_register.identification.project_stages}
      />}

    {enableDropdown.includes('Discount') &&     <DropDownAddValueCustom
        name="discount"
        label={`Discount`}
        onClick={onDiscountAdd}
      // options={projectStageOptions}
      // reload={() => reloadProjectStageOptions()}
      // url={endpoints.risk_management.risk_register.identification.project_stages}
      />
          }  </>
           }
      

    </>
  );
}
