import { LoadingButton } from '@mui/lab';
import { CardContent, IconButton, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import Header from '../SideCards.js/header';
import CustomersCreate from './create';
import { getCsvData } from './getCsv';
import { TABLE_HEAD } from './customers-table-head';
import './Severity-rating-function/RiskAnalysis.css';
import UserTableRow from './customers-table-row';
import ImportDilaog from '../import-modal';

export default function Customers() {
  const csvLinkRef = useRef();
  const create = useBoolean();
  const remove = useBoolean();
  const table = useTable();
  const confirm = useBoolean();
  const add = useBoolean();
  const popover = usePopover();
  const popoverDots = usePopover();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [getLoading, setGetLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [countries, setcountries] = useState();


  const [filteredData, setFilteredData] = useState(tableData);

  const [filterss, setFilterss] = useState({
    customer_id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  });

  let data = [...tableData];


  useEffect(() => {
    Object.keys(filterss).forEach((key) => {
      const filterValue = filterss[key].trim();
      if (filterValue) {
        data = data.filter((item) => {
          const itemValue = item[key];
          if (!isNaN(filterValue)) {
            return Number(itemValue) === Number(filterValue);
          } else {
            return (itemValue?.toString().toLowerCase() || '').includes(filterValue.toLowerCase());
          }
        });
      }
    });

    console.log('Filtered Data:', data);
    setFilteredData(data);
  }, [filterss, tableData]);


  const handleFilterChange = (key, value) => {
    setFilterss((prev) => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    getCustomersList();
    getCountries();
  }, []);

  const getCustomersList = async () => {
    setGetLoading(true);
    const data = await getRequest(endpoints.accounting.customers.get_list);
    console.log('🚀 ~ getCustomersList ~ data:', data);
    setGetLoading(false);
    if (data) {
      setTableData(data);
    }
  };
  const getCountries = async () => {
    setGetLoading(true);
    const data = await getRequest(endpoints.accounting.customers.get_countries);
    setGetLoading(false);
    if (data) {
      setcountries(data?.countries);
    }
  };

  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: filteredData.length > 0 ? filteredData : [],
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  console.log('🚀 ~ Customers ~ tableData:', tableData);
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  console.log('🚀 ~ Customers ~ dataInPage:', dataInPage);
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const deleteCallBack = () => {
    confirm.onFalse();
    getCustomersList();
  };
  const handleDeleteRow = async (id) => {
    setDeleteLoading(true);
    await deleteRequest(`${endpoints.accounting.customers.delete}/${id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
    setDeleteLoading(false);
  };

  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log('🚀 ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_register.identification.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        getCustomersList();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  const handleEditRow = (item) => {
    setSelectedItem(item); // Set the selected item for editing
    add.onTrue();
  };

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const getCsvHeaders = () => {
    return TABLE_HEAD.filter(({ id }) => id !== 'actions') // Exclude the actions column explicitly
      .map(({ id, label }) => ({
        label,
        key: id,
      }));
  };
  const handleExport = () => {
    if (csvLinkRef.current) {
      const csvData = getCsvData(tableData);
      csvLinkRef.current.link.click(csvData);
    }
  };

  return (
    <>
      <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
        <CardContent>
          {getLoading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
              <Header name="CUSTOMERS" />
              <Box
                sx={{
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <div style={{}}></div>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  <Button
                    onClick={popover.onOpen}
                    className="dark-button"
                    variant="contained"
                    sx={{ mx: 1 }}
                    startIcon={<Iconify icon="mdi:filter" />}
                  >
                    Filter
                  </Button>
                  <Button
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                    onClick={() => {
                      setSelectedItem({});
                      add.onTrue();
                    }}
                  >
                    Create
                  </Button>
                  <IconButton onClick={popoverDots.onOpen}>
                    <Iconify icon="eva:more-vertical-fill" />
                  </IconButton>
                </Box>
                <CustomPopover
                  open={popoverDots.open}
                  onClose={popoverDots.onClose}
                  arrow="right-top"
                  sx={{ width: 140 }}
                >
                  {/* <MenuItem
                    onClick={() => {
                      create.onTrue();
                      popoverDots.onClose();
                    }}
                  >
                    <Iconify icon="solar:import-bold" />
                    Import
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      handleExport();
                      popoverDots.onClose();
                    }}
                  >
                    <Iconify icon="solar:export-bold" />
                    Export
                  </MenuItem>
                </CustomPopover>

                <CustomPopover
                  open={popover.open}
                  onClose={popover.onClose}
                  arrow="bottom-bottom"
                  sx={{ width: 1000, padding: 2, background: 'white !important' }}
                >
                  <MenuItem
                    disableRipple
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 2,
                      color: '#000',
                      background: 'white !important',
                      '&:hover': { backgroundColor: 'white !important' },
                    }}
                  >
                    <Iconify
                      icon="mdi:close"
                      onClick={popover.onClose}
                      sx={{
                        width: 20,
                        height: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        backgroundColor: '#F4F6F8',
                        color: '#637381',
                      }}
                    />

                    <TextField
                      label="Customer ID"
                      size="small"
                      value={filterss.customer_id}
                      onChange={(e) => handleFilterChange('customer_id', e.target.value)}
                      placeholder="Search.."
                      variant="outlined"
                      sx={{
                        flex: 1,
                        background: 'white !important',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#e0e0e0 !important',
                          },
                        },
                      }}
                    />

                    <TextField
                      label="First Name"
                      size="small"
                      value={filterss.first_name}
                      onChange={(e) => handleFilterChange('first_name', e.target.value)}
                      placeholder="Search.."
                      variant="outlined"
                      sx={{
                        flex: 1,
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#e0e0e0 !important',
                          },
                        },
                      }}
                    />
                    <TextField
                      label="Last Name"
                      size="small"
                      value={filters.last_name}
                      onChange={(e) => handleFilterChange('last_name', e.target.value)}
                      placeholder="Search.."
                      variant="outlined"
                      sx={{
                        flex: 1,
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#e0e0e0 !important',
                          },
                        },
                      }}
                    />

                    <TextField
                      label="Email"
                      value={filterss.email}
                      onChange={(e) => handleFilterChange('email', e.target.value)}
                      size="small"
                      placeholder="Search.."
                      variant="outlined"
                      sx={{
                        flex: 1,
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#e0e0e0 !important',
                          },
                        },
                      }}
                    />
                    <TextField
                      label="Phone No."
                      value={filterss.phone_number}
                      onChange={(e) => handleFilterChange('phone_number', e.target.value)}
                      size="small"
                      placeholder="Search.."
                      variant="outlined"
                      sx={{
                        flex: 1,
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#e0e0e0 !important',
                          },
                        },
                      }}
                    />
                  </MenuItem>
                </CustomPopover>
              </Box>
              <Card
                sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                }}
              >
                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                  <TableSelectedAction
                    dense={table.dense}
                    numSelected={table.selected.length}
                    rowCount={tableData.length}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                    action={
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={confirm.onTrue}>
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                  <Scrollbar>
                    <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                      <TableHeadCustom
                        order={table.order}
                        orderBy={table.orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={tableData.length}
                        numSelected={table.selected.length}
                        onSort={table.onSort}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                      />
                      <TableBody>
                        {dataFiltered
                          .slice(
                            table.page * table.rowsPerPage,
                            table.page * table.rowsPerPage + table.rowsPerPage
                          )
                          .map((row, index) => (
                            <UserTableRow
                              key={row.id}
                              row={row}
                              selected={table.selected.includes(row.id)}
                              onSelectRow={() => table.onSelectRow(row.id)}
                              onDeleteRow={() => handleDeleteRow(row.id)}
                              onEditRow={() => handleEditRow(row)}
                              serialNumber={table.page * table.rowsPerPage + index + 1}
                            />
                          ))}
                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                        />
                        <TableNoData notFound={notFound} />
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
          {
            <CSVLink
              ref={csvLinkRef}
              filename="Customers_Report.csv"
              headers={getCsvHeaders()}
              data={getCsvData(tableData)}
              style={{ display: 'none' }}
            />
          }
        </CardContent>
      </Card>

      <ImportDilaog
        open={create.value}
        onClose={create.onFalse}
        reload={() => { }}
        url={endpoints.risk_management.risk_register.identification.importFile} />
      <CustomersCreate
        currentItem={selectedItem}
        open={add.value}
        onClose={add.onFalse}
        reload={getCustomersList}
        closeform={() => handleCloseForm()}
        countries={countries}
      />
      <ConfirmDialog
        open={remove.value}
        onClose={remove.onFalse}
        title="Delete"
        content={<>Are you sure want to delete these items?</>}
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleBulkDelete();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}
function applyFilter({ inputData, comparator, filters }) {
  const { name, status, role } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);
  if (name) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }
  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }
  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }
  return inputData;
}
