import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
} from "@mui/material";
import Scrollbar from "src/components/scrollbar";

const IncomeStatementTableSheet = ({ data = {} }) => {
    console.log("🚀 ~ IncomeStatementTableSheet ~ data:", data);

    // Define table headers
    const headers = ["Account", "Period Total"];

    // Helper function to calculate totals for each section
    const calculateSectionTotals = (section) => {
        return data[section]?.reduce((total, item) => {
            return total + parseFloat(item.total || item.amount_paid || 0);
        }, 0);
    };

    // Helper function to render a section
    const renderSection = (sectionName, displayName) => {
        const sectionData = data[sectionName] || [];
        if (sectionData.length === 0) return null;

        return (
            <>
                {/* Section Header Row */}
                <TableRow>
                    <TableCell
                        colSpan={headers.length}
                        sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5",
                            textAlign: "center",
                        }}
                    >
                        {displayName}
                    </TableCell>
                </TableRow>

                {/* Entity Rows */}
                {sectionData.map((entity, idx) => (
                    <TableRow key={idx}>
                        <TableCell
                            sx={{
                                minWidth: "150px",
                                wordBreak: "break-word",
                            }}
                        >
                            {entity.account_chart?.name || "-"}
                        </TableCell>
                        <TableCell
                            sx={{
                                textAlign: "center",
                                minWidth: "150px",
                                wordBreak: "break-word",
                            }}
                        >
                            {entity.total || entity.amount_paid
                                ? `$${parseFloat(entity.total || entity.amount_paid).toLocaleString()}`
                                : "-"}
                        </TableCell>
                    </TableRow>
                ))}

                {/* Section Total Row */}
                <TableRow>
                    <TableCell
                        sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        Total {displayName}
                    </TableCell>
                    <TableCell
                        sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        {`$${calculateSectionTotals(sectionName).toLocaleString()}`}
                    </TableCell>
                </TableRow>
            </>
        );
    };

    return (
        <Card
            sx={{
                boxShadow: "0px 0px 30px 0px #64748B1A",
            }}
        >
            <TableContainer component={Paper}>
                <Scrollbar>
                    <Table>
                        {/* Table Header */}
                        <TableHead>
                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        sx={{
                                            fontWeight: "bold",
                                            minWidth: "120px",
                                            textAlign: "center",
                                            wordBreak: "break-word",
                                        }}
                                    >
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        <TableBody>
                            {renderSection("revenue", "Revenue")}
                            {renderSection("liabilities", "Liabilities")}
                            {renderSection("equity", "Equity")}
                        </TableBody>
                    </Table>
                </Scrollbar>
            </TableContainer>
        </Card>
    );
};

export default IncomeStatementTableSheet;
