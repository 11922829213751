// src/components/content/RiskRegister.js
import { useEffect, useState } from 'react';
import Header from '../header';
import { Box, Button, Card, CardContent, IconButton, Table, TableBody, TableContainer, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import { LoadingButton } from '@mui/lab';
import UserTableRow from './finance-table-row';
import FinanceCreateForm from './Finance-create';

const FinanceStatement = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({})
  const [actionVisible, setActionVisible] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [areIconsVisible, setAreIconsVisible] = useState(false);

  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.no' },
    { id: 'Statement ID', label: 'Statement ID' },
    { id: 'Statement Type', label: 'Statement Type' },
    { id: 'Reporting Period', label: 'Reporting Period' },
    { id: 'Company/Entity', label: 'Company/Entity' },
    { id: 'Department/ Division Filters', label: 'Department/ Division Filters' },
    { id: 'Account Grouping', label: 'Account Grouping' },
    { id: 'Include Subtotals by Category', label: 'Include Subtotals by Category' },
    { id: 'Comparative Analysis', label: 'Comparative Analysis' },
    { id: 'Variance Analysis', label: 'Variance Analysis' },
    { id: 'Currency Selection', label: 'Currency Selection' },
    { id: 'Rounding Level', label: 'Rounding Level' },
    { id: 'Notes', label: 'Notes' },
    { id: 'Auto-Calculation of Ratios', label: 'Auto-Calculation of Ratios' },
    { id: 'Approval Workflow', label: 'Approval Workflow' },
    { id: 'Export Options', label: 'Export Options' },
    //{ id: 'Scheduled Generation', label: 'Scheduled Generation' },
    { id: 'Action', label: 'Action' },
  ];

  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);

  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  useEffect(() => {
    getFinanceStatement();
  }, []);

  const getFinanceStatement = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.finance_statement.get_list);

    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };
  const deleteCallBack = () => {
    confirm.onFalse();
    getFinanceStatement()
  }
  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []);
  };
  const handleDeleteRow = async (id) => {
    setDeleteLoading(true);
    await deleteRequest(`${endpoints.accounting.finance_statement.delete}/${id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
    setDeleteLoading(false);
  };

  const handleEditRow = (item) => {
    setSelectedItem(item); 
    create.onTrue();

  };

  const handleBulkDelete = async () => {
    const Ids = table.selected;
    if (Ids.length > 0) {
      try {
        const response = await postRequest(endpoints.risk_management.risk_change_request.bulk_delete, { ids: Ids });
        remove.onFalse();
        setActionVisible(false);
        getFinanceStatement();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  return <>
    <Card
      sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
    >      <CardContent>
        {loading ? (
          <Box height={700}>
            <LoadingScreen />
          </Box>
        ) : (
          <>
            <Header name="FINANCE STATEMENT" />
            <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <div>
              </div>
              <Button
                onClick={() => {
                  setSelectedItem({});
                  create.onTrue();
                }}
                className="dark-button"
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Create
              </Button>
            </Box>
            <Card sx={{
              boxShadow: '0px 0px 30px 0px #64748B1A',
            }}>
              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                  dense={table.dense}
                  numSelected={table.selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  action={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={confirm.onTrue}>
                        <Iconify icon="solar:trash-bin-trash-bold" />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Scrollbar>
                  <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage
                        )
                        .map((row, index) => (
                          <UserTableRow
                            key={row.id}
                            row={row}
                            selected={table.selected.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                            onDeleteRow={() => handleDeleteRow(row.id)}
                            onEditRow={() => handleEditRow(row)}
                            serialNumber={table.page * table.rowsPerPage + index + 1}
                          />
                        ))}
                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                      />
                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={dataFiltered.length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </>
        )}
      </CardContent>
    </Card >
    <FinanceCreateForm
      open={create.value}
      onClose={create.onFalse}
      reload={() => getFinanceStatement()}
      currentItem={selectedItem}
      closeform={() => handleCloseForm()}
    />
    <ConfirmDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title="Delete"
      content="Are you want to delete selected items?"
      action={
        <Button
          variant="contained"
          className='dark-button'
          onClick={() => {
            confirm.onFalse();
          }}
        >
          Delete
        </Button>
      }
    />
    <ConfirmDialog
      open={remove.value}
      onClose={remove.onFalse}
      title="Delete"
      content={
        <>
          Are you sure want to delete these items?
        </>
      }
      action={
        <LoadingButton
          loading={deleteLoading}
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleBulkDelete();
          }}
        >
          Delete
        </LoadingButton>
      }
    />
  </>;
};
export default FinanceStatement;   