import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@mui/material";
import Scrollbar from "src/components/scrollbar";

const ProfitAndLossSheet = ({ data }) => {
    const headers = ["Account", ...data?.map((item) => item.period), "Year Total ($)"];

    const calculateYearTotal = (type) => {
        return data?.reduce((total, period) => total + (period[`total_${type}`] || 0), 0);
    };

    const renderSection = (type, sectionData) => {
        const consolidated = {};

        data?.forEach((period, index) => {
            const items = period.details[type] || [];
            items?.forEach((item) => {
                if (!consolidated[item.account_name]) {
                    consolidated[item.account_name] = Array(data.length).fill(0);
                }
                consolidated[item.account_name][index] = item.total_amount;
            });
        });

        return (
            <>
                <TableRow>
                    <TableCell
                        colSpan={headers.length}
                        sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </TableCell>
                </TableRow>

                {Object.entries(consolidated).map(([accountName, amounts]) => (
                    <TableRow key={accountName}>
                        <TableCell sx={{ fontWeight: "bold" }}>{accountName}</TableCell>
                        {amounts?.map((amount, index) => (
                            <TableCell key={index} align="center">
                                {amount ? `$${amount.toFixed(2)}` : "-"}
                            </TableCell>
                        ))}
                        <TableCell align="center">
                            ${amounts.reduce((sum, value) => sum + value, 0).toFixed(2)}
                        </TableCell>
                    </TableRow>
                ))}

                <TableRow>
                    <TableCell sx={{ fontWeight: "bold", backgroundColor: "#e0e0e0" }}>
                        Total {type.charAt(0).toUpperCase() + type.slice(1)}
                    </TableCell>
                    {data.map((period, index) => (
                        <TableCell key={index} align="center" sx={{ fontWeight: "bold" }}>
                            ${period[`total_${type}`]?.toFixed(2) || "0.00"}
                        </TableCell>
                    ))}
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        ${calculateYearTotal(type).toFixed(2)}
                    </TableCell>
                </TableRow>
            </>
        );
    };

    return (

        <TableContainer component={Paper}>
            <Scrollbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers?.map((header, index) => (
                                <TableCell
                                    key={index}
                                    align="center"
                                    sx={{ fontWeight: "bold", backgroundColor: "#e0e0e0" }}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderSection("revenue", data)}
                        {renderSection("expenses", data)}

                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold", backgroundColor: "#d1d1d1" }}>
                                Net Profit/Loss
                            </TableCell>
                            {data?.map((period, index) => (
                                <TableCell
                                    key={index}
                                    align="center"
                                    sx={{ fontWeight: "bold", color: period.net_profit_loss >= 0 ? "green" : "red" }}
                                >
                                    ${period.net_profit_loss?.toFixed(2) || "0.00"}
                                </TableCell>
                            ))}
                            <TableCell
                                align="center"
                                sx={{ fontWeight: "bold", color: calculateYearTotal("net_profit_loss") >= 0 ? "green" : "red" }}
                            >
                                ${calculateYearTotal("net_profit_loss").toFixed(2)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Scrollbar>
        </TableContainer>
    );
};

export default ProfitAndLossSheet;
