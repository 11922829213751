import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/material.css'
import { useForm } from 'react-hook-form';
import FormProvider, { RHFAutocomplete, RHFTextField, RHFPhoneInput } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import axios, { endpoints, simpleHeader } from 'src/utils/axios';
import * as Yup from 'yup';

import Iconify from 'src/components/iconify';
import { getRequest, postRequest } from 'src/services';
import { handleErrorMessages } from './feching';

const CustomersCreate = ({
  open,
  onClose,
  currentItem,
  reload,
  countries,
}) => {


  console.log("🚀 ~ currentItem:", currentItem)

  const USA = countries?.find(country => country.name === "United States");
  // console.log(USA)


  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  // ------------------------------
  //  Local States
  // ------------------------------
  const [isChecked, setIsChecked] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  // ------------------------------
  //  YUP Validation schema
  // ------------------------------
  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    company_name: Yup.string().required('Company Name is required'),
    email: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid Email').required('Email is required'),
    // phoneNumber: Yup.string().nullable()
  });

  // ------------------------------
  //  Default Form Values
  // ------------------------------
  const defaultValues = useMemo(
    () => ({
      first_name: currentItem?.first_name || '',
      last_name: currentItem?.last_name || '',
      company_name: companyName || currentItem?.company_name || '', // Ensure companyName takes precedence
      email: currentItem?.email || '',
      phoneNumber: currentItem?.phone || '',
      fax: currentItem?.fax || '',
      adress: currentItem?.adress || '',
      address_line_2: currentItem?.address_line_2 || '',
      suite: currentItem?.suite || '',
      country: currentItem?.country || null,
      state: currentItem?.state || null,
      city: currentItem?.city || '',
      zip_code: currentItem?.zip_code || '',
    }),
    [currentItem]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });


  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (open) {
      reset({ ...defaultValues, company_name: companyName });
    }
  }, [reset, defaultValues, open, companyName]);



  useEffect(() => {
    const fetchData = async () => {
      if (open) {
        setSelectedCountry(USA);
        try {
          const USAStates = await fetchStates(USA.id);
          const NY = USAStates?.find(state => state.name === "New York");
          setSelectedState(NY);
        } catch (error) {
          console.error("Error fetching states:", error);
        }
      }
    };

    fetchData();
  }, [currentItem, open]);


  useEffect(() => {
    if (open) {
      reset(defaultValues);
      setIsChecked(false)
    }
  }, [reset, defaultValues, open]);

  // ------------------------------
  //  API calls
  // ------------------------------
  const getProfile = async () => {
    try {
      const data = await getRequest(endpoints.profile_info);
      setCompanyName(data.company_name || '');
    } catch (error) {
      console.error('Error fetching profile: ', error);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        endpoints.accounting.customers.get_states(countryId),
        {
          headers: simpleHeader,
        }
      );
      setStates(response.data || []);
      return response.data
      // console.log(states, 'states--------------')
    } catch (error) {
      console.error('Error fetching states:', error);
      enqueueSnackbar('Failed to fetch states', { variant: 'error' });
      setStates([]);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setValue('suggestion', '');
  };

  const handleCountryChange = async (event, newCountry) => {
    setSelectedCountry(newCountry);
    setValue('country', newCountry);
    // Clear the state field on country change
    setValue('state', null);
    setStates([]);
    setSelectedState(null)

    // Fetch states if newCountry has an id
    if (newCountry?.id) {
      await fetchStates(newCountry.id);
    }
  };

  // ------------------------------
  //  Submit Handler
  // ------------------------------
  const onSubmit = handleSubmit(async (data) => {
    try {
      const formData = new FormData();
      formData.append('first_name', data.first_name);
      formData.append('last_name', data.last_name);
      formData.append('company_name', data.company_name);
      formData.append('email', data.email);
      formData.append('phone', data.phoneNumber);
      formData.append('fax', data.fax || '');
      formData.append('address', data.adress || '');
      formData.append('address_line_2', data.address_line_2 || '');
      formData.append('suite', data.suite || '');
      formData.append('country', data.country?.id || '');
      formData.append('state', data.state?.id || '');
      formData.append('city', data.city || '');
      formData.append('zip_code', data.zip_code || '');
      formData.append('suggestion', data.suggestion || '');

      const result = await postRequest(
        currentItem?.id
          ? `${endpoints.accounting.customers.post_list}/${currentItem.id}?_method=PUT`
          : endpoints.accounting.customers.post_list,
        formData,
        true
      );

      if (result.metadata?.success) {
        reload();
        reset();
        onClose();
        enqueueSnackbar(currentItem?.id ? 'Customer Updated successfully' : 'Customer created successfully', { variant: 'success' });
      } else {
        handleErrorMessages(result.message);
      }
    } catch (err) {
      enqueueSnackbar('Submission failed', { variant: 'error' });
      console.error('Error in catch:', err);
    }
  });

  // ------------------------------
  //  Close Handler
  // ------------------------------
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      maxWidth="md"
      sx={{
        borderRadius: 'none',
        padding: '60px',
        fontSize: '13px',
        fontFamily: 'arial',
      }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxSizing: 'content-box',
          }}
        >
          <span
            className="cardColor"
            style={{
              padding: '7px',
              marginLeft: '-25px',
              color: 'white',
              fontWeight: '700',
            }}
          >
            &nbsp;&nbsp; {currentItem.id ? "Update Customers" : 'Create Customers'} &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={handleClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderRadius: '1px' }}>
          <Box
            mt={2}
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="first_name" label={`First Name*`} />
            <RHFTextField name="last_name" label="Last Name*" />
            <RHFTextField name="company_name" label="Company Name" />
            <RHFTextField name="email" label="Email*" />
            {/* <RHFTextField name="phone" label="Phone Number" /> */}
            <RHFPhoneInput name="phoneNumber" label="Phone Number" />
            <RHFTextField name="fax" label="Fax" />
            <RHFTextField name="adress" label="Address Line 1" />
            <RHFTextField name="address_line_2" label="Address Line 2" />
            <RHFTextField name="suite" label="Suite" />
            {/* COUNTRY AUTOCOMPLETE */}
            <RHFAutocomplete
              name="country"
              label="Country"
              options={countries}
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderOption={(props, option) => (
                <li {...props} key={option.id || 'none'}>
                  {option.name}
                </li>
              )}
              onChange={handleCountryChange}
              value={selectedCountry}
            />
            <RHFAutocomplete
              name="state"
              label="State"
              options={states}
              value={selectedState}
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option?.name}
                </li>
              )}
            />

            <RHFTextField name="city" label="City" />
            <RHFTextField name="zip_code" label="Zip Code" />
          </Box>
          {/* Additional Info Checkbox */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginTop: '35px',
              marginLeft: '25px',
            }}
          >
            <span
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              Additional Information
            </span>
            <input
              type="checkbox"
              style={{ width: '16px', height: '16px' }}
              onChange={handleCheckboxChange}
              checked={isChecked}
            />
          </div>

          {isChecked && (
            <>
              <Box
                mt={2}
                rowGap={1}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFAutocomplete
                  name=""
                  label="Business Types"
                  options={['type1', 'type2', 'type3', 'type4']}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
                <RHFTextField
                  name=""
                  label="Tax Identification Number (Tin / EIN)"
                />
                <RHFAutocomplete
                  name=""
                  label="Client Category"
                  options={['category1', 'category2', 'category3', 'category4']}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
                <RHFAutocomplete
                  name=""
                  label="Preferred Payment Term"
                  options={['Net 15 Days', 'Net 30 Days', 'Net 60 Days']}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
                <RHFAutocomplete
                  name=""
                  label="Preferred Schedule Frequency"
                  options={['Weekly', 'Monthly', 'Quaterly', 'Yearly']}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
                <RHFAutocomplete
                  name=""
                  label="Customer Risk Level"
                  options={['High', 'Medium', 'Low']}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
                <RHFTextField name="" label="Account Manager" />
              </Box>

              <Box style={{ marginTop: '10px' }}>
                <RHFTextField
                  name="suggestion"
                  label="Notes/Remarks"
                  multiline
                  rows={4}
                />
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            className="dark-button"
            loading={isSubmitting}
          >
            {currentItem.id ? "Update" : 'Submit'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default CustomersCreate;