import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { endpoints } from 'src/utils/axios';
import * as Yup from 'yup';

import Iconify from 'src/components/iconify';
import { postRequest } from 'src/services';
import { DatePicker } from '@mui/x-date-pickers';

const BussinessCreateForm = ({
    open,
    onClose,
    currentItem,
    reload,
    userList
}) => {
    console.log("🚀 ~ currentItem:", currentItem)
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    // ------------------------------

    const NewUserSchema = Yup.object().shape({
        //tax_type: Yup.string().required('Tax Type is required'),
        //business_amount: Yup.number().required('Business Amount is required'),
        //taxable_amount: Yup.number().required('Taxable Amount is required'),
        //tax_percentage: Yup.number().required('Tax Rate is required'),
        //tax_amount: Yup.number().required('Tax Amount is required'),
        //paid_by: Yup.object().nullable().required('Paid By is required'),
        //frequency: Yup.string().required('Frequency is required'),
    });

    const defaultValues = useMemo(
        () => ({
            tax_type: currentItem?.tax_type || '',
            business_amount: currentItem?.business_amount || '',
            taxable_amount: currentItem?.taxable_amount || '',
            tax_percentage: currentItem?.tax_percentage || '',
            tax_amount: currentItem?.tax_amount || '',
            paid_by: currentItem?.paid_by?.id || null, // Use the entire object
            frequency: currentItem?.frequency || '',
        }),
        [currentItem]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        if (open) {
            reset(defaultValues);
        }
    }, [reset, defaultValues, open]);

    const onSubmit = handleSubmit(async (data) => {
        try {
            const formData = new FormData();
            formData.append('tax_type', data.tax_type);
            formData.append('business_amount', data.business_amount);
            formData.append('taxable_amount', data.taxable_amount);
            formData.append('tax_percentage', data.tax_percentage);
            formData.append('tax_amount', data.tax_amount);
            formData.append('paid_by', data.paid_by?.id || ''); 
            formData.append('frequency', data.frequency);

            const result = await postRequest(
                currentItem?.id
                    ? `${endpoints.accounting.taxes.business_taxes.crud_list}/${currentItem.id}?_method=PUT`
                    : endpoints.accounting.taxes.business_taxes.crud_list,
                formData,
                true
            );

            if (result.metadata?.success) {
                reload();
                reset();
                onClose();
                enqueueSnackbar(currentItem?.id ? 'Business Tax Updated successfully' : 'Business Tax created successfully', { variant: 'success' });
            } else {
                enqueueSnackbar('Failed to save data', { variant: 'error' });
            }
        } catch (err) {
            enqueueSnackbar('Submission failed', { variant: 'error' });
            console.error('Error in catch:', err);
        }
    });

    const handleClose = () => {
        onClose();
    };
    
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxSizing: 'content-box',
                    }}
                >
                    <span
                        className="cardColor"
                        style={{
                            padding: '7px',
                            marginLeft: '-25px',
                            color: 'white',
                            fontWeight: '700',
                        }}
                    >
                        &nbsp;&nbsp; Bussiness Tax &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <IconButton onClick={handleClose}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ borderRadius: '1px' }}>
                    <Box
                        mt={2}
                        rowGap={2}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >

                           <RHFAutocomplete
                                          name="tax_type"
                                          label="Tax Type"
                                          options={['State','Federal','Local']}
                                          getOptionLabel={(option) => option}
                                          isOptionEqualToValue={(option, value) => option === value}
                                          renderOption={(props, option) => (
                                            <li {...props} key={option}>
                                              {option}
                                            </li>
                                          )}
                                        />
                        <RHFTextField name="business_amount" label="Bussiness Amount" type="number" />
                        <RHFTextField name="taxable_amount" label="Taxable Amount" type="number" />
                        <RHFTextField name="tax_percentage" label="Tax Rate (%)" type="number" />
                        <RHFTextField name="tax_amount" label="Tax Amount" type="number" />
                        <RHFAutocomplete
                            name="paid_by"
                            label="Paid by"
                            options={userList}
                            getOptionLabel={(option) => option?.name || ''}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id} // Compare objects by ID
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                        />

                        <RHFAutocomplete
                            name="frequency"
                            label="Frequency"
                            options={['Monthly', 'Quarterly', 'Yearly']}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => (
                                <li {...props} key={option}>
                                    {option}
                                </li>
                            )}
                        />
                        <Controller
                            name="date"
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    label="Date"
                                    {...field}
                                    inputFormat="yyyy-MM-dd"
                                    disableFuture
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            )}
                        />

                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={handleClose}>
                        Close
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        className="dark-button"
                        loading={isSubmitting}
                    >
                        {currentItem.id ? "Update" : 'Create'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
export default BussinessCreateForm;