import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, InputAdornment, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import FormProvider, { RequiredLabel, RHFSelect, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { DatePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

export default function AddEditTask({  item, open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState('');

  // Validation schema
  const NewUserSchema = Yup.object().shape({
    description: Yup.string().required('Cost Code Task Description is required'),
    start_date:  null,
    end_date:  null,


  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      description:'',
    }),
    [item]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = handleSubmit(async (value) => {

 
  });
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => { onClose(); reset(); }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3), background: '#F4F6F8', mb: 2 }}>
          New Task
          <Iconify
            icon="ri:close-line"
            style={{ position: 'absolute', right: '30px', cursor: 'pointer' }}
            onClick={() => { onClose(); reset(); }}
          />
        </DialogTitle>

        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Box
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              lg: 'repeat(1, 1fr)',
            }}
          >
            <RHFTextField  name="description" label='Cost Code Task Description' />
            <Controller
                  name="start_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label="Start Date"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                        const endDate = methods.getValues('end_date');
                        if (newValue && endDate && newValue > endDate) {
                          setError('start_date', { message: 'Start Date cannot be later than End Date' });
                        } else {
                 
                          clearErrors('start_date');
                          clearErrors('end_date');
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error || !!warning,
                          helperText: error?.message || warning,
                        },
                      }}
                    />
                  )}
                />
                <Controller
                  name="end_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label={"End Date"}
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                        const startDate = methods.getValues('start_date');
                        if (newValue && startDate && newValue < startDate) {
                          setError('end_date', { message: 'End Date cannot be earlier than Start Date' });
                        } else {
                          clearErrors('end_date');
                          clearErrors('start_date');
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error || !!warning,
                          helperText: error?.message || warning,
                        },
                      }}
                    />
                  )}
                />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => { onClose(); reset(); }} className="danager">
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" onClick={onSubmit}>
             Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

AddEditTask.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  item: PropTypes.object,
};
