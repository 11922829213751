import React, { useEffect, useState, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { MenuItem } from '@mui/material';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
const GenerateMaterialCreate = ({ open, onClose, reload, currentItem, closeform }) => {

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [riskIds, setRiskIds] = useState([]);

    const NewUserSchema = Yup.object().shape({
        csi_code: Yup.string().required('CSI Code is required'),
        material_code: Yup.string().required('Material Code is required'),
        material_description: Yup.string().required('Material Description is required'),
        unit: Yup.number().required('Unit is required'),
        unit_price: Yup.number().required('Unit Price is required'),
        quantity: Yup.number().required('Quantity is required'),
        cost: Yup.number().required('Material Cost is required'),
        submittle: Yup.string().nullable(),
        submittle_number: Yup.string().nullable(),
        type: Yup.string().required('Type is required'),
    });


    const defaultValues = useMemo(
        () => ({
            csi_code: currentItem?.csi_code || '',
            material_code: currentItem?.material_code || '',
            material_description: currentItem?.material_description || '',
            unit: currentItem?.unit || '',
            unit_price: currentItem?.unit_price || '',
            quantity: currentItem?.quantity || '',
            cost: currentItem?.cost || '',
            submittle: currentItem?.submittle || '',
            submittle_number: currentItem?.submittle_number || '',
            type: currentItem?.type || '',
        }),
        [currentItem]
    );
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [currentItem, reset, defaultValues]);


   
    // Handle form submission
    const onSubmit = handleSubmit(async (data) => {

        setLoading(true);
        const formData = new FormData();

        formData.append('risk_id', data.risk_id.id);
        formData.append('request_id', data.request_id);
        formData.append('requested_changes', data.requested_changes);
        formData.append('reason_for_change', data.reason_for_change);
        formData.append('requested_by', data.requested_by);
        formData.append('approval_status', data.approval_status);
        try {
            const response = await postRequest(
                currentItem?.id
                    ? `${endpoints.risk_management.risk_change_request.crud}/${currentItem.id}?_method=PUT`
                    : endpoints.risk_management.risk_change_request.crud,
                formData,
                true
            );
            if (response.metadata && response.metadata.success) {
                reset();
                onClose();
                closeform();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response?.message || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });

    const closeAndReset = () => {
        reset();
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeAndReset();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>

                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
                    <span
                        className='cardColor'
                        style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                        &nbsp;&nbsp;  CREATE MATERIAL  &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <IconButton onClick={closeAndReset}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        borderRadius: '1px',
                    }}
                >
                    <Box
                        mt={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >

                        <RHFTextField name="CSI_code" label="CSI Code" required />
                        <RHFTextField name="material_code" label="Material Code" required />
                        <RHFTextField name="Material Description" label="Material Description" required />
                        <RHFTextField name="unit" type="number" label="Unit" required />
                        <RHFTextField name="unit_price" type="number" label="Unit Price" required />
                        <RHFTextField name="quantity" type="number" label="Quantity" required />
                        <RHFTextField name="cost" type="number" label="Material Cost" required />
                        <RHFTextField name="submittle" label="Submittle" />
                        <RHFTextField name="submittle_number" label="Submittle Number" />
                        <RHFSelect name="type" label="Type">
                            <MenuItem value="Permanent">Permanent</MenuItem>
                            <MenuItem value="Construction">Construction</MenuItem>
                        </RHFSelect>

                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={closeAndReset}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" className='dark-button'
                        loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
export default GenerateMaterialCreate;