import { Box, MenuItem, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { RHFSelect, RHFTextField } from "src/components/hook-form";

export default function ActivityScheduleRow({ 
  control,
  setValue,
  setError, 
  methods,
  clearErrors,
  handleChangeDuration,
  handleStartDateChange
}) {
  const [warning, setWarning] = useState('');

  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ pb: 2, pt: 2 }} className="heading1">
          Schedule
        </Typography>
        <Box
          mt={2}
          mb={2}
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
        >
          <RHFSelect name="predecessor_activities" label="Predecessor Activities">
            <MenuItem value="item 1">
              {'Item 1'} {/* map items here */}
            </MenuItem>
          </RHFSelect>
          <RHFSelect name="successor" label="Successor">
            <MenuItem value="item 1">
              {'Item 1'} {/* map items here */}
            </MenuItem>
          </RHFSelect>
          <RHFTextField onChange={handleChangeDuration} type="number" name="duration" label="Duration (in days)" required />
          <Controller
            name="start_date"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Start Date"
                value={field.value}
                onChange={(newValue) => {
                  handleStartDateChange(newValue)
                  field.onChange(newValue);
                  const endDate = methods.getValues('end_date');
                  if (newValue && endDate && newValue > endDate) {
                    setError('start_date', {
                      message: 'Start Date cannot be later than End Date',
                    });
                  } else {
                    clearErrors('start_date');
                    clearErrors('end_date');
                  }
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error || !!warning,
                    helperText: error?.message || warning,
                  },
                }}
              />
            )}
          />
          <Controller
            name="end_date"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
               disabled
                label={'Finish Date'}
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                  const startDate = methods.getValues('start_date');
                  if (newValue && startDate && newValue < startDate) {
                    setError('end_date', {
                      message: 'End Date cannot be earlier than Start Date',
                    });
                  } else {
                    clearErrors('end_date');
                    clearErrors('start_date');
                  }
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error || !!warning,
                    helperText: error?.message || warning,
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
}
