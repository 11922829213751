import { Helmet } from 'react-helmet-async';
import DashboardHeader from 'src/components/accounts-management/dashboard-Header';
import Dashboard from './accounts-dashboard';
import { useSearchParams } from 'src/routes/hooks';
import { useEffect } from 'react';
// ----------------------------------------------------------------------
export default function AccountsDashboard() {
  //const searchParams = useSearchParams();
  //const token =  `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiMTU4ZTFmNWIyM2M4MjM3NjEzM2QyNzg3YWRmMjc3MDllNTk5OTQxM2Q5NzNhMzFkZTZlZTk5ODQ2MDM1N2UzOThhMWE4ZDNkNDM4ZWMzNzAiLCJpYXQiOjE3MzQ1OTAyMDMuMDI4MjY1LCJuYmYiOjE3MzQ1OTAyMDMuMDI4MjY5LCJleHAiOjE3NjYxMjYyMDMuMDIwMDksInN1YiI6IjI2MjAiLCJzY29wZXMiOltdfQ.e51Rxl9IN5yc25ObPpU_p8jR24NLJoFqy0qm3twe12nbpTvME_nDwJxqrecrHL5vScdoD0AGt7NNu_6kkL-SiaSN4LbWYPaY_LTScPibUTmhVlw764M4QzjhTLDrKlZ0czOKdHypJ1e5NOzJi9yO36BhAj02Lak10h4HUM_Koj_mjtAtOi7zKZPL1ydmiztvum84HWDFf_rSjIY_5zzRcrHdpkIJC5yCjQmfNlk-Aqw22caL9vwvkEjIT2KO9_OVAR61eTOiU4OvA7Ocp8HkzOWIRqLGQo4hFpM__nFhV3YxKiS0NDGpbPJkC_r8WMxsg43Eubi7F4Zjzxe-1AQyJ6Vih91vL5d6id0yHlrBe6s_Dqm3lwDSdEkA4TAkqEvz--GAmmP6tWymCNImzv3ltxmiqXihdCegKSj6fM7ZbopQ2Wuwua4UgfRyOzYOg9OaL0BpcfvseLkOgYsuWigkPtRqcO_YhBishf7sMgEUUCBpwjhEVTasv5_CrfqEe3tClDJ-i7o3wvWeuzr_j8a3bv95iDkIh0r-n0KysKP45zO4IJOurbj9mtf4PbGiwzYw9BL-j3dYrAP9pJ2S3_orsQyGtrJOd3eN34iLVOV1eJkZUF2efsU6UnM4c7qOsDFGkjAEOErRp8Sj2H1k-eTXT5rNbDd4SZ-mQ9LMoY5UTsQ`;
  ////const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
  //const project = searchParams.get('project_id') || localStorage.getItem('projectId') || '';
  //// const company = 16551;
  //const company = 2620;
  //useEffect(() => {
  //  console.log('token', token);
  //  localStorage.setItem('authToken', token);
  //  localStorage.setItem('projectId', project);
  //  localStorage.setItem('companyId', company);
  //}, [token]);
  const searchParams = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
  const project = searchParams.get('project_id') || localStorage.getItem('projectId') || '';
  const company = searchParams.get('company_id') || localStorage.getItem('companyId') || '';
  useEffect(() => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('projectId', project);
    localStorage.setItem('companyId', company);
  }, [token]);
  return (
    <>
      <Helmet>
        <title>Accounts</title>
      </Helmet>
      <DashboardHeader Title="Accounting Dashboard"  />
       <Dashboard/>
    </>
  );
}