// src/components/content/RiskRegister.js
import { useCallback, useEffect, useState } from 'react';
import OrderTableToolbar from './weekly-report-filter';
import UserTableRow from './payroll-table-row';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent, IconButton,
  MenuItem,
  Select,
  Table,
  TableBody, TableContainer, Tooltip
} from '@mui/material';
import { isEqual } from 'lodash';
import { useNavigate, useParams } from 'react-router';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import Header from '../header';

const Payroll = () => {
  const navigate = useNavigate();
const  { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);

  const [showCreateView, setShowCreateView] = useState(false);

  const handleCreateClick = () => {
    setShowCreateView(true);
  };

  const handleBackClick = () => {
    setShowCreateView(false);
  };

  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const handleNavigate = () => {
    navigate(`/accounts-management/create-weeklypayroll/${id}`);
  };

  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.no' },
    { id: 'Date', label: 'Date' },
    { id: 'Facility', label: 'Facility' },
    { id: 'Scope of work', label: 'Scope of work' },
    { id: 'Supervisor', label: 'Supervisor' },
    { id: 'Week Ending', label: 'Week Ending' },
    { id: 'Job Order Number / Project Number', label: 'Job Order Number / Project Number' },
    { id: 'Status', label: 'Status' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [projectList, setprojectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('All');


  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  console.log('🚀 ~ RiskChangeRequest ~ dataInPage:', dataInPage);
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback(
    (id) => {
      setTableData((prevTableData) => {
        const deleteRow = prevTableData.filter((row) => row.id !== id);
        table.onUpdatePageDeleteRow(dataInPage.length);
        return deleteRow;
      });
    },
    [dataInPage.length, table]
  );

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  useEffect(() => {
    getWeeklyPayroll(selectedProject);
    getProjects();

  }, []);

  const getWeeklyPayroll = async (projectId) => {
    setLoading(true);
    const endpoint = projectId && projectId !== 'All'
      ? `${endpoints.accounting.payroll.weeklyPayroll.get_payrolls}?project_id=${projectId}`
      : endpoints.accounting.payroll.weeklyPayroll.get_payrolls;
    const data = await getRequest(endpoint);
    console.log('🚀 ~ getWeeklyPayroll ~ data:', data);
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };

  const getProjects = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_project_list);
    console.log('🚀 ~ getProjets ~ data:', data);
    if (data) {
      setprojectList(data);

    }
  };

  const handleChange = (event) => {
    const projectId = event.target.value
    setSelectedProject(projectId);
    getWeeklyPayroll(projectId)
    console.log('Selected Project ID:', event.target.value);
  };
  const deleteCallBack = () => {
    confirm.onFalse();
    getWeeklyPayroll();
  };

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []);
  };

  const handleDeleteRows = async (id) => {
    await deleteRequest(
      `${endpoints.risk_management.risk_change_request.crud}/${selectedItem.id}`,
      deleteCallBack
    );
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  };

  const defaultFilters = {
    name: '',
    status: 'all',
    startDate: null,
    endDate: null,
  };

  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log('🚀 ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_change_request.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false);
        getWeeklyPayroll();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.order.details(id));
    },
    [router]
  );
  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <>
      <div>
        <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
          {' '}
          <CardContent>
            {loading ? (
              <Box height={700}>
                <LoadingScreen />
              </Box>
            ) : (
              <>
                <Header name="WEEKLY PAYROLL / TIMESHEET" />

                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: '20px',
                    justifyContent: 'space-between',
                    gap: '12px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: 1,
                      padding: '5px 0',
                      marginTop: '10px',
                    }}
                  >
                    <h5
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#333',
                        margin: '0',
                        textTransform: 'none',
                      }}
                    >
                      Project Name
                    </h5>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <Select
                        sx={{ minWidth: 65, height: 35 }}
                        value={selectedProject}
                        onChange={handleChange}
                      >
                        <MenuItem value="All">All</MenuItem>
                        {projectList?.map((project) => (
                          <MenuItem key={project.id} value={project.id}>
                            {project.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <OrderTableToolbar
                        filters={filters}
                        onFilters={handleFilters}
                        //
                        //defaultStartDate={defaultStartDate}
                        canReset={canReset}
                        onResetFilters={handleResetFilters}
                      />
                    </Box>
                  </Box>

                  <Button
                    sx={{ minWidth: 65, height: 35, marginTop: '50px' }}
                    onClick={handleNavigate}
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                  >
                    Create Time Sheet
                  </Button>
                </Box>

                <Card
                  sx={{
                    boxShadow: '0px 0px 30px 0px #64748B1A',
                  }}
                >
                  <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <TableSelectedAction
                      dense={table.dense}
                      numSelected={table.selected.length}
                      rowCount={tableData.length}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                      action={
                        <Tooltip title="Delete">
                          <IconButton color="primary" onClick={confirm.onTrue}>
                            <Iconify icon="solar:trash-bin-trash-bold" />
                          </IconButton>
                        </Tooltip>
                      }
                    />

                    <Scrollbar>
                      <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                        <TableHeadCustom
                          order={table.order}
                          orderBy={table.orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={tableData.length}
                          numSelected={table.selected.length}
                          onSort={table.onSort}
                          onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                              checked,
                              tableData.map((row) => row.id)
                            )
                          }
                        />
                        <TableBody>
                          {dataFiltered
                            .slice(
                              table.page * table.rowsPerPage,
                              table.page * table.rowsPerPage + table.rowsPerPage
                            )
                            .map((row, index) => (
                              <UserTableRow
                                key={row.id}
                                row={row}
                                selected={table.selected.includes(row.id)}
                                onSelectRow={() => table.onSelectRow(row.id)}
                                //onDeleteRow={() => handleDeleteRow(row.id)}
                                //onEditRow={() => handleEditRow(row.id)}
                                serialNumber={table.page * table.rowsPerPage + index + 1} 

                              />
                            ))}

                          <TableEmptyRows
                            height={denseHeight}
                            emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                          />
                          <TableNoData notFound={notFound} />
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>

                  <TablePaginationCustom
                    count={dataFiltered.length}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                  />
                </Card>
              </>
            )}
          </CardContent>
        </Card>
        {/*<RiskChangeRequestCreate
          open={create.value}
          onClose={create.onFalse}
          reload={() => getWeeklyPayroll()}
          currentItem={selectedItem}
          closeform={() => handleCloseForm()}
        />*/}
        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content="Are you want to delete selected items?"
          action={
            <Button
              variant="contained"
              className="dark-button"
              onClick={() => {
                handleDeleteRows();
                confirm.onFalse();
              }}
            >
              Delete
            </Button>
          }
        />

        <ConfirmDialog
          open={remove.value}
          onClose={remove.onFalse}
          title="Delete"
          content={<>Are you sure want to delete these items?</>}
          action={
            <LoadingButton
              loading={deleteLoading}
              variant="contained"
              className="dark-button"
              onClick={() => {
                handleBulkDelete();
              }}
            >
              Delete
            </LoadingButton>
          }
        />
      </div>
    </>
  );
};

export default Payroll;
