// exportCsvData.js

import { dateformation } from "src/components/date-format-mdy";

export const getCsvData = (tableData = {}) => {
    return tableData?.map((row) => {
        console.log("🚀 ~ returntableData?.map ~ tableData:", tableData)
        const filteredRow = {};
        const fields = {
            'Project Name': row?.project?.name,
            'Date': dateformation(row?.date),
            'Propsal Number': row?.proposal_number,
            'Type of work': row?.type_of_work,
            'Total': row?.total,
            'Advance Deposit': row?.advance_deposit,
            'Balance Amount': row?.balance_amount,
            'Status': row?.status === 1 ? "YES" : "NO",
        };
        Object.keys(fields).forEach((key) => {
            if (key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};