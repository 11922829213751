import { Box, InputAdornment, Typography } from '@mui/material';
import { RHFTextField } from 'src/components/hook-form';

export default function ActivityCalculationRow({ icon, AccessTimeIcon, showMarkupCost }) {
  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ pb: 2, pt: 2 }} className="heading1">
          Calculation
        </Typography>
        <Box
          mt={2}
          mb={2}
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
        >
          <RHFTextField
            disabled 
            type="number"
            name="labor_hours"
            label="Labor Hrs"
            logo={<AccessTimeIcon />}
          />
          <RHFTextField
            disabled 
            type="number"
            name="direct_unit_price"
            label="Direct Unit Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box component="span" sx={{ color: 'text.disabled' }}>
                    {icon}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            disabled 
            type="number"
            name="direct_cost"
            label="Direct Cost"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box component="span" sx={{ color: 'text.disabled' }}>
                    {icon}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          {showMarkupCost && (
            <RHFTextField
              disabled 
              type="number"
              name="mark_up_cost"
              label="Mark Up Cost"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {icon}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <RHFTextField
            disabled 
            type="number"
            name="bid_amount"
            label="Marked Up / Bid Amount"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box component="span" sx={{ color: 'text.disabled' }}>
                    {icon}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            disabled 
            type="number"
            name="bid_unit_price"
            label="Marked Up / Bid Unit Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box component="span" sx={{ color: 'text.disabled' }}>
                    {icon}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </>
  );
}
