import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useMemo, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import  FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import WeeklyPayrolltable from './weekly-payroll-timesheet-table'
import { enqueueSnackbar } from 'notistack';
import { handleErrorMessages } from '../../risk-register/feching';

const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};


const WeeklyPayroll = () => {
  const [tableData, setTableData] = useState([]);
  const [projectList, setProjectList] = useState([]);
  //const [selectedProject, setSelectedProject] = useState('All');
  const [createdBy, setCreatedBy] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();


  const getWeeklytimeSheetData = async (week_starting_date) => {
    try {
      const data = await getRequest(
        `${endpoints.accounting.payroll.weeklyPayroll.get_time_sheet}/${week_starting_date}`
      );
      if (data) {
        setTableData(data);
      }
    } catch (error) {
      console.error('Error fetching weekly timesheet data:', error);
    }
  };


  const getCreatedBy = async () => {
    try {
      const data = await getRequest(endpoints.accounting.documents.get_all_users);
      const supervisor_idUsers = data?.filter(user => user.role === 'Supervision') || [];
      console.log("🚀 ~ getCreatedBy ~ supervisor_idUsers:", supervisor_idUsers);
      const transformedOptions = supervisor_idUsers.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
      }));
      console.log("🚀 ~ supervisor_id Users:", transformedOptions);
      setCreatedBy(transformedOptions);
    } catch (error) {
      console.error("Error fetching supervisor_ids:", error);
    }
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/accounts-management/payroll-project-list/payroll/${id}`);
  };




  const TABLE_HEAD = [
    { id: 'Name', label: 'Name' },
    { id: 'Backcharge', label: 'Backcharge' },
    { id: 'Union No.', label: 'Union No.' },
    { id: 'SS#', label: 'SS#' },
    { id: 'Trade', label: 'Trade' },
    { id: 'Mon (11-Nov)', label: 'Mon (11-Nov)' },
    { id: 'Tue (12-Nov)', label: 'Tue (12-Nov)' },
    { id: 'Wed (13-Nov)', label: 'Wed (13-Nov)' },
    { id: 'Thu (14-Nov)', label: 'Thu (14-Nov)' },
    { id: 'Fri (15-Nov)', label: 'Fri (15-Nov)' },
    { id: 'Sat (16-Nov)', label: 'Sat (16-Nov)' },
    { id: 'Sun (17-Nov)', label: 'Sun (17-Nov)' },
  ];

  const dummyData = [
    {
      Name: 'John Doe',
      Backcharge: '100',
      'Union No.': '12345',
      'SS#': '987-65-4321',
      Trade: 'Electrician',
      'Mon (11-Nov)': 8,
      'Tue (12-Nov)': 8,
      'Wed (13-Nov)': 8,
      'Thu (14-Nov)': 8,
      'Fri (15-Nov)': 8,
      'Sat (16-Nov)': 4,
      'Sun (17-Nov)': 0,
    },
  ];

  const NewUserSchema = Yup.object().shape({
    //week_starting_date: Yup.date().required('Week Start Date is required'),
    //week_ending_date: Yup.date().required('Week End Date is required'),
  });

  const defaultValues = useMemo(() => {
    const today = new Date();
    return {
      week_starting_date: today,
      week_ending_date: addDays(today, 6),
      facility: null,
      supervisor_id: null,
      scope_of_work: '',
      'jobordernumber/projectnumber': '',
    };
  }, []);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // Watch the week_starting_date field
  useEffect(() => {
    getCreatedBy();
    getWeeklytimeSheetData();
  }, []);

  useEffect(() => {
    const getProjects = async () => {
      const data = await getRequest(endpoints.accounting.expenses.get_project_list);
      if (data) {
        setProjectList(data);
        const matchedFacility = data?.find((project) => String(project.id) === String(id));
        if (matchedFacility) {
          setValue('facility', matchedFacility);
        }
      }
    };
    getProjects();
  }, [id, setValue]);

  const week_starting_date = watch('week_starting_date');

  useEffect(() => {
    if (week_starting_date) {
      const newEndDate = addDays(week_starting_date, 6);
      setValue('week_ending_date', newEndDate);
      const formattedDate = week_starting_date.toISOString().split('T')[0];
      getWeeklytimeSheetData(formattedDate);
    }
  }, [week_starting_date, setValue]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = handleSubmit(async (data) => {
    console.log("🚀 ~ WeeklyPayroll ~ Submitted Data:", data);

    // Create a FormData object
    const formData = new FormData();
    formData.append('week_starting_date', data.week_starting_date.toISOString().split('T')[0]);
    formData.append('week_ending_date', data.week_ending_date.toISOString().split('T')[0]);
    formData.append('project_id', data.facility?.id || ''); 
    formData.append('supervisor_id', data.supervisor_id?.id || ''); // Add supervisor_id ID
    formData.append('scope_of_work', data.scope_of_work || '');
    //formData.append('jobOrderNumber', data['jobordernumber/projectnumber'] || '');

    setLoading(true)
    try {
      const response = await postRequest(
        endpoints.accounting.payroll.weeklyPayroll.post_payrolls, // Adjust the endpoint
        formData,
        true // Indicating it's a multipart/form-data request
      );
      setLoading(false);

      if (response.metadata?.success) {
        reset(); // Reset the form
        enqueueSnackbar({
          variant: 'success',
          message: response.metadata.message,
        });
      } else {
        handleErrorMessages(response.metadata?.message || 'Something went wrong.');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting weekly payroll:', error);
      enqueueSnackbar({
        variant: 'error',
        message: 'Failed to submit weekly payroll. Please try again later.',
      });
    }
  });

  return (
    <div style={{ marginLeft: '15px' }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <h2>WEEKLY PAYROLL / TIMESHEET</h2>
          {/*<Button variant="contained" className="dark-button" onClick={handleBack}>
            Back to List
          </Button>*/}
        </div>
        <Box
          mt={2}
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(3, 3fr)',
          }}
          sx={{ marginTop: '10px', padding: '20px' }}
        >
          <Controller
            name="week_starting_date"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Week Start Date"
                {...field}
                inputFormat="yyyy-MM-dd"
                renderInput={(params) => (
                  <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
                )}
              />
            )}
          />

          <RHFAutocomplete
            name="facility"
            label="Facility"
            options={projectList}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            disabled // Disable the dropdown
            control={control}
          />

          <RHFTextField name="scope_of_work" label="Scope of Work" />

          <RHFAutocomplete
            label="supervisor_id"
            name="supervisor_id"
            options={createdBy}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
          />

          <Controller
            name="week_ending_date"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Week End Date"
                {...field}
                inputFormat="yyyy-MM-dd"
                //readOnly
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />

          <RHFTextField
            name="jobordernumber/projectnumber"
            label="Job Order Number / Project Number"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            marginTop: '50px',
          }}
        >
          <Button
            sx={{ minWidth: 65, height: 35 }}
            className="dark-button"
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
          <Button
            sx={{ minWidth: 65, height: 35 }}
            className="dark-button"
            variant="contained"
            onClick={() => reset(defaultValues)}
          >
            Cancel
          </Button>
        </Box>
      </FormProvider>

      <div style={{ marginTop: '40px' }}>
        <WeeklyPayrolltable data={dummyData} />
       
      </div>
    </div>
  );
};
export default WeeklyPayroll;