import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { dateformation } from 'src/components/date-format-mdy';
import { IconButton } from '@mui/material';

// ----------------------------------------------------------------------


export default function UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, serialNumber }) {
    console.log("🚀 invoices>>>> ~ row:", row)
    const { customer_id, account_chart, invoice_number, invoice_date, due_date, outstanding_balance, total, note, status, project } = row;

    const confirm = useBoolean();
    const popover = usePopover();

    return (
        <>
            <TableRow hover selected={selected} >
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow} />
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell>{customer_id}</TableCell>
                <TableCell>{project?.name}</TableCell>
                <TableCell>{account_chart?.code}</TableCell>
                <TableCell>{account_chart?.name}</TableCell>
                <TableCell>{account_chart?.account_type?.name}</TableCell>
                <TableCell>{invoice_number}</TableCell>
                <TableCell>{dateformation(invoice_date) || ' '}</TableCell>
                <TableCell>{dateformation(due_date)}</TableCell>
                {/*<TableCell>{ }</TableCell>*/}
                <TableCell>{total}</TableCell>
                <TableCell>{outstanding_balance}</TableCell>
                <TableCell>{note}</TableCell>
                <TableCell>
                    {{
                        0: "Pending",
                        1: " Reviewed",
                        2: " Approved",
                        3: " Submitted",
                        4: "User Accepted",
                        5: "User Rejected",
                        6: "Follow Up",
                        7: "Partially Received",
                        8: "Received Payment",
                    }[status] || ""}
                </TableCell>
                <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>
            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
            >
                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onEditRow(row);
                        console.log(">>>>>>>", row)
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>
            </CustomPopover>
            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content="Are you sure you want to delete?"
                action={
                    <Button
                        variant="contained" color="error"
                        onClick={() => {
                            confirm.onFalse();
                            //onDeleteRow(row.id);
                            //console.log(">>>>>>>", row.id)
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
}
UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};