import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from 'src/components/iconify';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
const ProjectList = ({ open, onClose, url }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [projectList, setProjectList] = useState([]);

    useEffect(() => {
        getProjects();
    }, []);
    const getProjects = async () => {
        const data = await getRequest(endpoints.accounting.projects.get_list);
        console.log("🚀 ~ getProjects ~ data:", data);
        if (data) {
            setProjectList(data); 
        }
    };
    const handleProjectClick = (projectId) => {
        console.log("Selected Project :", projectId, url);
        onClose(); 
        //navigate(url);
    };
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="sm"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
                <span
                    className='cardColor'
                    style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                    &nbsp;&nbsp; Select Project
                </span>
                <IconButton onClick={onClose}>
                    <Iconify icon="mdi:close" />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    borderRadius: '1px',
                }}
            >
                <List
                    sx={{
                        maxHeight: '4=00px',
                        overflowY: 'auto',
                        borderRadius: '5px',
                    }}
                >
                    {projectList?.map((project) => (
                        <ListItem
                            key={project.id}
                            disablePadding
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover, // Light background on hover
                                },
                                marginBottom: '10px',
                                backgroundColor: '#f9f9f9', // Default background
                                border: '1px solid #ddd', // Light border for better visibility
                                borderRadius: '4px',
                            }}
                        >
                            <ListItemButton
                                onClick={() => handleProjectClick(project.id)}
                                sx={{
                                    borderRadius: '4px',
                                }}
                            >
                                <ListItemText
                                    primary={project.name}
                                    primaryTypographyProps={{
                                        //fontWeight: 'bold',
                                        fontSize: '16px',
                                        color: '#333',
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="inherit" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ProjectList;