export const histogramConfigureGantt = (gantt, generateColumns) => {
    ///user columns
    const secondGridColumns = {
      columns: [
        {
          name: 'name',
          label: 'Name',
          width: 60,
          align: 'center',
          tree: true,
          template: function (task) {
            return task.text;
          },
        },
        {
          name: 'budgetedHrs',
          width: 80,
          label: 'Budgeted Hrs',
          align: 'center',
          template: function (task) {
            return task.budgetedHrs + ' ' + 'Hours';
          },
        },
        {
          name: 'assignedHrs',
          width: 80,
          label: 'Assigned Hrs',
          align: 'center',
          template: function (task) {
            return task.assignedHrs + ' ' + 'Hours';
          },
        },
      ],
    };
  
    

      gantt.templates.histogram_cell_label = function (
        start_date,
        end_date,
        resource,
        tasks,
        assignments
      ) {
        return resource.budgetedHrs + '/' + resource.assignedHrs;
      };
  
      gantt.templates.histogram_cell_class = function (
        start_date,
        end_date,
        resource,
        tasks,
        assignments
      ) {
        return '';
      };
      gantt.templates.histogram_cell_allocated = function (
        start_date,
        end_date,
        resource,
        tasks,
        assignments
      ) {
        return resource.budgetedHrs;
      };
      gantt.templates.histogram_cell_capacity = function (
        start_date,
        end_date,
        resource,
        tasks,
        assignments
      ) {
        return resource.assignedHrs;
      };
    
  
    gantt.templates.grid_row_class = (start, end, task) => {
      if (task.parent != 0) {
        return 'sub_task';
      } else {
        return 'parent_task';
      }
    };
  
    ///graph view=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  

  
    gantt.config.row_height = 43;
  
    //main grid system=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    gantt.config.layout = {
      css: 'gantt_container',
      rows: [
        {
          css: 'first_grind',
          cols: [
            { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
            { resizer: true, width: 1 },
            { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
            { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
          ],
          gravity: 3,
        },
        {
          cols: [
            {
              html: "<div class='resourse-grid-title'>Assigned Resources</div>",
              css: 'resourse-grid-title-container',
              width: 435,
              group: 'grids',
            },
            { resizer: true, width: 1 },
            {
              html: "<div class='resourse-grid-title'>Note: Squares represent required hours per day, and circles represent assigned hours per day.</div>",
              css: 'resourse-grid-title-container',
            },
          ],
          gravity: 0.3,
        },
        {
          cols: [
            {
              html: "<div class=''></div>",
              css: 'resourse-grid-title-container',
            },
          ],
          gravity: 0.4,
        },
        {
          cols: [
            {
              view: 'resourceGrid',
              group: 'grids',
              width: 435,
              scrollY: 'resourceVScroll',
              config: secondGridColumns,
            },
            { resizer: true, width: 1 },
      
            ///graph view=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  
            {
              view: 'resourceHistogram',
              capacity: 24,
              scrollX: 'scrollHor',
              scrollY: 'resourceVScroll',
            },
  
  
            ///enter hours view
            { view: 'scrollbar', id: 'resourceVScroll', group: 'vertical' },
          ],
          gravity: 2,
        },
        { view: 'scrollbar', id: 'scrollHor' },
      ],
    };
  };
  