import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
} from "@mui/material";
import Scrollbar from "src/components/scrollbar";

const BalanceSheetTable = ({ data }) => {
    console.log("🚀 ~ BalanceSheetTable ~ data:", data);

    const headers = ["Account", ...data?.map((item) => item?.period)];

    const renderSection = (entityType) => {
        const totals = {};
        return (
            <>
                <TableRow>
                    <TableCell
                        colSpan={headers?.length}
                        sx={{
                            fontWeight: "bolder",
                    

                        }}
                    >
                        {entityType?.charAt(0).toUpperCase() + entityType.slice(1)}
                    </TableCell>
                </TableRow>

                {/* Entity Rows */}
                {data?.map((periodData, index) => {
                    const entityDetails = periodData.details[entityType];
                    entityDetails.forEach((entity) => {
                        if (!totals[entity.account_name]) {
                            totals[entity.account_name] = Array(data.length).fill(0);
                        }
                        totals[entity.account_name][index] = entity.total_amount;
                    });
                })}

                {Object.entries(totals).map(([accountName, amounts]) => (
                    <TableRow key={accountName}>
                        <TableCell
                            sx={{
                                minWidth: "150px", // Adjust the minimum width
                                //fontWeight: "bold",
                                wordBreak: "break-word",
                                alignItems: "center",
                            }}
                        >
                            {accountName}
                        </TableCell>
                        {amounts.map((amount, idx) => (
                            <TableCell
                                key={idx}
                                sx={{
                                    minWidth: "150px", // Adjust column width for numerical data
                                    textAlign: "center",
                                    wordBreak: "break-word",
                                }}
                            >
                                {amount ? `$${amount.toLocaleString()}` : "-"}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}

                {/* Total Row */}
                <TableRow>
                    <TableCell
                        sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        Total {entityType.charAt(0).toUpperCase() + entityType.slice(1)}
                    </TableCell>
                    {data.map((periodData, index) => (
                        <TableCell
                            key={index}
                            sx={{
                                fontWeight: "bold",
                                textAlign: "center",
                                backgroundColor: "#f5f5f5",
                            }}
                        >
                            {`$${(periodData[`total_${entityType}`] || 0).toLocaleString()}`}
                        </TableCell>
                    ))}
                </TableRow>
            </>
        );
    };

    return (
        <Card
            sx={{
                boxShadow: "0px 0px 30px 0px #64748B1A",
            }}
        >
            <TableContainer component={Paper}>
                <Scrollbar>
                    <Table>
                        {/* Table Header */}
                        <TableHead>
                            <TableRow>
                                {headers?.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        sx={{
                                            fontWeight: "bold",
                                            minWidth: "120px", // Adjust column width for headers
                                            textAlign: "center",
                                            //backgroundColor: "#e0e0e0",
                                            wordBreak: "break-word",
                                        }}
                                    >
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Render Sections */}
                            {renderSection("assets")}
                            {renderSection("liabilities")}
                            {renderSection("equity")}
                        </TableBody>
                    </Table>
                </Scrollbar>
            </TableContainer>
        </Card>
    );
};

export default BalanceSheetTable;
