import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import SubContractorFollowUp from './subcontractor-follow-up';


// ----------------------------------------------------------------------

export default function UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, serialNumber, chartsAccountList, bankInformation, reload }) {
    const { notes, status, agreement_number, payment_terms, subcon_agreement_items, project, id, contractor, contract_date, contract_terms, remarks ,balance} = row;

    const confirm = useBoolean();
    const popover = usePopover();
    const create = useBoolean();

    return (
        <>
            <TableRow hover selected={selected} >
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow} />
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell>{project?.name}</TableCell>
                <TableCell
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={create.onTrue}
                >
                    {agreement_number}
                </TableCell>
                <TableCell>{contractor?.first_name}{contractor?.last_name}</TableCell>
                <TableCell>{contract_date}</TableCell>
                <TableCell>{contract_terms}</TableCell>
                <TableCell>{contract_date}</TableCell>
                <TableCell>{subcon_agreement_items[0]?.description}</TableCell>
                <TableCell>{subcon_agreement_items[0]?.amount}</TableCell>
                <TableCell>{balance}</TableCell>
                <TableCell>{remarks}</TableCell>
                <TableCell>
                    {{
                        0: "Pending",
                        1: "Agrement Approved",
                        2: "Agrement Issues",
                        3: "Agrement Accepted",
                        4: "Shipped",
                        5: "Received",
                        6: "Accepted",
                        7: "Goods",
                        8: "Services Accepted",
                    }[status] || ""}
                </TableCell>           
                    <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>

            <SubContractorFollowUp
                open={create.value}
                onClose={create.onFalse}
                SANumber={agreement_number}
                SAId={id}
                chartsAccountList={chartsAccountList}
                bankInformation={bankInformation}
                balance={balance}
                reload={reload}
            // reload={() => getInvoice()}
            // currentItem={selectedItem}
            // closeform={() => handleCloseForm()}
            />

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
            >
                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onEditRow(row);
                        console.log(">>>>>>>", row)
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>
            </CustomPopover>
            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content="Are you sure you want to delete?"
                action={
                    <Button
                        variant="contained" color="error"
                        onClick={() => {
                            confirm.onFalse();
                            onDeleteRow(row.id);
                            console.log(">>>>>>>", row.id)
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
}
UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};