import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';
import DashboardLayout from 'src/layouts/dashboard/layout';
import { riskManagementRoutes } from './risk-management';
import { JobCostingRotes } from '../job-costing-routes';

import { AccountRoutes } from './accounts-management';
import { ganttChartRoutes } from './gantt-chart-routes';
const ProjectCalendarPage = lazy(() => import('src/pages/dashboard/project-calendar'));
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const GantChart = lazy(() => import('src/pages/ganttchart/gantt'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/company-calendar',
      // index: true,
      element: (
        // <AuthGuard>
        <MainLayout>
          <HomePage />
          {/* <CalendarPage /> */}
        </MainLayout>
        // </AuthGuard>
      ),
    },
    // {
    //   path: '/risk-management',
    //   element: (
    //    <MainDashboardLayout>
    //      <RiskManagement/>
    //    </MainDashboardLayout>

    //   )
    // },

    {
      path: '/project-calendar',
      element: (
        <DashboardLayout>
          <ProjectCalendarPage />
        </DashboardLayout>
      )
    },
    // {
    //   path: '/job-costing',
    //   element: (
    //     <DashboardLayout>
    //      </DashboardLayout>
    //   )
    // },

    {
      path: '/chat-view',
      // index: true,
      element: (
        <DashboardLayout>
          <ChatPage isCompany={false} />
        </DashboardLayout>
      )

    },
    {
      path: '/company-chat-view',
      // index: true,
      element: (
        <DashboardLayout>
          <ChatPage isCompany={true} />
        </DashboardLayout>
      )

    },
    {
      path: '/ezelog-mail',
      element: (
        <DashboardLayout>
          <MailPage isCompany={false} />
        </DashboardLayout>
      ),

    },
    {
      path: '/company-mail',
      element: (
        <DashboardLayout>
          <MailPage isCompany={true} />
        </DashboardLayout>
      ),

    },
    {
      path: '/project-ganttchart',
      element: (
        <DashboardLayout>
          <GantChart isCompany={false} />
        </DashboardLayout>
      ),

    },

    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

  // Account routes
     ...AccountRoutes,
    // gantt chart routes
    ...ganttChartRoutes,
    // risk management routes
    ...riskManagementRoutes,
    //Job costing routes
    ...JobCostingRotes,
    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
