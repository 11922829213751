import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider from 'src/components/hook-form';
import { MultiFilePreview, Upload } from 'src/components/upload';
import { postRequest } from 'src/services';

const ImportDilaog = ({
    open,
    onClose,
    currentItem,
    reload,
    url,
}) => {
    const theme = useTheme();
    const [files, setFiles] = useState([]);
    const [filePreview, setFilePreview] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fileError, setFileError] = useState(false); // New state for file error
    const { enqueueSnackbar } = useSnackbar();
    const NewUserSchema = Yup.object().shape({
        images: Yup.mixed().nullable().required('File is required'),
    });
    const defaultValues = useMemo(
        () => ({
            images: currentItem?.images || '',
        }),
        [currentItem]
    );

    useEffect(() => {
        if (currentItem) {
            if (currentItem.file != null) {
                setFilePreview([{ ...currentItem.file, preview: currentItem.file.url }]);
            } else {
                setFilePreview([]);
            }
        }
    }, [defaultValues]);

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });
    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const handleClose = () => {
        reset();
        setFiles([]);
        setFilePreview([]);
        setFileError(false);
        onClose();
    };
    const onSubmit = handleSubmit(async (data) => {
        if (files.length === 0) {
            setFileError(true);
            return;
        }
        const formData = new FormData();
        if (files.length > 0) {
            formData.append('file', files[0]);
        }
        setLoading(true);
        try {
            const data = await postRequest(url,
                formData,
                true
            );
            setLoading(false);
            if (data.metadata?.success) {
                reset();
                setFiles([]);
                setFilePreview([]);
                setFileError(false);
                onClose();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: data.metadata.message,
                });
            } else {
                if (data.errors && Array.isArray(data.errors)) {
                    data.errors.forEach((error) => {
                        enqueueSnackbar({
                            variant: 'error',
                            message: error[0],
                        });
                    });
                } else {

                }
            }
        } catch (err) {
            setLoading(false);
            console.log('Error in catch:', err);
            enqueueSnackbar({
                variant: 'error',
                message: 'An unexpected error occurred',
            });
        }
    });
    const handleDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setFiles([...newFiles]);
            setFileError(false); // Remove error when file is selected
        },
        [files]
    );
    const handleRemoveFile = (inputFile) => {
        setFiles([]);
        setFilePreview([]);
        setFileError(true);
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose();
                    }
                }}
                fullWidth
                maxWidth="md"
                sx={{
                    borderRadius: 'none',
                    padding: '60px',
                    fontSize: '13px',
                    fontFamily: 'arial',
                }}
                transitionDuration={{
                    enter: theme.transitions.duration.shortest,
                    exit: theme.transitions.duration.shortest - 80,
                }}
            >
                <FormProvider methods={methods} onSubmit={onSubmit}>
                    <DialogTitle
                        sx={{
                            justifyContent: 'space-evenly',
                            boxSizing: 'content-box',
                        }}
                    >
                        <span
                            className='cardColor'
                            style={{
                                padding: '7px',
                                marginLeft: '-25px',
                                color: 'white ',
                                fontWeight: '700',
                            }}
                        >
                            &nbsp;&nbsp; Import File &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        </span>
                        <br />
                        <br />
                        <Divider />
                    </DialogTitle>
                    <>
                        <DialogContent
                            sx={{
                                borderRadius: '1px',
                            }}
                        >
                            <Box
                                rowGap={3}
                                display="grid"
                                gridTemplateColumns={{
                                    xs: 'repeat(1, 1fr)',
                                }}
                            >
                                <Typography variant="subtitle2">Related File</Typography>
                                <Upload onDrop={handleDrop} onRemove={handleRemoveFile} name="images" isExcelFile={true} />
                                {fileError && ( // Display error message if file is not selected
                                    <Typography variant="caption" color="error">
                                        * File is required
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ my: 3 }}>
                                <MultiFilePreview
                                    files={files?.length > 0 ? files : filePreview}
                                    onRemove={handleRemoveFile}
                                />
                            </Box>
                        </DialogContent>
                    </>
                    <DialogActions>
                        <Button variant="outlined" color="inherit" onClick={handleClose}>
                            Cancel
                        </Button>
                        <LoadingButton type="submit" variant="contained" className='dark-button' loading={isSubmitting}>
                            {!currentItem ? 'Create Import' : 'Update Import'}
                        </LoadingButton>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </>
    );
};
export default ImportDilaog;