// exportCsvData.js

export const getCsvData = (tableData = {}) => {
    return tableData?.map((row) => {
        const filteredRow = {};
        const fields = {
            risk_id: row?.risk_id,
            risk_title: row?.title,
        };
  
        Object.keys(fields).forEach((key) => {
            if (key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};
