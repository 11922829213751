// src/components/content/RiskRegister.js
import { useEffect, useState } from 'react';
import Header from '../header';
import {
  Select,
  MenuItem,
  Box,
  Button,
  Card,
  CardContent, IconButton,
  Table,
  TableBody, TableContainer, Tooltip
} from '@mui/material';
import Iconify from 'src/components/iconify';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import { LoadingButton } from '@mui/lab';
import UserTableRow from './expense-table-row';
import ExpensesCreate from './expenses-create-form';


const Expenses = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [employeeOptions, setemployeeOptions] = useState([]);
  const [departmentsOptions, setdepartmentsOptions] = useState([]);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [createdby, setcreatedby] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [projectList, setprojectList] = useState([]);
  const [costcenterList, setcostcenterList] = useState([]);
  const [chartsAccountList, setchartsAccount] = useState([]);
  const [selectedProject, setSelectedProject] = useState('All');

  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.no' },
    { id: 'Date', label: 'Date' },
    { id: 'Client', label: 'Client', width: '300px' },
    { id: 'Project Name', label: 'Project Name' },
    { id: 'Account Type', label: 'Account Type' },
    { id: 'Description', label: 'Description' },
    { id: 'Charts of account', label: 'Charts of account' },
    { id: 'Amount', label: 'Amount' },
    { id: 'Notes/Remarks', label: 'Notes/Remarks' },
    { id: 'Status', label: 'Status' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData.length > 0 ? tableData : [],
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  console.log("🚀 ~ Customers ~ tableData:", tableData)
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  console.log("🚀 ~ Customers ~ dataInPage:", dataInPage)
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleEditRow = (item) => {
    setSelectedItem(item); // Set the selected item for editing
    create.onTrue();
    //setIsModalOpen(true); // Open the modal
  };

  useEffect(() => {
    getExpensesData();
    getEmployeeName()
    getDepartments()
    getCatgeory()
    getCreatedBy()
    getVendors()
    getProjets()
    getCostCenter()
    getChartsOfAccount()
    getCustomersList()
  }, []);



  const getCustomersList = async () => {
    const data = await getRequest(endpoints.accounting.customers.get_list);
    const transformedOptions = data?.map(user => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
    })) || [];
    console.log("🚀 ~ transformedOptions ~ transformedOptions:", transformedOptions)
    setLoading(false);
    if (data) {
      setcustomerList(transformedOptions);
    }
  };
  const getExpensesData = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.expenses.get_list);
    console.log("🚀 ~ getExpensesData ~ data:", data)
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };
  const getEmployeeName = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_employee_list);
    console.log("🚀 ~ getEmployeeName ~ data:", data)
    if (data) {
      setemployeeOptions(data);
    }
  };
  const getDepartments = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_departments_list);
    console.log("🚀 ~ getDepartments ~ data:", data)
    if (data) {
      setdepartmentsOptions(data);
    }
  };
  const getCostCenter = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_cost_center_list);
    console.log("🚀 ~ getCostCenter ~ data:", data)
    if (data) {
      setcostcenterList(data);
    }
  };
  const getChartsOfAccount = async () => {
    const data = await getRequest(endpoints.accounting.expenses.charts_of_account_list);
    console.log("🚀 ~ getCostCenter ~ data:", data)
    if (data) {
      setchartsAccount(data);
    }
  };
  const getCatgeory = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_category_list);
    console.log("🚀 ~ getCatgeory ~ data:", data)
    if (data) {
      setcategoryOptions(data);
    }
  };
  const getProjets = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_project_list);
    console.log("🚀 ~ getProjets ~ data:", data)
    if (data) {
      setprojectList(data);
    }
  };

  const getCreatedBy = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.documents.get_all_users);
    const transformedOptions = data?.map(user => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
    })) || [];
    console.log("🚀 ~ transformedOptions ~ transformedOptions:", transformedOptions)
    setLoading(false);
    if (data) {
      setcreatedby(transformedOptions);
    }
  };

  const getVendors = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.vendors.get_list);
    console.log('vendors data is:', data);
    setLoading(false);
    const transformedOptions = data?.map(user => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
    })) || [];
    console.log("🚀 ~ transformedOptions ~ transformedOptions:", transformedOptions)
    if (data) {
      setVendorsList(transformedOptions);
    }
  };


  const handleChange = (event) => {
    setSelectedProject(event.target.value);
    console.log('Selected Project ID:', event.target.value);
  };


  const deleteCallBack = () => {
    confirm.onFalse();
    getExpensesData();
  };

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const handleDeleteRow = async (id) => {
    setDeleteLoading(true)
    await deleteRequest(`${endpoints.accounting.expenses.delete}/${id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
    setDeleteLoading(false)
  }


  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log('🚀 ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_change_request.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getExpensesData();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  return (
    <>
      <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
        {' '}
        <CardContent>
          {loading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
              <Header name="Expenses Management" />
              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '20px',
                  justifyContent: 'space-between',
                  gap: '12px',
                  marginTop: '15px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '5px 0',
                  }}
                >
                  <h5
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#333',
                      margin: '0',
                      textTransform: 'none',
                    }}
                  >
                    Project Name
                  </h5>
                    <Select
                      sx={{ minWidth: 65, height: 35 }}
                      value={selectedProject}
                      onChange={handleChange}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {projectList?.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                          {project.name}
                        </MenuItem>
                      ))}
                    </Select>

                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: '20px',
                    justifyContent: 'flex-end',
                    gap: '12px',
                  }}
                >
                  <Button
                    onClick={() => {
                      setSelectedItem({});
                      create.onTrue();
                    }}
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                  >
                    Create
                  </Button>
                </Box>
              </Box>
              <Card
                sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                }}
              >
                <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                  {actionVisible && (
                    <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                      <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            setActionVisible(checked),
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                        action={
                          <Tooltip title="Delete">
                            <IconButton color="primary" onClick={remove.onTrue}>
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    </div>
                  )}
                  <Scrollbar>
                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                      <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                        action={
                          <Tooltip title="Delete">
                            <IconButton color="primary" onClick={confirm.onTrue}>
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Tooltip>
                        }
                      />

                      <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                          <TableHeadCustom
                            order={table.order}
                            orderBy={table.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={tableData.length}
                            numSelected={table.selected.length}
                            onSort={table.onSort}
                            onSelectAllRows={(checked) =>
                              table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                              )
                            }
                          />
                          <TableBody>
                            {dataFiltered
                              .slice(
                                table.page * table.rowsPerPage,
                                table.page * table.rowsPerPage + table.rowsPerPage
                              )
                              .map((row, index) => (
                                <UserTableRow
                                  key={row.id}
                                  row={row}
                                  selected={table.selected.includes(row.id)}
                                  onSelectRow={() => table.onSelectRow(row.id)}
                                  onDeleteRow={() => handleDeleteRow(row.id)}
                                  onEditRow={() => handleEditRow(row)}
                                  serialNumber={table.page * table.rowsPerPage + index + 1} // Calculate the serial number
                                />
                              ))}
                            <TableEmptyRows
                              height={denseHeight}
                              emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                            />

                            <TableNoData notFound={notFound} />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
        </CardContent>
      </Card>
      <ExpensesCreate
        open={create.value}
        onClose={create.onFalse}
        reload={() => getExpensesData()}
        currentItem={selectedItem}
        closeform={() => handleCloseForm()}
        employeeOptions={employeeOptions}
        departmentsOptions={departmentsOptions}
        categoryOptions={categoryOptions}
        createdby={createdby}
        vendorsList={vendorsList}
        projectList={projectList}
        costcenterList={costcenterList}
        chartsAccountList={chartsAccountList}
        customerList={customerList}
      />
      <ConfirmDialog
        open={remove.value}
        onClose={remove.onFalse}
        title="Delete"
        content={<>Are you sure want to delete these items?</>}
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleBulkDelete();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};
export default Expenses;