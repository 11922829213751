import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';

// ----------------------------------------------------------------------

const RHFPhoneInput = forwardRef((props, ref) => {
    const { control } = useFormContext();

    const inputRef = React.useRef(null);

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        },
    }));

    return (
        <Controller
            name={props.name}
            control={control}
            sx={{ pl: 2 }}
            render={({ field, fieldState: { error } }) => (
                props.name === 'phoneNumber' ? (
                    <MuiPhoneNumber
                        {...field}
                        defaultCountry="us"
                        countryCodeEditable={false}
                        onChange={(value) => field.onChange(value)}
                        fullWidth
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error?.message : props.helperText}
                        {...props}
                        ref={inputRef}
                    />
                ) : (
                    <TextField
                        {...field}
                        fullWidth
                        type={props.type}
                        value={props.type === 'number' && field.value === 0 ? '' : field.value}
                        onChange={(event) => {
                            if (props.type === 'number') {
                                field.onChange(Number(event.target.value));
                            } else {
                                field.onChange(event.target.value);
                            }
                        }}
                        error={!!error}
                        helperText={error ? error?.message : props.helperText}
                        {...props}
                        ref={inputRef}
                    />
                )
            )}
        />
    );
});

RHFPhoneInput.propTypes = {
    helperText: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.string,
};

export default RHFPhoneInput;