import React, { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    DialogActions,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import { endpoints } from 'src/utils/axios';
import * as Yup from 'yup';
import { useSearchParams } from 'src/routes/hooks';
import { getRequest, postRequest } from 'src/services';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';

const EmployeeAddDialog = ({ open, onClose, reload, label }) => {
    const [loading, setLoading] = useState(false);
    const [rolenames, setRolenames] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const searchParams = useSearchParams();

    const NewUserSchema = Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        company_name: Yup.string().required('Company name is required'),
        role: Yup.object()
            .shape({
                id: Yup.string().required('Role is required'),
                name: Yup.string().required('Role is required'),
            })
            .required('Role is required'),
    });

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues: {
            first_name: '',
            last_name: '',
            company_name: companyName,
            role: null,
        },
    });

    const { reset, handleSubmit, setValue } = methods;

    useEffect(() => {
        fetchRoles();
        getProfile();
    }, []);

    useEffect(() => {
        setValue('company_name', companyName);
    }, [companyName, setValue, open]);

    const fetchRoles = async () => {
        try {
            const data = await getRequest(endpoints.risk_management.risk_register.identification.app_role);
            const names = data.map((role) => ({ id: role.id, name: role.name }));
            setRolenames(names);
        } catch (error) {
            console.error('Error fetching roles: ', error);
        }
    };
    const handleClose = () => {
        onClose();
        reset();
    };

    const getProfile = async () => {
        try {
            const data = await getRequest(endpoints.profile_info);
            setCompanyName(data.company_name || '');
        } catch (error) {
            console.error('Error fetching profile: ', error);
        }
    };

    const onSubmit = handleSubmit(async (formData) => {
        setLoading(true); // Start loading state
        const users = [
            {
                first_name: formData.first_name,
                last_name: formData.last_name,
                company_name: formData.company_name,
                role: formData.role ? formData.role.name : '',
            },
        ];
        try {
            const responseData = await postRequest(endpoints.users, { users }, false);
            if (responseData.metadata && responseData.metadata.success) {
                onClose();
                reload();
                reset();
                enqueueSnackbar({
                    variant: 'success',
                    message: "Risk owner created successfully",
                });
            } else {
                //handleErrorMessages(responseData.metadata.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false)
        }
    });

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle>Add New {label}</DialogTitle>
                <Divider />
                <DialogContent>
                    <Box mt={2} rowGap={3} columnGap={2} display="grid" gridTemplateColumns="1fr">
                        <RHFTextField name="first_name" label="First Name" />
                        <RHFTextField name="last_name" label="Last Name" />
                        <RHFTextField name="company_name" label="Company Name" />
                        <RHFAutocomplete
                            name="role"
                            label="Role"
                            options={rolenames}
                            getOptionLabel={(option) => option?.name || ''}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        className='dark-button'
                        disabled={loading}
                        loading={loading}
                        onClick={onSubmit}
                        variant="contained"
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};

export default EmployeeAddDialog;
