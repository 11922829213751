import { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
import { MultiFilePreview, Upload } from 'src/components/upload';

const AssetsCreate = ({ open, onClose, reload, currentItem, closeform, createdby }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [isFileDeleted, setIsFileDeleted] = useState(false);
  const [filePreview, setFilePreview] = useState([]);
  const NewUserSchema = Yup.object().shape({

    //number: Yup.string().required('Number is required'),
    //floor: Yup.string().required('Floor is required'),
    //name_of_room: Yup.string().required('Name of Room is required'),
    //qr_and_barcode: Yup.string().required('QR & Barcode Scanning is required'),
    //iot_details: Yup.string().required('IOT Details are required'),
    //description_of_work: Yup.string().required('Description of Work is required'),
    //frequency_of_maintenance: Yup.string().required('Frequency of Maintenance is required'),
    //supplier: Yup.string().required('Supplier & Manufacturer is required'),
    //parts_of_replace: Yup.string().required('Parts to replace if any is required'),
    //Is_the_part_inventory_available: Yup.string().required('Part Inventory Available is required'),
    //what_consumeables_are_needed: Yup.string().required('What consumables are needed is required'),
    //what_tools_are_needed: Yup.string().required('What tools are needed is required'),
    //location_of_parts: Yup.string().required('Location of parts is required'),
    //performed_by: Yup.string().required('Performed by is required'),
    //estimated_time_to_perform: Yup.string().required('Estimated time to perform is required'),
    //do_you_need_to_schedule_third_party: Yup.string().required('Need to schedule third party is required'),
    //remarks: Yup.string().required('Remarks are required'),
    //asset_file: Yup.mixed().required('Attachment Files are required'),
  });


  const defaultValues = useMemo(
    () => ({
      number: currentItem?.number || '',
      floor: currentItem?.floor || '',
      name_of_room: currentItem?.name_of_room || '',
      qr_and_barcode: currentItem?.qr_and_barcode || '',
      iot_details: currentItem?.iot_details || '',
      description_of_work: currentItem?.description_of_work || '',
      frequency_of_maintenance: currentItem?.frequency_of_maintenance || '',
      supplier_id: currentItem?.supplier || '',
      parts_of_replace: currentItem?.parts_of_replace || '',
      Is_the_part_inventory_available: currentItem?.Is_the_part_inventory_available || '',
      what_consumeables_are_needed: currentItem?.what_consumeables_are_needed || '',
      what_tools_are_needed: currentItem?.what_tools_are_needed || '',
      location_of_parts: currentItem?.location_of_parts || '',
      performed_by: currentItem?.performed_by || '',
      estimated_time_to_perform: currentItem?.estimated_time_to_perform || '',
      do_you_need_to_schedule_third_party: currentItem?.do_you_need_to_schedule_third_party || '',
      remarks: currentItem?.remarks || '',
      asset_file: currentItem?.asset_file
    }),
    [currentItem]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [currentItem, reset, defaultValues]);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('number', data.number);
    formData.append('floor', data.floor);
    formData.append('name_of_room', data.name_of_room);
    formData.append('qr_and_barcode', data.qr_and_barcode);
    formData.append('iot_details', data.iot_details);
    formData.append('description_of_work', data.description_of_work);
    formData.append('frequency_of_maintenance', data.frequency_of_maintenance);
    formData.append('supplier_id', data.supplier_id.id);
    formData.append('parts_of_replace', data.parts_of_replace);
    formData.append('Is_the_part_inventory_available', data.Is_the_part_inventory_available);
    formData.append('what_consumeables_are_needed', data.what_consumeables_are_needed);
    formData.append('what_tools_are_needed', data.what_tools_are_needed);
    formData.append('location_of_parts', data.location_of_parts);
    formData.append('performed_by', data.performed_by.id);
    formData.append('estimated_time_to_perform', data.estimated_time_to_perform);
    formData.append('do_you_need_to_schedule_third_party', data.do_you_need_to_schedule_third_party);
    formData.append('remarks', data.remarks);
    if (isFileDeleted) {
      formData.append('is_file_deleted', true);
    }
    if (files.length > 0) {
      formData.append('asset_file', files[0]);
    } 
    try {
      const response = await postRequest(
        currentItem?.id
          ? `${endpoints.accounting.inventory.assests.update_list}/${currentItem.id}?_method=PUT`
          : endpoints.accounting.inventory.assests.post_list,
        formData,
        true
      );
      if (response.metadata && response.metadata.success) {
        reset();
        onClose();
        closeform();
        reload();
        enqueueSnackbar({
          variant: 'success',
          message: response.metadata.message,
        });
      } else {
        handleErrorMessages(response?.message || 'An error occurred.');
      }
    } catch (err) {
      console.error('Error:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    } finally {
      setLoading(false);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }));
      setFiles([...newFiles]);
    },
    [files]
  );


  const handleRemoveFile = (inputFile) => {
    setIsFileDeleted(true);
    setFiles([]);
    setFilePreview([]);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      maxWidth="md"
      sx={{
        borderRadius: 'none',
        padding: '60px',
        fontSize: '13px',
        fontFamily: 'arial',
      }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxSizing: 'content-box',
          }}
        >
          <span
            className="cardColor"
            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}
          >
            &nbsp;&nbsp; CREATE ASSEST &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={onClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            borderRadius: '1px',
          }}
        >
          <Box
            mt={2}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="number" label="Number" type="number" />
            <RHFTextField name="floor" label="Floor" />
            <RHFTextField name="name_of_room" label="Name of Room" />
            <RHFTextField name="qr_and_barcode" label="QR & Barcode Scanning" />
            <RHFTextField name="iot_details" label="IOT Details" />
            <RHFTextField name="description_of_work" label="Description of work" />
            <RHFAutocomplete
              name="frequency_of_maintenance"
              label="Frequency of Maintenance"
              options={['daily', 'weekly', 'biweekly', 'quarterly', 'biannually', 'winter']}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
              error={!!methods.formState.errors.approval_status}
              helperText={methods.formState.errors.approval_status?.message}
            />
            <RHFAutocomplete
              name="supplier_id"
              label="Supplier & Manufacturer"
              options={createdby}
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
            />

            <RHFTextField name="parts_of_replace" label="Parts to replace if any" />
            <RHFTextField
              name="Is the part inventory available"
              label="Is the part inventory available"
            />
            <RHFTextField
              name="what_consumeables_are_needed"
              label="What consumeables are needed"
            />
            <RHFTextField name="what_tools_are_needed" label="What tools are needed" />
            <RHFTextField name="location_of_parts" label="Location of parts" />
            <RHFAutocomplete
              name="performed_by"
              label="Performed by"
              options={createdby}
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
            />
            <RHFTextField name="estimated_time_to_perform" label="Estimated time to perform" />
            <RHFTextField
              type="number"
              name="do_you_need_to_schedule_third_party"
              label="Do you need to schedule third party / Technician"
            />
            <RHFTextField name="remarks" label="Remarks" />

          </Box>
          <Box
            mt={4}
            rowGap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
            }}
          >
            <Typography variant="subtitle2">Assests File</Typography>
            <Upload onDrop={handleDrop} name="assest_file" onRemove={handleRemoveFile} />
          </Box>
          <Box sx={{ my: 3 }}>
            <MultiFilePreview
              files={files?.length > 0 ? files : filePreview}
              onRemove={handleRemoveFile}
              setFileDeleted={setIsFileDeleted}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            className="dark-button"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {currentItem ? "Update" :"Submit" }   
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default AssetsCreate;
