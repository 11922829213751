// exportCsvData.js

export const getCsvData = (tableData = {}) => {
    return tableData?.map((row) => {
        const filteredRow = {};
        const fields = {
            project_name: row?.project?.name,
            agreement_number: row?.agreement_number,
            subcontractor_name: `${row?.contractor?.first_name} ${row?.contractor?.last_name}`,
            invoice_date: row?.contract_date,
            payment_terms: row?.contract_terms,
            contract_date: row?.contract_date,
            item_description: row?.subcon_agreement_items?.[0]?.description,
            item_amount: row?.subcon_agreement_items?.[0]?.amount,
            balance: row.balance,
            notes: row?.remarks,
            status: row?.status === 1 ? "Yes" : "No",
        };

        Object.keys(fields).forEach((key) => {
            if (key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};
