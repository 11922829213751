export const configureLightbox = (gantt, setSelectedTask, setCategoryModal, router, setTaskModal) => {
    gantt.showLightbox = (id) => {
      const task = gantt.getTask(id);
      setSelectedTask(task);
      if (task.$level === 1) {
        setCategoryModal(true);
      } else if (task.$level === 2) {
        router.push('/gantt-chart/create-edit-activity');
      } else {
        setTaskModal(true);
      }
    };
  
    gantt.hideLightbox = () => {
      setTaskModal(false);
      setCategoryModal(false);
    };
  };
  