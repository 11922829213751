import { RHFTextField } from "src/components/hook-form";
export default function ActivityFirstRow({calculationofDirectUnitPrice,calculationofMarkupBidUnitPrice}) {
  return (
    <>
            <RHFTextField name="activity" required label="Cost Code Activity" />
            <RHFTextField name="description" required label="Cost Code Activity Description" />
            <RHFTextField 
             onChange={(e)=>{
              calculationofDirectUnitPrice(e.target.value,'quantity')
              calculationofMarkupBidUnitPrice(e.target.value,'quantity')
              }}
            type="number" name="quantity" required label="Quantity" />
            <RHFTextField type="number" name="unit" required label="Unit" />
    </>
  );
}
