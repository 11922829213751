import MainDashboardLayout from 'src/layouts/dashboard/main-layout';
import AccountsDashboard from 'src/pages/dashboard/accounts-management';
import RiskView from 'src/components/accounts-management/risk-register/risk-view';
// import IncidentView from 'src/components/accounts-management/SideCards.js/vendors/incident-view';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
// import RiskChangeView from 'src/components/accounts-management/SideCards.js/risk-change-request/risk-change-view';
import AccountsSideBarLayout from 'src/layouts/dashboard/accounts-SideBar-layout';

import SubcontractorAmount from 'src/components/accounts-management/SideCards.js/accounts-payable/subcontractor-amount';

import WorkOrder from 'src/components/accounts-management/SideCards.js/accounts-payable/work-orders';

import AccountsPayableView from 'src/components/accounts-management/SideCards.js/accounts-payable/accounts-payable-view';
import Expenses from 'src/components/accounts-management/SideCards.js/expenses';
import ExpensesView from 'src/components/accounts-management/SideCards.js/expenses/risk-change-view';
import Payroll from 'src/components/accounts-management/SideCards.js/payroll/payroll';
import ProjectsListPayroll from 'src/components/accounts-management/SideCards.js/payroll/projectlist-index';
import PayrollView from 'src/components/accounts-management/SideCards.js/payroll/payroll-info-view';
import CertifiedPayroll from 'src/components/accounts-management/SideCards.js/payroll/certified-payroll';
import CertifiedPayrollView from 'src/components/accounts-management/SideCards.js/payroll/certified-payroll/certifiedpayroll-change-view';
import RunPayroll from 'src/components/accounts-management/SideCards.js/payroll/run-payroll';
import RunPayrollView from 'src/components/accounts-management/SideCards.js/payroll/run-payroll/runpayroll-change-view';
import PayrollTaxes from 'src/components/accounts-management/SideCards.js/payroll/payroll-taxes';
import WeeklyPayroll from 'src/components/accounts-management/SideCards.js/payroll/create-weeklypayroll';

import CreateRunPayroll from 'src/components/accounts-management/SideCards.js/payroll/run-payroll/create-runpayroll';

import CreatePayrollTax from 'src/components/accounts-management/SideCards.js/payroll/payroll-taxes/create-payrolltax';

import PayrollTaxesView from 'src/components/accounts-management/SideCards.js/payroll/payroll-taxes/payrolltaxes-Info-view';

import Taxes from 'src/components/accounts-management/SideCards.js/taxes';
import BusinessTaxes from 'src/components/accounts-management/SideCards.js/taxes/businesstaxes';
import MiscTaxes from 'src/components/accounts-management/SideCards.js/taxes/misctaxes';
import TaxesPayroll from 'src/components/accounts-management/SideCards.js/taxes/payrolltaxes';

import CreateSalesTax from 'src/components/accounts-management/SideCards.js/taxes/create-salestax';
import CreatePayrollTaxes from 'src/components/accounts-management/SideCards.js/taxes/createpayrolltaxes';
import CreateBusinessTax from 'src/components/accounts-management/SideCards.js/taxes/create-businesstaxes';
import CreateMiscTax from 'src/components/accounts-management/SideCards.js/taxes/create-misctaxes';

import TaxesView from 'src/components/accounts-management/SideCards.js/taxes/taxes-info-view';
import ProjectsList from 'src/components/accounts-management/SideCards.js/inventory';
import Inventory from 'src/components/accounts-management/SideCards.js/inventory/materials';

import Equipment from 'src/components/accounts-management/SideCards.js/inventory/equipments';
import Tools from 'src/components/accounts-management/SideCards.js/inventory/tools';
import Assets from 'src/components/accounts-management/SideCards.js/inventory/assets';

import InventoryView from 'src/components/accounts-management/SideCards.js/inventory/inventory-info-view';
import GeneralLedger from 'src/components/accounts-management/SideCards.js/general-ledger/general-ledger';
import GeneralLedgerView from 'src/components/accounts-management/SideCards.js/general-ledger/risk-change-view';
import GenerateBalanceSheet from 'src/components/accounts-management/SideCards.js/balance-sheet/generate-balancesheet';

import GenerateEquipment from 'src/components/accounts-management/SideCards.js/inventory/generate-equipment';
import GenerateMaterial from 'src/components/accounts-management/SideCards.js/inventory/generate-materials';
import GenerateProfitAndLossStatement from 'src/components/accounts-management/SideCards.js/balance-sheet/gererate-profitloss';

import BalanceSheet from 'src/components/accounts-management/SideCards.js/balance-sheet';
import BalanceSheetView from 'src/components/accounts-management/SideCards.js/balance-sheet/balancesheet-view';

import IncomeStatement from 'src/components/accounts-management/SideCards.js/balance-sheet/income-statement';
import ProfitAndLossStatement from 'src/components/accounts-management/SideCards.js/balance-sheet/profit-loss-statement';

import BankInformation from 'src/components/accounts-management/SideCards.js/bank-information';
import BankInformationView from 'src/components/accounts-management/SideCards.js/bank-information/bank-Info-view';
import FinanceStatement from 'src/components/accounts-management/SideCards.js/finance-statement';
import FinanceStatementView from 'src/components/accounts-management/SideCards.js/finance-statement/finance-statement-view';
import Employees from 'src/components/accounts-management/SideCards.js/employees';
import EmployeesView from 'src/components/accounts-management/SideCards.js/employees/employees-info-view';
import Reports from 'src/components/accounts-management/SideCards.js/reports';
import Overview from 'src/components/accounts-management/SideCards.js/reports/overview';
import Transactions from 'src/components/accounts-management/SideCards.js/reports/transactions';
import Budgeting from 'src/components/accounts-management/SideCards.js/reports/budgeting';
import FinancialReporting from 'src/components/accounts-management/SideCards.js/reports/financial-reporting';
import Report from 'src/components/accounts-management/SideCards.js/reports/report';  
import ReportsView from 'src/components/accounts-management/SideCards.js/reports/reports-view';
import Documents from 'src/components/accounts-management/SideCards.js/documents/documents';
import DocumentsView from 'src/components/accounts-management/SideCards.js/documents/risk-change-view';
import Calendar from 'src/components/accounts-management/SideCards.js/calendar/risk-change-request';
import CalendarView from 'src/components/accounts-management/SideCards.js/calendar/risk-change-view';
import AuditLogs from 'src/components/accounts-management/SideCards.js/audit-logs/risk-change-request';
import AuditLogsView from 'src/components/accounts-management/SideCards.js/audit-logs/risk-change-view';
import Settings from 'src/components/accounts-management/SideCards.js/settings/risk-change-request';
import SettingsView from 'src/components/accounts-management/SideCards.js/settings/risk-change-view';
import Customers from 'src/components/accounts-management/risk-register/customers';
import Vendors from 'src/components/accounts-management/SideCards.js/vendors';
import CreateVendor from 'src/components/accounts-management/SideCards.js/vendors/create-vendor';

import Invoice from 'src/components/accounts-management/SideCards.js/account-receiveable/invoice';
import CashFlow from 'src/components/accounts-management/SideCards.js/general-ledger/cash-flow';
import BankRecognization from 'src/components/accounts-management/SideCards.js/general-ledger/bank-recognization';
import GeneralLedgerReport from 'src/components/accounts-management/SideCards.js/general-ledger/general-ledger-generate-report';
import CashFlowReport from 'src/components/accounts-management/SideCards.js/general-ledger/cash-flow-generate-report';
import PaymentRequisitions from 'src/components/accounts-management/SideCards.js/account-receiveable/payment-requisitions/payment-requisitions';
import CreateBankInformation from 'src/components/accounts-management/SideCards.js/bank-information/create-bankinformation';

import CreateEmployee from 'src/components/accounts-management/SideCards.js/employees/create-employee';
import GenerateIncomeStatement from 'src/components/accounts-management/SideCards.js/balance-sheet/generate-incomestatement';
import InvoicePayable from 'src/components/accounts-management/SideCards.js/accounts-payable/invoice-page';
import Proposal from 'src/components/accounts-management/SideCards.js/account-receiveable/proposal/proposal';
import CostCodes from 'src/components/accounts-management/SideCards.js/account-receiveable/cost-codes/cost-codes';
import ChangeOrder from 'src/components/accounts-management/SideCards.js/account-receiveable/change-order/change-order';
import CostCodesActivities from 'src/components/accounts-management/SideCards.js/account-receiveable/cost-codes-activities/cost-codes-activities';
import ChangeOrderActivities from 'src/components/accounts-management/SideCards.js/account-receiveable/change-order-activities/change-order-activities';
import AccountsPayableViewTable from 'src/components/accounts-management/SideCards.js/accounts-payable/accounts-payable-table';
import PurchaseOrder from 'src/components/accounts-management/SideCards.js/accounts-payable/purchase-order';

const contentComponents = [
  {
    name: 'Customers',
    route: '/customers',
    component: Customers,
    subRoutes: [{ name: 'View', route: '/view/:id', component: RiskView }],
  },
  {
    name: 'Vendors',
    route: '/vendors',
    component: Vendors,
    // subRoutes: [{ name: 'IncidentView', route: '/incidentView/:id', component: IncidentView }],
  },

  {
    name: 'Create Vendors',
    route: '/create-vendor',
    component: CreateVendor,
    // subRoutes: [{ name: 'IncidentView', route: '/incidentView/:id', component: IncidentView }],
  },
  {
    name: 'Account Receivable Invoice',
    route: '/account-receiveable',
    component: Invoice,
    // subRoutes: [{ name: 'RiskChangeView', route: '/view/:id', component: RiskChangeView }],
  },
  {
    name: 'Cost Codes',
    route: '/account-receiveable/cost-codes',
    component: CostCodes,
    subRoutes: [{ name: 'Cost Code Activites', route: '/activities/:id', component: CostCodesActivities }],
  },
  {
    name: 'Change Order',
    route: '/account-receiveable/change-order',
    component: ChangeOrder,
    subRoutes: [{ name: 'Change Order Activities', route: '/activities/:id', component: ChangeOrderActivities }],
  },
  {
    name: 'Propsal',
    route: '/account-receiveable/proposal',
    component: Proposal,
    // subRoutes: [{ name: 'RiskChangeView', route: '/view/:id', component: RiskChangeView }],
  },
  {
    name: 'Payment Requisitions',
    route: '/account-receiveable/payment-requisitions',
    component: PaymentRequisitions,
    // subRoutes: [{ name: 'RiskChangeView', route: '/view/:id', component: RiskChangeView }],
  },

  {
    name: 'purchase order',
    route: '/accounts-payable',
    component: PurchaseOrder,
    subRoutes: [{ name: 'Stake-holder', route: '/view/:id', component: AccountsPayableView }],
  },

  {
    name: 'Subcontractor Amount',
    route: '/accounts-payable/subcontractor-amount',
    component: SubcontractorAmount,
    subRoutes: [{ name: 'Stake-holder', route: '/view/:id', component: AccountsPayableView }],
  },

  {
    name: 'Work Orders',
    route: '/accounts-payable/work-orders',
    component: WorkOrder,
    subRoutes: [{ name: 'Stake-holder', route: '/view/:id', component: AccountsPayableView }],
  },
  {
    name: 'Invoices',
    route: '/accounts-payable/invoice-page',
    component: InvoicePayable,
    subRoutes: [{ name: 'Stake-holder', route: '/view/:id', component: AccountsPayableView }],
  },

  {
    name: 'Expenses',
    route: '/expenses',
    component: Expenses,
    subRoutes: [{ name: 'Expenses-View', route: '/view/:id', component: ExpensesView }],
  },

  {
    name: 'PayrollProjectList',
    route: '/payroll-project-list',
    component: ProjectsListPayroll,
    subRoutes: [{ name: 'PayrollView', route: '/view/:id', component: PayrollView }],
  },
  {
    name: 'Payroll',
    route: '/payroll-project-list/payroll/:id',
    component: Payroll,
    //component: ProjectsListPayroll,
    subRoutes: [{ name: 'PayrollView', route: '/view/:id', component: PayrollView }],
  },

  {
    name: 'Create Weekly Payroll',
    route: '/create-weeklypayroll/:id',
    component: WeeklyPayroll,
    subRoutes: [{ name: 'PayrollView', route: '/view/:id', component: PayrollView }],
  },
  {
    name: 'CertifiedPayroll',
    route: '/payroll/certified-payroll',
    component: CertifiedPayroll,
    subRoutes: [
      { name: 'CertifiedPayrollView', route: '/view/:id', component: CertifiedPayrollView },
    ],
  },
  {
    name: 'RunPayroll',
    route: '/payroll/run-payroll',
    component: RunPayroll,
    subRoutes: [{ name: 'RunPayrollView', route: '/view/:id', component: RunPayrollView }],
  },

  {
    name: 'Create Run Payroll',
    route: '/create-runpayroll',
    component: CreateRunPayroll,
    subRoutes: [{ name: 'RunPayrollView', route: '/view/:id', component: RunPayrollView }],
  },

  {
    name: 'PayrollTaxes',
    route: '/payroll/payroll-taxes',
    component: PayrollTaxes,
    subRoutes: [{ name: 'PayrollTaxesView', route: '/view/:id', component: PayrollTaxesView }],
  },

  {
    name: 'Create Payroll Taxes',
    route: '/create-payrolltax',
    component: CreatePayrollTax,
    subRoutes: [{ name: 'PayrollView', route: '/view/:id', component: PayrollView }],
  },

  {
    name: 'Taxes',
    route: '/taxes',
    component: Taxes,
    subRoutes: [{ name: 'TaxesView', route: '/view/:id', component: TaxesView }],
  },

  {
    name: 'Create Sales Taxes',
    route: '/create-salestax',
    component: CreateSalesTax,
    subRoutes: [{ name: 'PayrollView', route: '/view/:id', component: PayrollView }],
  },

  {
    name: 'Create Payroll Taxes',
    route: '/createpayrolltaxes',
    component: CreatePayrollTaxes,
    subRoutes: [{ name: 'PayrollView', route: '/view/:id', component: PayrollView }],
  },

  {
    name: 'Business Taxes',
    route: '/create-businesstaxes',
    component: CreateBusinessTax,
    subRoutes: [{ name: 'PayrollView', route: '/view/:id', component: PayrollView }],
  },

  {
    name: 'Misc Taxes',
    route: '/create-misctaxes',
    component: CreateMiscTax,
    subRoutes: [{ name: 'PayrollView', route: '/view/:id', component: PayrollView }],
  },

  {
    name: 'Payroll Taxes',
    route: '/taxes/payrolltaxes',
    component: TaxesPayroll,
    subRoutes: [{ name: 'TaxesView', route: '/view/:id', component: TaxesView }],
  },

  {
    name: 'Business Taxes',
    route: '/taxes/businesstaxes',
    component: BusinessTaxes,
    subRoutes: [{ name: 'TaxesView', route: '/view/:id', component: TaxesView }],
  },

  {
    name: 'Misc. Taxes',
    route: '/taxes/misctaxes',
    component: MiscTaxes,
    subRoutes: [{ name: 'TaxesView', route: '/view/:id', component: TaxesView }],
  },

  {
    name: 'Material Projects List',
    route: '/inventory/materials',
    component: ProjectsList,
    subRoutes: [{ name: 'InventoryView', route: '/view/:id', component: InventoryView }],
  },

  {
    name: 'Material Category',
    route: '/inventory/materials/create-material-category/:id',
    component: Inventory,
    subRoutes: [{ name: 'InventoryView', route: '/view/:id', component: InventoryView }],
  },

  {
    name: 'Equipments Project List',
    route: '/inventory/equipments',
    component: ProjectsList,
    subRoutes: [{ name: 'InventoryView', route: '/view/:id', component: InventoryView }],
  },

  {
    name: 'Equipments Category',
    route: '/inventory/equipments/create-equipment-category/:id',
    component: Equipment,
    subRoutes: [{ name: 'InventoryView', route: '/view/:id', component: InventoryView }],
  },

  {
    name: 'Tools',
    route: '/inventory/tools',
    component: Tools,
    subRoutes: [{ name: 'InventoryView', route: '/view/:id', component: InventoryView }],
  },

  {
    name: 'Assets',
    route: '/inventory/assets',
    component: Assets,
    subRoutes: [{ name: 'InventoryView', route: '/view/:id', component: InventoryView }],
  },

  {
    name: 'General Ledger',
    route: '/general-ledger',
    component: GeneralLedger,
    subRoutes: [{ name: 'GeneralLedgerView', route: '/view/:id', component: GeneralLedgerView }],
  },
  {
    name: 'General Ledger Report',
    route: '/general-ledger-generate-report',
    component: GeneralLedgerReport,
    subRoutes: [{ name: 'GeneralLedgerView', route: '/view/:id', component: GeneralLedgerView }],
  },
  {
    name: 'Cash Flow',
    route: '/general-ledger/cash-flow',
    component: CashFlow,
    subRoutes: [{ name: 'GeneralLedgerView', route: '/view/:id', component: GeneralLedgerView }],
  },
  {
    name: 'Cash Flow',
    route: '/cash-flow-generate-report',
    component: CashFlowReport,
    subRoutes: [{ name: 'GeneralLedgerView', route: '/view/:id', component: GeneralLedgerView }],
  },
  {
    name: 'Bank Recognization',
    route: '/general-ledger/bank-recognization',
    component: BankRecognization,
    subRoutes: [{ name: 'GeneralLedgerView', route: '/view/:id', component: GeneralLedgerView }],
  },

  {
    name: 'Balance Sheet',
    route: '/balance-sheet',
    component: BalanceSheet,
    subRoutes: [{ name: 'BalanceSheetView', route: '/view/:id', component: BalanceSheetView }],
  },

  {
    name: 'Income Statement',
    route: '/balance-sheet/income-statement',
    component: IncomeStatement,
    subRoutes: [{ name: 'BalanceSheetView', route: '/view/:id', component: BalanceSheetView }],
  },

  {
    name: 'Profit and Loss Statement',
    route: '/balance-sheet/profit-loss-statement',
    component: ProfitAndLossStatement,
    subRoutes: [{ name: 'BalanceSheetView', route: '/view/:id', component: BalanceSheetView }],
  },

  {
    name: 'Generate Balance Sheet',
    route: '/generate-balancesheet',
    component: GenerateBalanceSheet,
    subRoutes: [{ name: 'BalanceSheetView', route: '/view/:id', component: BalanceSheetView }],
  },

  {
    name: 'Generate Equipment',
    route: '/generate-equipment/:projectId/:id',
    component: GenerateEquipment,
    subRoutes: [{ name: 'BalanceSheetView', route: '/view/:id', component: BalanceSheetView }],
  },
  {
    name: 'Generate Material',
    route: '/generate-materials/:projectId/:id',
    component: GenerateMaterial,
    subRoutes: [{ name: 'BalanceSheetView', route: '/view/:id', component: BalanceSheetView }],
  },


  {
    name: 'Generate Income Statement',
    route: '/generate-incomestatement',
    component: GenerateIncomeStatement,
    subRoutes: [{ name: 'BalanceSheetView', route: '/view/:id', component: BalanceSheetView }],
  },

  {
    name: 'Generate Profit And Loss Statement',
    route: '/generate-profitloss',
    component: GenerateProfitAndLossStatement,
    subRoutes: [{ name: 'BalanceSheetView', route: '/view/:id', component: BalanceSheetView }],
  },

  {
    name: 'Bank Information',
    route: '/bank-information',
    component: BankInformation,
    subRoutes: [
      { name: 'BankInformationView', route: '/view/:id', component: BankInformationView },
    ],
  },
  {
    name: 'Create Bank Information',
    route: '/create-bankinformation',
    component: CreateBankInformation,
    subRoutes: [
      { name: 'BankInformationView', route: '/view/:id', component: BankInformationView },
    ],
  },

  {
    name: 'Finance Statement',
    route: '/finance-statement',
    component: FinanceStatement,
    subRoutes: [
      { name: 'FinanceStatementView', route: '/view/:id', component: FinanceStatementView },
    ],
  },

  {
    name: 'Create Employees',
    route: '/create-employee',
    component: CreateEmployee,
    subRoutes: [{ name: 'EmployeesView', route: '/view/:id', component: EmployeesView }],
  },

  {
    name: 'Employees',
    route: '/employees',
    component: Employees,
    subRoutes: [{ name: 'EmployeesView', route: '/view/:id', component: EmployeesView }],
  },

  {
    name: 'Reports',
    route: '/reports',
    component: Reports,
    subRoutes: [{ name: 'ReportsView', route: '/view/:id', component: ReportsView }],
  },

  {
    name: 'Overview',
    route: '/reports/overview',
    component: Overview,
    subRoutes: [{ name: 'ReportsView', route: '/view/:id', component: ReportsView }],
  },

  {
    name: 'Report',
    route: '/reports/report',
    component: Report,
    subRoutes: [{ name: 'ReportsView', route: '/view/:id', component: ReportsView }],
  },

  {
    name: 'Transactions',
    route: '/reports/transactions',
    component: Transactions,
    subRoutes: [{ name: 'ReportsView', route: '/view/:id', component: ReportsView }],
  },

  {
    name: 'Financial Reporting',
    route: '/reports/financial-reporting',
    component: FinancialReporting,
    subRoutes: [{ name: 'ReportsView', route: '/view/:id', component: ReportsView }],
  },

  {
    name: 'Budgeting',
    route: '/reports/budgeting',
    component: Budgeting,
    subRoutes: [{ name: 'ReportsView', route: '/view/:id', component: ReportsView }],
  },

  {
    name: 'Documents',
    route: '/documents',
    component: Documents,
    subRoutes: [{ name: 'DocumentsView', route: '/view/:id', component: DocumentsView }],
  },

  {
    name: 'Calendar',
    route: '/calendar',
    component: Calendar,
    subRoutes: [{ name: 'CalendarView', route: '/view/:id', component: CalendarView }],
  },

  {
    name: 'Audit Logs',
    route: '/audit-logs',
    component: AuditLogs,
    subRoutes: [{ name: 'AuditLogsView', route: '/view/:id', component: AuditLogsView }],
  },

  {
    name: 'Settings',
    route: '/settings',
    component: Settings,
    subRoutes: [{ name: 'SettingsView', route: '/view/:id', component: SettingsView }],
  },
];

export const AccountRoutes = [
  {
    path: '/accounts-management',
    element: (
      <MainDashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </MainDashboardLayout>
    ),
    children: [
      { element: <AccountsDashboard />, index: true },
      ...contentComponents.flatMap(({ route, component: Component, subRoutes }) => {
        const mainRoute = {
          path: route.slice(1),
          element: (
            <AccountsSideBarLayout>
              <Component />
            </AccountsSideBarLayout>
          ),
        };

        const subRouteElements = subRoutes
          ? subRoutes.map(({ route: subRoute, component: SubComponent }) => ({
            path: `${route.slice(1)}${subRoute}`,
            element: (
              <AccountsSideBarLayout>
                <SubComponent />
              </AccountsSideBarLayout>
            ),
          }))
          : [];
        return [mainRoute, ...subRouteElements];
      }),
    ],
  },
];