// src/components/content/RiskRegister.js
import { useCallback, useEffect, useState } from 'react';
import Header from '../header';
import { MenuItem, Box, Button, Card, CardContent, IconButton, Table, TableBody, TableContainer, Tooltip, TextField, InputAdornment, Select } from '@mui/material';
import Iconify from 'src/components/iconify';
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { paths } from 'src/routes/paths';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import { LoadingButton } from '@mui/lab';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import UserTableRow from './cash-flow-table-row';

const CashFlow = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({})
  const [actionVisible, setActionVisible] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [projectList, setprojectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('All');

  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };
  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.no' },
    { id: 'Date', label: 'Date' },
    { id: 'Transaction Type', label: 'Transaction Type' },
    { id: 'Source', label: 'Source' },
    { id: 'Description', label: 'Description' },
    { id: 'Debit($)', label: 'Debit($)' },
    { id: 'Credit', label: 'Credit' },
    { id: 'Balance($)33', label: 'Balance($)' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();
  const popover = usePopover();

  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback(
    (id) => {
      setTableData((prevTableData) => {
        const deleteRow = prevTableData.filter((row) => row.id !== id);
        table.onUpdatePageDeleteRow(dataInPage.length);
        return deleteRow;
      });
    },
    [dataInPage.length, table]
  );



  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );


  useEffect(() => {
    getCashFlowData(selectedProject);
    getProjects();
  }, []);

 
  const getCashFlowData = async (projectId) => {
    try {
      setLoading(true);
      const endpoint = projectId && projectId !== 'All'
        ? `${endpoints.accounting.general_ledger.get_cash_flow}?project_id=${projectId}`
        : endpoints.accounting.general_ledger.get_cash_flow;
      const data = await getRequest(endpoint);
      if (data) {
        const {
          account_payable_invoices = [],
          expenses = [],
          account_receivable_invoices = [],
        } = data;

        // Combine all data into one array with a 'type' field
        const combinedData = [
          ...account_payable_invoices?.map((invoice) => ({
            ...invoice,
            type: 'Account Payable',
          })),
          ...account_receivable_invoices?.map((invoice) => ({
            ...invoice,
            type: 'Account Receivable',
          })),
          ...expenses?.map((expense) => ({
            ...expense,
            type: 'Expense',
          })),
        ];

        setTableData(combinedData);
      }
    } catch (error) {
      console.error('Error fetching ledger data:', error);
      // Optionally, set an error state here to display to the user
    } finally {
      setLoading(false);
    }
  };
  const getProjects = async () => {
    const data = await getRequest(endpoints.accounting.expenses.get_project_list);
    console.log('🚀 ~ getProjets ~ data:', data);
    if (data) {
      setprojectList(data);

    }
  };

  const handleChange = (event) => {
    const projectId = event.target.value
    setSelectedProject(projectId);
    getCashFlowData(projectId)
    console.log('Selected Project ID:', event.target.value);
  };

  const deleteCallBack = () => {
    confirm.onFalse();
    getCashFlowData()
  }

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const handleDeleteRows = async (id) => {
    await deleteRequest(`${endpoints.risk_management.risk_change_request.crud}/${selectedItem.id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  }


  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log("🚀 ~ handleBulkDelete ~ Ids:", Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(endpoints.risk_management.risk_change_request.bulk_delete, { ids: Ids });
        console.log("🚀 ~ handleBulkDelete ~ response:", response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getCashFlowData();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };


  return <>
    <Card
      sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
    >      <CardContent>
        {loading ? (
          <Box height={700}>
            <LoadingScreen />
          </Box>
        ) : (
          <>
            <Header name="Cash Flow Management" />
              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '20px',
                  justifyContent: 'space-between',
                  gap: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '5px 0',
                  }}
                >
                  <h5
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#333',
                      margin: '0',
                      textTransform: 'none',
                    }}
                  >
                    Project Name
                  </h5>
                  <Select
                    sx={{ minWidth: 65, height: 35 }}
                    value={selectedProject}
                    onChange={handleChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {projectList?.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

            
              </Box>

            <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "flex-end",
                gap: "12px"
              }}
            >

              <TextField
                placeholder="Search..."
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="material-symbols:search" />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: '250px',
                }}
              />

              <Button
                onClick={popover.onOpen}
                className="dark-button"
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<Iconify icon="mdi:filter" />}
              >
                Filter
              </Button>

              <Button
                onClick={() => {

                  navigate("/accounts-management/cash-flow-generate-report");

                }}
                className="dark-button"
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Generate Report
              </Button>
            </Box>
            <CustomPopover
              open={popover.open}
              onClose={popover.onClose}
              arrow="bottom-bottom"
              sx={{ width: 1000, padding: 2, background: 'white !important' }}
            >
              <MenuItem
                disableRipple
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 2,
                  color: '#000',
                  background: 'white !important',
                  '&:hover': { backgroundColor: 'white !important' },
                }}
              >
                <Iconify
                  icon="mdi:close"
                  onClick={popover.onClose}
                  sx={{
                    width: 20,
                    height: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    backgroundColor: '#F4F6F8',
                    color: '#637381',
                  }}
                />

                <TextField
                  label="Customer ID"
                  size="small"
                  placeholder="Search.."
                  variant="outlined"

                  sx={{
                    flex: 1,
                    background: 'white !important',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#e0e0e0 !important',
                      },
                    },
                  }}
                />

                <TextField
                  label="First Name"
                  size="small"
                  placeholder="Search.."
                  variant="outlined"

                  sx={{
                    flex: 1,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#e0e0e0 !important',
                      },
                    },
                  }}
                />
                <TextField
                  label="Last Name"
                  size="small"
                  placeholder="Search.."
                  variant="outlined"

                  sx={{
                    flex: 1,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#e0e0e0 !important',
                      },
                    },
                  }}
                />

                <TextField
                  label="Email"
                  size="small"
                  placeholder="Search.."
                  variant="outlined"

                  sx={{
                    flex: 1,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#e0e0e0 !important',
                      },
                    },
                  }}
                />
                <TextField
                  label="Phone No."
                  size="small"
                  placeholder="Search.."
                  variant="outlined"

                  sx={{
                    flex: 1,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#e0e0e0 !important',
                      },
                    },
                  }}
                />
              </MenuItem>
            </CustomPopover>
            <Card sx={{
              boxShadow: '0px 0px 30px 0px #64748B1A',
            }}>

              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                  dense={table.dense}
                  numSelected={table.selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  action={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={confirm.onTrue}>
                        <Iconify icon="solar:trash-bin-trash-bold" />
                      </IconButton>
                    </Tooltip>
                  }
                />

                <Scrollbar>
                  <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage
                        )
                          .map((row, index) => (
                          <UserTableRow
                            key={row.id}
                            row={row}
                            selected={table.selected.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                            //onDeleteRow={() => handleDeleteRow(row.id)}
                            //onEditRow={() => handleEditRow(row.id)}
                            serialNumber={table.page * table.rowsPerPage + index + 1}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                      />

                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={dataFiltered.length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </>
        )}
      </CardContent>
    </Card >

    <ConfirmDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title="Delete"
      content="Are you want to delete selected items?"
      action={
        <Button
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleDeleteRows();
            confirm.onFalse();
          }}
        >
          Delete
        </Button>
      }
    />

    <ConfirmDialog
      open={remove.value}
      onClose={remove.onFalse}
      title="Delete"
      content={
        <>
          Are you sure want to delete these items?
        </>
      }
      action={
        <LoadingButton
          loading={deleteLoading}
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleBulkDelete();
          }}
        >
          Delete
        </LoadingButton>
      }
    />

  </>;
};

export default CashFlow;
