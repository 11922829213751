export const defaultConfigureGantt = (gantt, generateColumns) => {
  ///user columns
  
  gantt.templates.histogram_cell_label = function (
    start_date,
    end_date,
    resource,
    tasks,
    assignments
  ) {
    return resource.budgetedHrs + '/' + resource.assignedHrs;
  };

  gantt.templates.histogram_cell_class = function (
    start_date,
    end_date,
    resource,
    tasks,
    assignments
  ) {
    return '';
  };
  gantt.templates.histogram_cell_allocated = function (
    start_date,
    end_date,
    resource,
    tasks,
    assignments
  ) {
    return resource.budgetedHrs;
  };
  gantt.templates.histogram_cell_capacity = function (
    start_date,
    end_date,
    resource,
    tasks,
    assignments
  ) {
    return resource.assignedHrs;
  };


gantt.templates.grid_row_class = (start, end, task) => {
  if (task.parent != 0) {
    return 'sub_task';
  } else {
    return 'parent_task';
  }
};

  const secondGridColumns = {
    columns: [
      {
        name: 'name',
        label: 'Name',
        width: 60,
        align: 'center',
        tree: true,
        template: function (task) {
          return task.text;
        },
      },
      {
        name: 'budgetedHrs',
        width: 80,
        label: 'Budgeted Hrs',
        align: 'center',
        template: function (task) {
          return task.budgetedHrs + ' ' + 'Hours';
        },
      },
      {
        name: 'assignedHrs',
        width: 80,
        label: 'Assigned Hrs',
        align: 'center',
        template: function (task) {
          return task.assignedHrs + ' ' + 'Hours';
        },
      },
    ],
  };

    gantt.templates.resource_cell_value = function (
      start_date,
      end_date,
      resource,
      tasks,
      assignments
    ) {
      let backgroundColor = '';

      if (assignments[0].budgetedHrs < assignments[0].assignedHrs) {
        backgroundColor = 'orange';
      } else if (assignments[0].budgetedHrs > assignments[0].assignedHrs) {
        backgroundColor = 'red';
      } else if (assignments[0].budgetedHrs == assignments[0].assignedHrs) {
        backgroundColor = 'green';
      }
      var html = `
        <div style="display: flex; gap: 10px; justify-content: center; align-items: center; height: 100%; background-color: ${backgroundColor};">
          <span style="color: black; background-color: white; height: 26px; width: 26px; line-height: 26px; text-align: center; display: inline-block;">
            ${assignments[0].budgetedHrs}
          </span>
          <span style="height: 26px; width: 26px; color: white; background-color: black; border-radius: 50%; line-height: 26px; text-align: center; display: inline-block;">
            ${assignments[0].assignedHrs}
          </span>
        </div>
      `;
      return html;
    };

  gantt.templates.grid_row_class = (start, end, task) => {
    if (task.parent != 0) {
      return 'sub_task';
    } else {
      return 'parent_task';
    }
  };

  gantt.config.row_height = 43;

  gantt.config.layout = {
    css: 'gantt_container',
    rows: [
      {
        css: 'first_grind',
        cols: [
          { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
          { resizer: true, width: 1 },
          { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
          { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
        ],
        gravity: 3,
      },
      {
        cols: [
          {
            html: "<div class='resourse-grid-title'>Assigned Resources</div>",
            css: 'resourse-grid-title-container',
            width: 435,
            group: 'grids',
          },
          { resizer: true, width: 1 },
          {
            html: "<div class='resourse-grid-title'>Note: Squares represent required hours per day, and circles represent assigned hours per day.</div>",
            css: 'resourse-grid-title-container',
          },
        ],
        gravity: 0.3,
      },
      {
        cols: [
          {
            html: "<div class=''></div>",
            css: 'resourse-grid-title-container',
          },
        ],
        gravity: 0.4,
      },
      {
        cols: [
          {
            view: 'resourceGrid',
            group: 'grids',
            width: 435,
            scrollY: 'resourceVScroll',
            config: secondGridColumns,
          },
          { resizer: true, width: 1 },
         {
            view: 'resourceTimeline',
            scrollX: 'scrollHor',
            scrollY: 'resourceVScroll',
          },
          { view: 'scrollbar', id: 'resourceVScroll', group: 'vertical' },
        ],
        gravity: 2,
      },
      { view: 'scrollbar', id: 'scrollHor' },
    ],
  };
};
