export const Descriptions = [
    {
        group: 'General Requirements',
        classify: ['Office Overhead', 'Field Overhead', 'Profit'],
    },
    {
        group: 'Insurance',
        classify: ['Worker Comp (if it is not included in Wage rates)', 'General Liability', 'Builders Riks', 'Auto', 'Professional Liability'],
    },
    {
        group: 'Bond',
        classify: ['FICA Taxes', 'Federal and state Unemployment', 'Vacation and other Fringe Benefit'],
    },
];
export const DescriptionsContingency = [
    {
        group: 'Bid and Construction Contingency', 
        classify: ['Design Free', 'Design Contingency'],
    },
];
export const TitleCategory = [
    { value: 'journey_man', label: 'Journey Man' },
    { value: 'helper', label: 'Helper' },
    { value: 'forman', label: 'Foreman' },
    { value: 'apprentice', label: 'Apprentice' },
];
