// src/components/content/RiskRegister.js
import { useCallback, useEffect, useState } from 'react';
import Header from '../header';
import { Box, Button, Card, CardContent, IconButton, MenuItem, Table, TableBody, TableContainer, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { paths } from 'src/routes/paths';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import { LoadingButton } from '@mui/lab';
import UserTableRow from './bank-recognization-table-row ';
import BankConcilationCreate from './bank-concilition-create';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

const BankRecognization = () => {
  const navigate = useNavigate();
  const popover = usePopover();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({})
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [areIconsVisible, setAreIconsVisible] = useState(false);


  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.no' },
    { id: 'Transaction ID', label: 'Transaction ID' },
    { id: 'Bank Account', label: 'Bank Account' },
    { id: 'Date', label: 'Date' },
    { id: 'Description', label: 'Description' },
    { id: 'Description', label: 'Description' },
    { id: 'Debit($)', label: 'Debit($)' },
    { id: 'Credit', label: 'Credit' },
    { id: 'Balance($)33', label: 'Balance($)33' },
    { id: 'Reconcilition', label: 'Reconcilition' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  console.log("🚀 ~ RiskChangeRequest ~ dataInPage:", dataInPage)
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback(
    (id) => {
      setTableData((prevTableData) => {
        const deleteRow = prevTableData.filter((row) => row.id !== id);
        table.onUpdatePageDeleteRow(dataInPage.length);
        return deleteRow;
      });
    },
    [dataInPage.length, table]
  );



  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );



  const getRiskChangeRequest = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.risk_management.risk_change_request.crud);
    console.log("🚀 ~ getRiskChangeRequest ~ data:", data)
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };


  const deleteCallBack = () => {
    confirm.onFalse();
    getRiskChangeRequest()
  }

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const handleDeleteRows = async (id) => {
    await deleteRequest(`${endpoints.risk_management.risk_change_request.crud}/${selectedItem.id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  }


  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log("🚀 ~ handleBulkDelete ~ Ids:", Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(endpoints.risk_management.risk_change_request.bulk_delete, { ids: Ids });
        console.log("🚀 ~ handleBulkDelete ~ response:", response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getRiskChangeRequest();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };



  return <>
    <Card
      sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
    >      <CardContent>
        {loading ? (
          <Box height={700}>
            <LoadingScreen />
          </Box>
        ) : (
          <>


            <Header name="Bank Concialiation" />


            <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "space-between",
                gap: "12px",

              }}
            >

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  padding: "5px 0",
                }}
              >
              </Box>

              <Box
                sx={{
                  display: "flex",
                  paddingBottom: "20px",
                  justifyContent: "flex-end",
                  gap: "12px"
                }}
              >
                <Button
                  onClick={() => {
                    setSelectedItem({});
                    create.onTrue();
                  }}
                  className="dark-button"
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                >
                  Bank Concialiation
                </Button>
                <IconButton onClick={popover.onOpen}>
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
              </Box>
            </Box>
            <CustomPopover
              open={popover.open}
              onClose={popover.onClose}
              arrow="right-top"
              sx={{ width: 140 }}
            >
              {/* <MenuItem
                  onClick={() => {
                    //create.onTrue();
                    popover.onClose();
                  }}
                >
                  <Iconify icon="solar:import-bold" />
                  Import
                </MenuItem> */}
              <MenuItem
                onClick={() => {
                  //handleExport();
                  popover.onClose();
                }}
              >
                <Iconify icon="solar:export-bold" />
                Export
              </MenuItem>
            </CustomPopover>

            <Card sx={{
              boxShadow: '0px 0px 30px 0px #64748B1A',
            }}>
              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                  dense={table.dense}
                  numSelected={table.selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  action={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={confirm.onTrue}>
                        <Iconify icon="solar:trash-bin-trash-bold" />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Scrollbar>
                  <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage
                        )
                        .map((row) => (
                          <UserTableRow
                            key={row.id}
                            row={row}
                            selected={table.selected.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                          //onDeleteRow={() => handleDeleteRow(row.id)}
                          //onEditRow={() => handleEditRow(row.id)}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                      />
                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={dataFiltered.length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </>
        )}
      </CardContent>
    </Card >
    <BankConcilationCreate
      open={create.value}
      onClose={create.onFalse}
      reload={() => getRiskChangeRequest()}
      currentItem={selectedItem}
      closeform={() => handleCloseForm()}
    />
    <ConfirmDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title="Delete"
      content="Are you want to delete selected items?"
      action={
        <Button
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleDeleteRows();
            confirm.onFalse();
          }}
        >
          Delete
        </Button>
      }
    />

    <ConfirmDialog
      open={remove.value}
      onClose={remove.onFalse}
      title="Delete"
      content={
        <>
          Are you sure want to delete these items?
        </>
      }
      action={
        <LoadingButton
          loading={deleteLoading}
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleBulkDelete();
          }}
        >
          Delete
        </LoadingButton>
      }
    />

  </>;
};

export default BankRecognization;
