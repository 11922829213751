// exportCsvData.js

export const getCsvData = (tableData = {}) => {
    return tableData?.map((row) => {
        const filteredRow = {};
        const fields = {
            serial_number: "",
            project_name: row.project?.name,
            work_order_number: row.work_order_number,
            work_order_date: row.work_order_date,
            subcontractor_name: row.created_by?.name,
            date: row.work_order_date,
            total_amount: row.total,
            balance: row.balance,
            remarks: row.remarks,
            status: row?.status === 1 ? "Yes" : "No",
        };

        Object.keys(fields).forEach((key) => {
            if (key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};
