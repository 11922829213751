import { useEffect, useState } from 'react';
import axios from 'src/utils/axios';
import { baseURLStaging } from 'src/config-global';
import GanttView from './Gantt';
import { LoadingScreen } from '../loading-screen';
import './Gantt.css'
import { getData } from './tasks';

const MainGantt = () => {
  const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiMmU1ZGRiNDMxOWQ4YTBjZGM3ODIyMTI5MzU5NTU5NjA1MTBlMDk4ZGYwNGVkODM4YmFjZTQwZTY4YTVkOWI3ZTU2M2EwZWJkYTgxNDQ2OTkiLCJpYXQiOjE3Mzc2MTk1MjIuOTc1Mzg1LCJuYmYiOjE3Mzc2MTk1MjIuOTc1MzksImV4cCI6MTc2OTE1NTUyMi45NjI4MDUsInN1YiI6IjI2MjAiLCJzY29wZXMiOltdfQ.aCGEjApEBBzQaEqAnpQ2m9Ny8KVia8dzI1dbrwF_jMTXeaJeoFvBzc-_cJ3iwtVKmxCvcStDdglq1HezW4zr_79b2VSQkfw8aPnufuH9gqqwr45TpZKywM1y3hpmJc2E0_x0bTzldwcsjnCgONjThUjqavR64U8VLzehforpptAsMhtZfc2oEeflle9iQ4vz2_4RkGB2GJDfe9mmpIdgI5Ccki9Ya8V9Mu8tI-bD-ROqrNXe6IaBo0rOlky6REkrYFUmNFF3_1mlIHFswOISScWZRwcdoskEiQAyrROyiqAZ44sFE-F_ZDWgZGxygYTPBvNxB6RIpcZa3kOiXyt5UuneqE6VGNGy04mY5QF2CvmMY7rrv6toJ1H7VpPlo_-U36eYMgD_zylShrKNB9oqDQ0zZ4FAF-AHpsNgbPZg1YKEJACSDVn_Xej1rlEtVAJGpplsqlR9OPrEer262K414N-TALzIMSmmazqncaBM5ptuzjOmZckd4ijcRw8oOPKKVXoPwGRO5-potm8L-CgmpsLZOjCVZDzpTsrsiv7xxc92-RyRcNERoBAEpIGAHSZ5Qw6fXJ52-__BCW3p2Tujibx8sw1RjpRwGdHYXyX3YgdeqKAlYiIoKDcPLcM-w-eBHDkDdpm3UgYQ-pFkt5v0AI2GwOmUi1L5vwx-EaLFZEM"; 
  const company = 2620;
  //const project = 3729;
  const project = 3734;

  // Initialize state as null to differentiate between loading and empty data
  const [gantdata, setganttData] = useState(null);
  const [loading, setLoading] = useState(false); // Optional: for handling loading states

  // Set localStorage items on mount
  useEffect(() => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('projectId', project);
    localStorage.setItem('companyId', company);
  }, [token, project, company]);

  // Fetch Gantt chart data on mount
  useEffect(() => {
    // getGanttChartData();
  }, []);

  // Log gantdata whenever it updates
  useEffect(() => {
    console.log("Updated gantdata:", gantdata);
  }, [gantdata]);

  const getGanttChartData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, 
          currentProjectId: project,
          currentCompanyId: company,
          "Content-Type": "application/json", // Adjust if necessary
        },
      };
      const response = await axios.get(`${baseURLStaging}/gantt-chart-cost-codes`, config);
      console.log("🚀 ~ getGanttChartData ~ response:", response.data.payload);

      if (response.data && response.data.payload) {
        const custom = response.data.payload;
        const data = {
          data: custom.gantt_resources.tasks,
          links: custom.gantt_resources.links,
          assignments: custom.gantt_resources.assignments,
          resources: custom.gantt_resources.resources,
        };
        console.log(data, "here we go");
        setganttData(data);
      }
    } catch (error) {
      console.error("Error fetching Gantt chart data:", error);
    } finally {
      setLoading(false); // Optional: update loading state
    }
  };

  // Optional: Handle loading and error states
  if (loading) {
    return <LoadingScreen/>;
  }

  // if (!gantdata) {
  //   return <div>No data available.</div>;
  // }
  let gantData2=getData()

  return (
    <GanttView gantt_resources={gantData2.tasks} relaod={() => getGanttChartData()} />
  );
};

export default MainGantt;
