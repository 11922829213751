import axios from 'axios';
import { payload } from 'src/api/calendar';
// config
import { baseURL } from 'src/config-global';
// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: baseURL });
const authToken = localStorage.getItem('authToken')
const projectId = localStorage.getItem('projectId')
const companyId = localStorage.getItem('companyId')
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
export default axiosInstance;
// ----------------------------------------------------------------------
export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, {
    headers: apiHeader,
    ...config
  });
  return res.data;
};
export const tokenGetter = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return `Bearer ${searchParams.get('token') || localStorage.getItem('authToken')}` || '';
};
const currentprojectid = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('project_id') || localStorage.getItem('projectId') || ''
};
const currentCompanyId = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('company_id') || localStorage.getItem('companyId') || '';
};
export const simpleHeader = {
  Authorization: tokenGetter(),
  currentprojectid: currentprojectid(),
  currentCompanyId: currentCompanyId(),
}

export const apiHeader = {
  Accept: 'application/json',
  Authorization: tokenGetter(),
  currentCompanyId: currentCompanyId(),
  currentProjectId: currentprojectid(),
}
export const apiPostHeader = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: tokenGetter(),
  currentCompanyId: currentCompanyId(),
  currentProjectId: currentprojectid(),
}

export const multipartPartFormDataHeader = {
  'Content-Type': 'multipart/form-data',
  Accept: 'application/json',
  Authorization: tokenGetter(),
  currentCompanyId: currentCompanyId(),
  currentProjectId: currentprojectid(),
}
const userProjectsID = currentprojectid();
// ----------------------------------------------------------------------
export const endpoints = {
  fetchMessages: '/chat/fetchMessages',
  fetchCompanyMessages: '/company-chat/fetchMessages',
  chat: '/api/chat',
  users: '/users',
  getProjectUsers: '/chat/contacts',
  getLead: '/company/leads',
  editLead: '/company/leads',
  getCompanyUsers: '/company-chat/company-contacts',
  conversationContact: '/chat/conversations',
  companyconversationContact: '/company-chat/conversations',
  chatAttachments: '/chat/chat-attachments?to_id=',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  bids_due: '/company/calendar/bids',
  leads_due: '/company/calendar/leads',
  project_due: '/company/calendar/projects',
  planned_cost_code: '/project/calendar/planned-cost-codes',
  performed_cost_code: '/project/calendar/performed-cost-codes',
  company_project: '/user/company-project',
  profile_info: '/profile',
  chat_details: '/get-current-company-project',
  risk_management: {
    risk_dashboard: {
      dashboardData: "/risk-management/risk-dashboard",
      monthYear: "/risk-management/dashboard-risk-trend",
      heatmap: "/risk-management/dashboard-heatmap",
    },
    risk_register: {
      identification: {
        identification: '/risk-management/identification-and-assessments',
        importFile: '/risk-management/identification-assessment-import',
        bulk_delete: '/risk-management/identification-assessment-bulk-delete',
        settings: '/risk-management/identification-assessment-settings',
        show: (id) => `/risk-management/identification-and-assessments/${id}`,
        project_stages: '/risk-management/project-stages',
        app_role: '/app/project-roles',
        risk_categories: '/risk-management/risk-categories',
        risk_owners: '/risk-management/risk-owners',
        AddUserID: `/users?project_id=${userProjectsID}`,
        risk_assessments: (id) => `/risk-management/identification-and-assessment/${id}/risk-assessments`,
        risk_analyses: (id) => `/risk-management/identification-and-assessment/${id}/risk-analyses`,
        mitigation_plans: (id) => `/risk-management/identification-and-assessment/${id}/mitigation-plans`,
        monitor_and_tracks: (id) => `/risk-management/identification-and-assessment/${id}/monitor-and-tracks`,
        threat_opportunity: '/risk-management/threat-and-opportunities',
        risk_area: '/risk-management/risk-areas'
      }
    },
    //created by me
    incident_reporting: {
      incident: '/risk-management/incident-reporting',
      IncidentShow: (id) => `/risk-management/incident-reporting/${id}`,
      DelIncident: '/risk-management/incident-reporting',
      bulk_delete: '/risk-management/incident-reporting-bulk-delete',
    },
    risk_change_request: {
      crud: '/risk-management/risk-change-requests',
      riskchangeview: (id) => `/risk-management/risk-change-requests/${id}`,
      bulk_delete: '/risk-management/risk-change-request-bulk-delete',
    },
    risk_stackholder_feedback: {
      crud: '/risk-management/stakeholder-feedback',
      stakehodlerShow: (id) => `/risk-management/stakeholder-feedback/${id}`,
    },
    risk_escalation: {
      crud: '/risk-management/risk-escalation',
      escalation_levels: '/risk-management/escalation-levels',
      escalation_levels_create: '/risk-management/escalation-levels',
      esclation_Show: (id) => `/risk-management/risk-escalation/${id}`,
    },
    risk_priotization: {
      crud: '/risk-management/risk-prioritization',
      priotization_Show: (id) => `/risk-management/risk-prioritization/${id}`,
    },
    risk_response_team: {
      crud: '/risk-management/risk-team-assignment',
      response_team_Show: (id) => `/risk-management/risk-team-assignment/${id}`,
    },
    quaterly_review: {
      crud: '/risk-management/quarterly-risk-review',
      quaterly_review_Show: (id) => `/risk-management/quarterly-risk-review/${id}`,
    },
    mitigation_survey: {
      crud: '/risk-management/risk-mitigation-survey',
      mitigation_survey_Show: (id) => `/risk-management/risk-mitigation-survey/${id}`,
    },
    risk_Learning_Insights: {
      crud: '/risk-management/risk-learning-insight',
      risk_Learning_Insights_Show: (id) => `/risk-management/risk-learning-insight/${id}`,
    },
    risk_trend_analysis: {
      crud: '/risk-management/risk-trend-analysis-report',
      risk_trend_analysis_Show: (id) => `/risk-management/risk-trend-analysis-report/${id}`,
    },
    mitigation_budget_request: {
      crud: '/risk-management/risk-mitigation-budget-request',
      mitigation_budget_request_Show: (id) => `/risk-management/risk-mitigation-budget-request/${id}`,
    },
  },
  job_costing: {
    get_list: '/job-costing',
    setting_customs: '/job-costing-custom-fields',
    setting: '/job-costing-settings',
    setting_post: '/job-costing-settings',
    update: '/job-costing-update',
    undo_last_drop: '/detach-job-costing',
    email: '/job-costing-mail',
  },
  accounting: {
    projects: {
      get_list: '/company/projects',
      post_list: '/company/projects',
      delete: '/company/projects',
    },
    customers: {
      get_list: '/company/customers',
      post_list: '/company/customers',
      delete: '/company/customers',
      get_countries: '/countries',
      get_states: (id) => `/countries/${id}/states`,

    },
    vendors: {
      get_list: '/accounting/vendors',

    },
    expenses: {
      get_list: '/accounting/expenses',
      post_list: '/accounting/expenses',
      delete: '/accounting/expenses',
      //dropdowms
      get_employee_list: '/company/employees',
      get_project_list: '/company/projects',
      get_departments_list: '/accounting/departments',
      add_departments_list: '/accounting/departments',
      get_category_list: '/accounting/expense-categories',
      add_category_list: '/accounting/expense-categories',
      get_cost_center_list: '/accounting/cost-centers',
      charts_of_account_list: '/accounting/account-charts',
    },
    bank_information: {
      get_list: '/accounting/bank-information',
      show_list: '/accounting/bank-information',
      post_list: '/accounting/bank-information',
      delete: '/accounting/bank-information',
    },
    documents: {
      get_list: '/accounting/accounting-documents',
      post_request: '/accounting/accounting-documents',
      delete: '/accounting/accounting-documents',
      get_tag_fodlers: '/accounting/tag-and-folders',
      store_tag_fodlers: '/accounting/tag-and-folders',
      get_all_users: '/company/users',
    },
    employee: {
      get_list: '/company/employees',
      delete: '/company/global-employees',
      post_list: '/company/employees'
    },
    finance_statement: {
      get_list: '/accounting/financial-statements',
      post_list: '/accounting/financial-statements',
      delete: '/accounting/financial-statements',
    },
    income_statement: {
      get_list: '/accounting/income-statement',
    },
    balance_sheet: {
      get_list: '/accounting/balance_sheet',
    },
    profit_loss: {
      get_list: '/accounting/profit_loss_statement',
    },
 

    payroll: {

      weeklyPayroll: {
        get_payrolls: '/accounting/weekly-payrolls',
        post_payrolls: '/accounting/weekly-payrolls',
        get_time_sheet: '/employees/weekly-time-sheet-report'
      },
      certifiedPayroll: {
        get_list: '/accounting/certified-payrolls'
      },
      payrollTaxes: {
        get_list: '/accounting/payroll-taxes'
      },
      salesTax:{
        get_list: '/accounting/sales-taxes'
      },

    },
    general_ledger: {
      get_list: '/accounting/general-ledger',
      get_cash_flow: '/accounting/cash-flow',
    },
    account_receiveable: {
    
      propsal: {
        get_list: '/accounting/account-receivable/proposals',
      },
      invoice: {
        get_list: '/accounting/account-receivable/invoices',
        update_invoice: '/accounting/account-receivable/invoices',

      },
      payment_requisition: {
        get_list_sov: '/accounting/account-receivable/sov-forms',
      },
      cost_codes: {
        get_category_list: '/accounting/cost-codes',
        get_activities: '/accounting/cost-codes-activities',
      },
      change_order: {
        get_category_list: '/accounting/change-order',
        get_activities: '/accounting/change-order-activities',
      },

    },
    account_payable: {
      purchase_order: {
        get_list: '/accounting/account-payable/purchase-orders',
      },
      purchase_order_invoice: {
        get_list: '/accounting/purchase-orders/',
        post_list: '/accounting/purchase-orders/',
      },
      subcontractor_amount: {
        get_list: '/accounting/account-payable/subcontractors-agreements',
      },
      subcontractor_invoice: {
        get_list: '/accounting/subcontractor-agreements/',
        post_list: '/accounting/subcontractor-agreements/',
      },
      work_order: {
        get_list: '/accounting/account-payable/work-orders',
      },
      work_order_invoice: {
        get_list: '/accounting/work-orders/',
        post_list: '/accounting/work-orders/',
      },
      invoices: {
        get_list: '/accounting/all-invoices',
        delete: '/accounting/all-invoices',
      },
    },
    taxes: {
      business_taxes: {
        crud_list: '/accounting/tax-type/1/business-and-misc-taxes',
      },
      misc_taxes: {
        crud_list: '/accounting/tax-type/2/business-and-misc-taxes',
      },

    },
    inventory: {
      assests: {
        get_list: '/accounting/assets',
        post_list: '/accounting/assets',
        update_list: '/accounting/assets',
        delete: '/accounting/assets',
      },
      materials: {
        get_list: '/performance/projects/'
      },
      equipments: {
        get_list: '/performance/projects/',
      },

    },

  },

  ganttChart: {
    get_data: '/gantt-chart-cost-codes',
    post_catgory: '/gantt-chart/cost-code-categories',
  },
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};