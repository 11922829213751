import { Switch,Button } from '@mui/material';
import { useState } from 'react';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router';

const CreateVendor = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [is_Checked, setIs_Checked] = useState(false);
  const [isCheckedOne, setIsCheckedOne] = useState(false);
  const [riskIds, setRiskIds] = useState([]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/accounts-management/vendors');
  };

  const handleCheckboxChangeOne = (event) => {
    setIsCheckedOne(event.target.checked);
  };

  const handleToggle = (event) => {
    setIsCheckedOne(event.target.checked); // Update the state
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckbox_Change = (event) => {
    setIs_Checked(event.target.checked);
  };

  const NewUserSchema = Yup.object().shape({
    incident_id: Yup.string().required('Incident Id is required'),
    incident_description: Yup.string().required('Incident Description is required'),
    date_and_time_of_incident: Yup.mixed().required('Date And Time Of Inciden is required'),
    impacted_areas: Yup.string().required('Impacted Areas is required'),
    immediate_actions_taken: Yup.string().required('Immediate Actions Taken is required'),
    incident_severity: Yup.string().required('Incident Severity is required'),
  });

  const defaultValues = useMemo(() => {
    const relatedRisk = { id: '', risk_id: 'None' };
    return {
      incident_id: '',
      incident_description: '',
      date_and_time_of_incident: new Date(),
      impacted_areas: '',
      immediate_actions_taken: '',
      incident_severity: '',
      is_related: relatedRisk,
    };
  }, [riskIds]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // Reset form values when `currentItem` changes
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const values = watch();

  useEffect(() => {
    reset({
      incident_id: '',
      incident_description: '',
      date_and_time_of_incident: new Date(),
      impacted_areas: '',
      immediate_actions_taken: '',
      incident_severity: '',
      is_related: { id: '', risk_id: 'None' },
    });
  }, [reset]);
  const onSubmit = handleSubmit(async (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);
  });
  return (
    <div style={{marginLeft:'15px'}}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
      <div
          style={{
            display: 'flex',
            justifyContent: 'space-between', 
            alignItems: 'center', 
            padding: '16px', 
          }}
        >
        <h2>Base Field</h2>
        <Button variant="contained" className="dark-button" onClick={handleNavigate}>
          Back
        </Button>
        </div>
        <Box
          mt={2}
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField name="sr.no" label="Sr. No" />
          <RHFAutocomplete
            name=""
            label="Type of Vendor"
            options={['Vendor1', `Vendor2`, 'Vendor3', 'Vendor4']}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            error={!!methods.formState.errors.approval_status}
            helperText={methods.formState.errors.approval_status?.message}
          />
          <RHFTextField name="firstName" label="First Name" />
          <RHFTextField name="last" label="Last Name" />
          <RHFTextField name="title" label="Title" />
          <RHFTextField name="trade" label="Trade / Speciality" />
          <RHFTextField name="companyname" label="Company Name" />
          <RHFTextField name="address 1" label="Address 1" />
          <RHFTextField name="address 2" label="Address 2" />
          <RHFTextField name="city" label="City" />
          <RHFAutocomplete
            name=""
            label="States"
            options={['State1', `State2`, 'State3', 'State4']}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            error={!!methods.formState.errors.approval_status}
            helperText={methods.formState.errors.approval_status?.message}
          />
          <RHFTextField name="zipCode" label="Zip Code" />
          <RHFAutocomplete
            name=""
            label="Country"
            options={['Ameria', `Canada`, 'Mexico', 'United States']}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            error={!!methods.formState.errors.approval_status}
            helperText={methods.formState.errors.approval_status?.message}
          />
          <RHFTextField name="countiesserved" label="Counties Served" />
          <RHFTextField name="phone" label="Phone" />
          <RHFTextField name="email" label="Email" />
          <RHFTextField name="fax" label="Fax" />
          <RHFAutocomplete
            name=""
            label="Union / Non Union"
            options={['State1', `State2`, 'State3', 'State4']}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            error={!!methods.formState.errors.approval_status}
            helperText={methods.formState.errors.approval_status?.message}
          />
          <RHFAutocomplete
            name=""
            label=" Minority Contractor Type"
            options={['State1', `State2`, 'State3', 'State4']}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            error={!!methods.formState.errors.approval_status}
            helperText={methods.formState.errors.approval_status?.message}
          />
          <br />
          {/*//upload*/}
          <div style={{ marginTop: '10px' }}>
            <label>
              <span style={{ color: 'darkgrey' }}>Signature</span>{' '}
              <span style={{ color: 'red' }}>*</span>
            </label>
            <div>
              <input
                type="file"
                className="form-control"
                required
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '8px',
                  fontSize: '14px',
                  marginTop: '8px',
                  width: '500px',
                  height: '50px',
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: '10px' }}>
            <label>
              <span style={{ color: 'darkgrey' }}>Upload Signature</span>{' '}
              <span style={{ color: 'red' }}>*</span>
            </label>
            <div>
              <input
                type="file"
                className="form-control"
                required
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '8px',
                  fontSize: '14px',
                  marginTop: '8px',
                  width: '500px',
                  height: '50px',
                }}
              />
            </div>
          </div>
        </Box>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginTop: '35px',
            maginLeft: '25px',
          }}
        >
          <span
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            Additional Information
          </span>
          <input
            type="checkbox"
            style={{ width: '16px', height: '16px' }}
            onChange={handleCheckboxChange}
            checked={isChecked}
          />
        </div>
        {isChecked && (
          <div>
            <div>
              <Box
                mt={2}
                rowGap={2}
                columnGap={3}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="naicscode" label="Naics Code" />
                <RHFTextField name="description" label="Description" />
                <RHFTextField name="classification" label="Classification" />
                <RHFTextField name="specificfunction" label="Specific Function" />
                <RHFTextField name="website" label="Website" />
                <RHFTextField name="socialmedia" label="Social Media" />
                <RHFTextField name="businesslocation" label="Business Location" />
                <RHFTextField name="businesslocation" label="Business Location" />
                <RHFTextField name="license" label="License" />
                <RHFTextField name="installercertificates" label="Installer Certificates" />
                <RHFTextField name="insurance" label="Insurance" />
                <RHFTextField name="dnbnumber" label="DNB Number" />
                <RHFTextField name="blockchainid" label="Blockchain ID" />
                <RHFTextField name="agenciesworkedwith" label="Agencies Worked With" />
                <RHFTextField name="areaofexperties" label="Area of Expertise" />
              </Box>
            </div>
            <div style={{ marginTop: '40px' }}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Switch checked={isCheckedOne} color="primary" onChange={handleToggle} />
                  <h4 style={{ margin: 0 }}>ADD EXPERIENCES</h4>
                </div>
                {isCheckedOne && (
                  <div style={{ marginTop: '10px' }}>
                    <div>
                      <Box
                        mt={2}
                        rowGap={2}
                        columnGap={3}
                        display="grid"
                        gridTemplateColumns={{
                          xs: 'repeat(1, 1fr)',
                          sm: 'repeat(2, 1fr)',
                        }}
                      >
                        <RHFTextField name="naicscode" label="Contract Number" />
                        <RHFTextField name="description" label="Name  of Contract" />
                        <RHFTextField name="classification" label="Name  of Agency" />
                        <RHFTextField name="specificfunction" label="Name  of Person to Contact" />
                        <RHFTextField name="website" label="Title of person" />
                        <RHFTextField name="socialmedia" label="Agency Phone Number" />
                        <RHFTextField name="businesslocation" label="Agency  Contact Email" />
                        <RHFTextField name="businesslocation" label="Value of Conctract" />
                        <RHFTextField name="license" label="Date of Completion" />
                        <RHFTextField name="installercertificates" label="Performed As" />
                        <RHFTextField name="insurance" label="Scope of work " />
                        <RHFTextField name="dnbnumber" label="Sample of Work performance" />
                      </Box>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginTop: '35px',
                maginLeft: '25px',
              }}
            >
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                SHIPPING INFORMATION
              </span>
              <input
                type="checkbox"
                style={{ width: '16px', height: '16px' }}
                onChange={handleCheckbox_Change}
                checked={is_Checked}
              />
            </div>
            {is_Checked && (
              <div style={{ marginTop: '10px' }}>
                <div>
                  <Box
                    mt={2}
                    rowGap={2}
                    columnGap={3}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                    }}
                  >
                    <RHFTextField name="naicscode" label="Adress" />
                    <RHFTextField name="description" label="Suit" />
                    <RHFAutocomplete
                      name=""
                      label="Country"
                      options={['America', `Canada`, 'Mexico', 'United States']}
                      getOptionLabel={(option) => option}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                      error={!!methods.formState.errors.approval_status}
                      helperText={methods.formState.errors.approval_status?.message}
                    />
                    <RHFAutocomplete
                      name=""
                      label="States"
                      options={['USA', `Canada`, 'Mexico', 'United States']}
                      getOptionLabel={(option) => option}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                      error={!!methods.formState.errors.approval_status}
                      helperText={methods.formState.errors.approval_status?.message}
                    />
                    <RHFTextField name="specificfunction" label="City" />
                    <RHFTextField name="website" label="Zip Code" />
                  </Box>
                </div>
              </div>
            )}
          </div>
        )}
      </FormProvider>
    </div>
  );
};
export default CreateVendor;
