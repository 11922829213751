import React from 'react';
import {
  Switch,
  FormControlLabel,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Button,
} from '@mui/material';

import { useState } from 'react';

import { useNavigate } from 'react-router';

import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';

import { useForm, Controller } from 'react-hook-form';

import * as Yup from 'yup';
import { useMemo } from 'react';

import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';

import Iconify from 'src/components/iconify';

const CreateRunPayroll = () => {
  const [isChecked, setIsChecked] = useState(false);

  const [is_Checked, setIs_Checked] = useState(false);

  const [isCheckedOne, setIsCheckedOne] = useState(false);

  const navigate = useNavigate();

  const handleCheckboxChangeOne = (event) => {
    setIsCheckedOne(event.target.checked);
  };

  const handleToggle = (event) => {
    setIsCheckedOne(event.target.checked);
  };

  const [riskIds, setRiskIds] = useState([]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCheckbox_Change = (event) => {
    setIs_Checked(event.target.checked);
  };

  const handleBack = () => {
    navigate('/accounts-management/payroll');
  };

  const TABLE_HEAD = [
    { id: 'sr.no', label: 'Sr.no' },
    { id: 'Employee Name', label: 'Employee Name' },
    { id: 'Title', label: 'Title' },
    { id: 'Total Hours', label: 'Total Hours' },
    { id: 'Amount', label: 'Amount' },
    { id: 'Tax', label: 'Tax' },
    { id: 'Total Amount', label: 'Total Amount' },
    { id: 'Actions', label: 'Action' },
  ];

  const dummyData = [
    {
      'sr.no': 1,
      'Employee Name': 'John Doe',
      Title: 'Software Engineer',
      'Total Hours': 40,
      Amount: '$2000',
      Tax: '$200',
      'Total Amount': '$2200',
      Actions: 'Edit/Delete',
    },
  ];

  const riskOwnersOptions = [
    { id: 1, name: 'Alice Johnson' },
    { id: 2, name: 'Bob Smith' },
    { id: 3, name: 'Charlie Brown' },
    { id: 4, name: 'Diana Prince' },
    { id: 5, name: 'Evan Wright' },
  ];

  const NewUserSchema = Yup.object().shape({
    incident_id: Yup.string().required('Incident Id is required'),
    incident_description: Yup.string().required('Incident Description is required'),
    date_and_time_of_incident: Yup.mixed().required('Date And Time Of Inciden is required'),
    impacted_areas: Yup.string().required('Impacted Areas is required'),
    immediate_actions_taken: Yup.string().required('Immediate Actions Taken is required'),
    incident_severity: Yup.string().required('Incident Severity is required'),
  });

  const defaultValues = useMemo(() => {
    const relatedRisk = { id: '', risk_id: 'None' };
    return {
      incident_id: '',
      incident_description: '',
      date_and_time_of_incident: new Date(),
      impacted_areas: '',
      immediate_actions_taken: '',
      incident_severity: '',
      is_related: relatedRisk,
    };
  }, [riskIds]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const values = watch();

  useEffect(() => {
    reset({
      incident_id: '',
      incident_description: '',
      date_and_time_of_incident: new Date(),
      impacted_areas: '',
      immediate_actions_taken: '',
      incident_severity: '',
      is_related: { id: '', risk_id: 'None' },
    });
  }, [reset]);

  const onSubmit = handleSubmit(async (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);
  });

  return (
    <div style={{marginLeft:'15px'}}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <h2>RUN PAYROLL </h2>
          <Button variant="contained" className="dark-button" onClick={handleBack}>
            Back to List
          </Button>
        </div>
        <Box
          mt={2}
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          
            <RHFTextField name="weekstartdate" label="Week Start Date" />
          

          
            <RHFTextField name="facility" label="Facility" />
          

          
            <RHFTextField name="scopeofwork" label="Scope of work" />
        

          
                 
                <RHFTextField name="supervisor" label="supervisor" />
              
            
        

         
            <RHFTextField name="weekending" label="Week Ending" />
          

          
            <RHFTextField name="weekending" label="Job Order Number / Project Number" />
          
        </Box>
      </FormProvider>

      <div style={{ marginTop: '60px' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((column) => (
                  <TableCell key={column.id} sx={{ fontWeight: 'bold' }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {dummyData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {TABLE_HEAD.map((column) => (
                    <TableCell key={column.id}>
                      {row[column.id] !== undefined ? row[column.id] : '-'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            marginTop: '50px',
          }}
        >
          <Button sx={{ minWidth: 65, height: 35 }} className="dark-button" variant="contained">
            Submit
          </Button>
          <Button sx={{ minWidth: 65, height: 35 }} className="dark-button" variant="contained">
            Cancel
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default CreateRunPayroll;
