import { Switch, TextField, Button } from '@mui/material';
import { useState } from 'react';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { useNavigate, useLocation } from 'react-router';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import axios, { endpoints, simpleHeader } from 'src/utils/axios';
import { DatePicker } from '@mui/x-date-pickers';
import { getRequest, postRequest } from 'src/services';

const CreateBankInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {state:data} = location;
  const ID=data?.id
  let defaultValues = {
    full_name: data?.full_name || '',
    address: data?.address || '',
    city: data?.city || '',
    state: data?.state || '',
    zip: data?.zip || '',
    phone_number: data?.phone_number || '',
    email_address: data?.email_address || '',
    company_name: data?.company_name || '',
    company_address: data?.company_address || '',
    tax_id: data?.tax_id || '',
    bank_name: data?.bank_name || '',
    bank_address: data?.bank_address || '',
    bank_city: data?.bank_city || '',
    bank_state: data?.bank_state || '',
    bank_zip: data?.bank_zip || '',
    bank_phone_number: data?.bank_phone_number || '',
    currency: data?.currency || '',
    opening_balance: data?.opening_balance || '',
    bank_account_type: data?.bank_account_type || '',
    account_number: data?.account_number || '',
    routing_number: data?.routing_number || '',
    purpose_of_account: data?.purpose_of_account || '',
    opening_date: data?.opening_date || '',
    signature: data?.signature || '',
    balance: data?.balance || '',
  };
  
  const NewUserSchema = Yup.object().shape({
    /* full_name: Yup.string().required('Full name is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string().required('ZIP code is required'),
    phone_number: Yup.string()
      .matches(/^\d+$/, 'Phone number must be numeric')
      .required('Phone number is required'),
    email_address: Yup.string()
      .email('Invalid email address')
      .required('Email address is required'),
    company_name: Yup.string().required('Company name is required'),
    company_address: Yup.string().required('Company address is required'),
    tax_id: Yup.string().required('Tax ID is required'),
    bank_name: Yup.string().required('Bank name is required'),
    bank_address: Yup.string().required('Bank address is required'),
    bank_city: Yup.string().required('Bank city is required'),
    bank_state: Yup.string().required('Bank state is required'),
    bank_zip: Yup.string().required('Bank ZIP code is required'),
    bank_phone_number: Yup.string()
      .matches(/^\d+$/, 'Bank phone number must be numeric')
      .required('Bank phone number is required'),
    currency: Yup.string().required('Currency is required'),
    opening_balance: Yup.number()
      .min(0, 'Opening balance cannot be negative')
      .required('Opening balance is required'),
    bank_account_type: Yup.string().required('Bank account type is required'),
    account_number: Yup.string()
      .matches(/^\d+$/, 'Account number must be numeric')
      .required('Account number is required'),
    routing_number: Yup.string()
      .matches(/^\d+$/, 'Routing number must be numeric')
      .required('Routing number is required'),
    purpose_of_account: Yup.string().required('Purpose of account is required'),
    opening_date: Yup.date()
      .max(new Date(), 'Opening date cannot be in the future')
      .required('Opening date is required'),
    signature: Yup.string().required('Signature is required'),
    balance: Yup.number()
      .min(0, 'Balance cannot be negative')
      .required('Balance is required'),  */
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleNavigate = () => {
    navigate('/accounts-management/bank-information');
  };

  const onSubmit = handleSubmit(async (data) => {
   
 

    try {
      const formData = new FormData();
      formData.append('full_name', data.full_name);
      formData.append('address', data.address);
      formData.append('city', data.city);
      formData.append('state', data.state);
      formData.append('zip', data.zip);
      formData.append('phone_number', data.phone_number || '');
      formData.append('email_address', data.email_address || '');
      formData.append('company_name', data.company_name || '');
      formData.append('company_address', data.company_address || '');
      formData.append('tax_id', data.country?.tax_id || '');
      formData.append('bank_name', data.bank_name || '');
      formData.append('bank_address', data.bank_address || '');
      formData.append('bank_city', data.bank_city || '');
      formData.append('bank_state', data.bank_state || '');
      formData.append('bank_zip', data.bank_zip || '');
      formData.append('bank_phone_number', data.bank_phone_number || '');
      formData.append('currency', data.currency || '');
      formData.append('opening_balance', data.opening_balance || '');
      formData.append('bank_account_type', data.bank_account_type || '');
      formData.append('account_number', data.account_number || '');
      formData.append('routing_number', data.routing_number || '');
      formData.append('purpose_of_account', data.purpose_of_account || '');
      formData.append('opening_date', data.opening_date || '');
      formData.append('signature', data.signature || '');
      formData.append('balance', data.balance || '');
      


      const result = await postRequest(
        ID ? `${endpoints.accounting.bank_information.post_list}/${ID}?_method=PUT`
          : endpoints.accounting.bank_information.post_list,
        formData,
        true
      );

      //console.log(data)

      reset();
      navigate('/accounts-management/bank-information');
    } catch (err) {
      console.error('Error in catch:', err);
    }
  });

  return (
    <div style={{ marginLeft: '14px' }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <h2>BANK & CREDIT CARD INFORMATION</h2>
          <Button variant="contained" className="dark-button" onClick={handleNavigate}>
            Back to List
          </Button>
        </div>
        <Box
          mt={2}
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField name="full_name" label="Full Name" />
          <RHFTextField name="address" label="Address" />
          <RHFTextField name="city" label="City" />
          <RHFTextField name="state" label="State" />
          <RHFTextField name="zip" label="Zip" />
          <RHFTextField name="phone_number" label="Phone Number" />
          <RHFTextField name="email_address" label="Email Address" />
          <RHFTextField name="company_name" label="Company Name" />

          <RHFTextField name="tax_id" label="Tax ID" />
        </Box>
        <div style={{ marginTop: '50px' }}>
          <h3>BANK INFORMATION</h3>
        </div>
        <Box
          mt={2}
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField name="bank_name" label="Bank Name" />
          <RHFTextField name="bank_address" label="Bank Address" />
          <RHFTextField name="bank_city" label="City" />
          <RHFTextField name="bank_state" label="State" />
          <RHFTextField name="bank_zip" label="Zip" />
          <RHFTextField name="bank_phonenumber" label="Bank Phone Number" />

          <RHFAutocomplete
            name="currency"
            label="Currency"
            options={['USD/EUR']}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            error={!!methods.formState.errors.approval_status}
            helperText={methods.formState.errors.approval_status?.message}
          />
          <RHFTextField name="opening_balance" label="Opening Balance" />
        </Box>

        <div style={{ marginTop: '50px' }}>
          <h3>ADDITIONAL INFORMATION</h3>
        </div>
        <Box
          mt={2}
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField name="bank_account_type" label="Account Type (Checking/Savings)" />
          <RHFTextField name="account_number" label="Account Number" />
          <RHFTextField name="routing_number" label="Routing Number" />
          <RHFTextField name="purpose_of_account" label="Purpose of Account" />
          <Controller
            name="opening_date"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Date of opening"
                {...field}
                inputFormat="yyyy-MM-dd"
                disableFuture
                renderInput={(params) => (
                  <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
                )}
              />
            )}
          />
        </Box>

        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '16px',
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              className="dark-button"
              loading={isSubmitting}
            >
              {data ? 'Update' : 'Submit'}
            </LoadingButton>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};
export default CreateBankInformation;
