// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root;

//export const baseURL = `https://web.ezelogs.com/api`
export const baseURL = `https://staging.ezelogs.com/api`
export const baseURLStaging = `https://staging.ezelogs.com/api`
export const baseURLORG = `https://web.ezelogs.com`
// export const baseURL = 'http://200.10.12.81:8080/api'

export const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiMTU4ZTFmNWIyM2M4MjM3NjEzM2QyNzg3YWRmMjc3MDllNTk5OTQxM2Q5NzNhMzFkZTZlZTk5ODQ2MDM1N2UzOThhMWE4ZDNkNDM4ZWMzNzAiLCJpYXQiOjE3MzQ1OTAyMDMuMDI4MjY1LCJuYmYiOjE3MzQ1OTAyMDMuMDI4MjY5LCJleHAiOjE3NjYxMjYyMDMuMDIwMDksInN1YiI6IjI2MjAiLCJzY29wZXMiOltdfQ.e51Rxl9IN5yc25ObPpU_p8jR24NLJoFqy0qm3twe12nbpTvME_nDwJxqrecrHL5vScdoD0AGt7NNu_6kkL-SiaSN4LbWYPaY_LTScPibUTmhVlw764M4QzjhTLDrKlZ0czOKdHypJ1e5NOzJi9yO36BhAj02Lak10h4HUM_Koj_mjtAtOi7zKZPL1ydmiztvum84HWDFf_rSjIY_5zzRcrHdpkIJC5yCjQmfNlk-Aqw22caL9vwvkEjIT2KO9_OVAR61eTOiU4OvA7Ocp8HkzOWIRqLGQo4hFpM__nFhV3YxKiS0NDGpbPJkC_r8WMxsg43Eubi7F4Zjzxe-1AQyJ6Vih91vL5d6id0yHlrBe6s_Dqm3lwDSdEkA4TAkqEvz--GAmmP6tWymCNImzv3ltxmiqXihdCegKSj6fM7ZbopQ2Wuwua4UgfRyOzYOg9OaL0BpcfvseLkOgYsuWigkPtRqcO_YhBishf7sMgEUUCBpwjhEVTasv5_CrfqEe3tClDJ-i7o3wvWeuzr_j8a3bv95iDkIh0r-n0KysKP45zO4IJOurbj9mtf4PbGiwzYw9BL-j3dYrAP9pJ2S3_orsQyGtrJOd3eN34iLVOV1eJkZUF2efsU6UnM4c7qOsDFGkjAEOErRp8Sj2H1k-eTXT5rNbDd4SZ-mQ9LMoY5UTsQ'