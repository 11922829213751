import { Box, InputAdornment } from "@mui/material";
import { RHFTextField } from "src/components/hook-form";
export default function ActivityFifthRow({icon,showUnitFields,calculationofDirectCost}) {
  return (
    <>
 {showUnitFields &&     <RHFTextField
        type="number"
        name="equipment_unit_price"
        label="Equipment Unit Price"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />}
      <RHFTextField
        type="number"
        onChange={(e)=>{
          calculationofDirectCost(e.target.value,'equipment_cost')
          }}
        name="equipment_cost"
        label="Equipment Cost"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />
 {showUnitFields &&     <RHFTextField
        type="number"
        name="subcontractor_unit_price"
        label="Subcontractor Unit Price"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />}
      <RHFTextField
        type="number"
        onChange={(e)=>{
          calculationofDirectCost(e.target.value,'subcontractor_cost')
          }}
        name="subcontractor_cost"
        label="Subcontractor Cost"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {icon}
              </Box>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}