import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import WorkOrderFollowUp from './work-order-follow-up';

// ----------------------------------------------------------------------
export default function UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, serialNumber, WOId, chartsAccountList, bankInformation, reload }) {
    console.log("🚀 ~ UserTableRow ~ row:", row)
    const { project, status, remarks, work_order_number, work_order_date, total, created_by, balance } = row;

    const confirm = useBoolean();
    const create = useBoolean();
    const popover = usePopover();

    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow} />
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell>{project?.name}</TableCell>
                <TableCell
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={create.onTrue}
                >
                    {work_order_number}
                </TableCell>
                <TableCell>{work_order_date}</TableCell>
                <TableCell>{created_by?.name }</TableCell>
                <TableCell>{work_order_date}</TableCell>
                <TableCell>{total}</TableCell>
                <TableCell>{balance}</TableCell>
                <TableCell>{remarks}</TableCell>
                <TableCell>{status === 1 ? "Yes" : "No"}</TableCell>
                <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>
            <WorkOrderFollowUp
                open={create.value}
                onClose={create.onFalse}
                WONumber={work_order_number}
                WOId={WOId}
                chartsAccountList={chartsAccountList}
                bankInformation={bankInformation}
                balance={balance}
                reload={reload}

            />
            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 140 }}
            >
                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onEditRow(row);
                        console.log(">>>>>>>", row);
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>
            </CustomPopover>
            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content="Are you sure you want to delete?"
                action={
                    <Button
                        variant="contained" color="error"
                        onClick={() => {
                            confirm.onFalse();
                            onDeleteRow(row.id);
                            console.log(">>>>>>>", row.id);
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
}

UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};
