import { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  DialogActions,
  Button,
  InputAdornment, IconButton
} from '@mui/material';
import { RHFTextField } from '../hook-form';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from '../hook-form/form-provider';
import { postRequest } from 'src/services';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

import { handleErrorMessages } from '../risk-management/risk-register/feching';
import Iconify from '../iconify';
import { DatePicker } from '@mui/x-date-pickers';

const EscalationModal = ({ isOpen, handleClose, reload, PercentIcon }) => {
  const [loading, setLoading] = useState(false);
  const NewUserSchema = Yup.object().shape({});
  
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues: {
      
    },
  });

  const { reset, handleSubmit, setError, clearErrors, warning, control } = methods;
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const onClose = () => {
    handleClose();
    reset();
  };

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);
    try {
      const responseData = await postRequest('', formData);
      if (responseData.metadata && responseData.metadata.success) {
        onClose();
        reload();
        enqueueSnackbar('Contingency created successfully', { variant: 'success' });
      } else {
        handleErrorMessages(responseData.metadata.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      enqueueSnackbar('An error occurred while submitting the form.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  });



  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
          <span
            className='cardColor'
            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
            &nbsp;&nbsp; Create Escalation  &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={handleClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            mt={2}
            mb={2}
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(2, 1fr)',
              lg: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="escalation_code" label="Escalation Code " />
            <Controller
              name="estinmate_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Estimate Date"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error || !!warning,
                      helperText: error?.message || warning,
                    },
                  }}
                />
              )}
            />
            <RHFTextField type="number" name="contract_duration" label="Contract Duration" />
            <Controller
              name="start_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Project Start Date (NTP)"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                    const endDate = methods.getValues('end_date');
                    if (newValue && endDate && newValue > endDate) {
                      setError('start_date', {
                        message: 'Start Date cannot be later than End Date',
                      });
                    } else {
                      clearErrors('start_date');
                      clearErrors('end_date');
                    }
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error || !!warning,
                      helperText: error?.message || warning,
                    },
                  }}
                />
              )}
            />
            <Controller
              name="end_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label={'Project End Date'}
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                    const startDate = methods.getValues('start_date');
                    if (newValue && startDate && newValue < startDate) {
                      setError('end_date', {
                        message: 'End Date cannot be earlier than Start Date',
                      });
                    } else {
                      clearErrors('end_date');
                      clearErrors('start_date');
                    }
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error || !!warning,
                      helperText: error?.message || warning,
                    },
                  }}
                />
              )}
            />
            <RHFTextField type="number" name="total_duration" label="Total Duration From Estimate Date" />
            <RHFTextField type="number" name="mid_point_of_construction" label="Mid Point Of Construction" />
            <Controller
              name="mid_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Mid Point Date  Of Construction "
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error || !!warning,
                      helperText: error?.message || warning,
                    },
                  }}
                />
              )}
            />
            <RHFTextField
              type="number"
              name="rate_of_escalation"
              label="Rate of Escalation"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {PercentIcon}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <Controller
              name="mid_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Mid Point Duration From Estimate Date (Years)"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error || !!warning,
                      helperText: error?.message || warning,
                    },
                  }}
                />
              )}
            />
            <RHFTextField
              type="number"
              name="escalation"
              label="Escalation %"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {PercentIcon}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
       
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            className="dark-button"
            disabled={loading}
            loading={loading}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default EscalationModal;