import { useEffect, useState, useMemo, useCallback } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { formatDate, handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
import { DatePicker } from '@mui/x-date-pickers';
import { MultiFilePreview, Upload } from 'src/components/upload';

const DocumentsCreate = ({ open, onClose, reload, currentItem, closeform, tagfodlers, createdby }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [isFileDeleted, setIsFileDeleted] = useState(false); 
    const [filePreview, setFilePreview] = useState([]);

    const NewDocumentSchema = Yup.object().shape({
        //name: Yup.string().required('Document Name is required'),
        //tag_and_folder: Yup.object().required('Tag and Folder is required'),
        //date: Yup.date().required('Date is required'),
        //created_by: Yup.string().required('Created By is required'),
        //type_of_document: Yup.string().required('Type of Document is required'),
        //remarks: Yup.string().optional(),
    });

    const defaultValues = useMemo(
        () => ({
            name: currentItem?.name || '',
            tag_and_folder: currentItem?.tag_and_folder || null,
            date: currentItem?.date ? new Date(currentItem.date) : new Date(),
            created_by: currentItem?.created_by || '',
            type_of_document: currentItem?.type_of_document || '',
            remarks: currentItem?.remarks || '',
            file: currentItem?.file || 'None',

        }),
        [currentItem]
    );

    useEffect(() => {
        console.log("🚀 ~ defaultValues------:", defaultValues)
        if (currentItem) {
            if (currentItem.file != null) {
                setFilePreview([{ ...currentItem.file, preview: currentItem.file.url }]);
            } else {
                setFilePreview([]);
            }
        }
    }, [defaultValues]);

    const methods = useForm({
        resolver: yupResolver(NewDocumentSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [currentItem, reset, defaultValues]);

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('tag_and_folder_id', JSON.stringify(data.tag_and_folder?.id));
        formData.append('date', formatDate(data.date));
        formData.append('created_by', data.created_by?.id);
        formData.append('type_of_document', data.type_of_document);
        formData.append('remarks', data.remarks || '');
        if (isFileDeleted) {
            formData.append('is_file_deleted', true);
        }
        if (files.length > 0) {
            formData.append('file', files[0]);
        }

        try {
            const response = await postRequest(
                currentItem?.id
                    ? `${endpoints.accounting.documents.post_request}/${currentItem.id}?_method=PUT`
                    : endpoints.accounting.documents.post_request,
                formData,
                true
            );

            if (response.metadata?.success) {
                reset();
                onClose();
                closeform();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response.message || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });

 const handleDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }));
      setFiles([...newFiles]);
    },
    [files]
  );



    const handleClose = () => {
        console.log("close clicked----");
        setIsFileDeleted(false);
        onClose(); // Close the dialog
    };

    const handleRemoveFile = (inputFile) => {
        setIsFileDeleted(true);
        setFiles([]);
        setFilePreview([]);
    };
    
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span
                        className='cardColor'
                        style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}
                    >
                        &nbsp;&nbsp; Documents &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <IconButton onClick={onClose}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box
                        mt={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
                    >
                        <RHFTextField name="name" label="Document Name" />
                        {/*<DropDownAddValueByid
                            name="tag_and_folder_id"
                            label="Tag & Folders"
                            options={tagfodlers}
                            onChange={(selectedOption) => {
                                methods.setValue('tag_and_folder_id', Number(selectedOption?.id || 0));
                            }}
                            url={endpoints.accounting.documents.store_tag_fodlers}
                        />*/}
                        <RHFAutocomplete
                            name="tag_and_folder"
                            label="Tag & Folders"
                            options={tagfodlers}
                            getOptionLabel={(option) => option?.name || ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                        />
                        <Controller
                            name="date"
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    label="Date"
                                    {...field}
                                    inputFormat="yyyy-MM-dd"
                                    //disableFuture
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                        <RHFAutocomplete
                            name="created_by"
                            label="Created / Uploaded By"
                            options={createdby}
                            getOptionLabel={(option) => option?.name || ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                        />
                        <RHFTextField name="type_of_document" label="Type of Document" />
                    </Box>
                    <Box mt={2}>
                        <RHFTextField name="remarks" label="Remarks / Notes" multiline rows={4} />
                    </Box>
                    <Box
                        mt={4}
                        rowGap={3}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                        }}
                    >
                        <Typography variant="subtitle2">Related Documents</Typography>
                        <Upload onDrop={handleDrop} onRemove={handleRemoveFile} />
                    </Box>
                    <Box sx={{ my: 3 }}>
                        <MultiFilePreview
                            files={files?.length > 0 ? files : filePreview}
                            onRemove={handleRemoveFile}
                            setFileDeleted={setIsFileDeleted}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        className='dark-button'
                        loading={isSubmitting}
                    >
                        {!currentItem || !currentItem.id ? 'Create Document' : 'Update Document'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};

export default DocumentsCreate;