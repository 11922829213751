// src/components/content/RiskRegister.js
import { useEffect, useState } from 'react';
import Header from '../header';
import {
  Box, Card,
  CardContent, IconButton,
  Table,
  TableBody, TableContainer, Tooltip
} from '@mui/material';
import Iconify from 'src/components/iconify';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { isEqual } from 'lodash';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import UserTableRow from './project-list-table-row';


const ProjectsListPayroll = () => {
  const navigate = useNavigate();
  let url = window.location.pathname
  const [loading, setLoading] = useState(false);
  const [actionVisible, setActionVisible] = useState(true);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [projectList, setprojectList] = useState([]);
  const [isMaterial, setIsMaterial] = useState(false)
  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'No.#', label: 'No.#' },
    { id: 'Project Name', label: 'ProjectName' },
  ];

  const table = useTable();
  const remove = useBoolean();

  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData.length > 0 ? tableData : [],
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  useEffect(() => {
    getProjects();
    const currentUrl = window.location.pathname;
    const result = getLastUrlSegment(currentUrl);
    setIsMaterial(result);
  }, [window.location.pathname]);

  const getLastUrlSegment = (url) => {
    if (!url) return false;
    const cleanUrl = url.replace(/\/$/, '');
    const lastSegment = cleanUrl.split('/').pop();
    return lastSegment === 'materials';
  };
  const getProjects = async () => {
    setLoading(true)
    const data = await getRequest(endpoints.accounting.expenses.get_project_list);
    console.log('🚀 ~ getProjets ~ data:', data);
    setLoading(false)
    if (data) {
      setprojectList(data);
      setTableData(data)
    }
  };
  return (
    <>
      <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
        {' '}
        <CardContent>
          {loading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
              <Header name="Projects" />
              <Card
                sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                  marginTop: '20px',
                }}
              >
                <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                  {actionVisible && (
                    <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                      <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            setActionVisible(checked),
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                        action={
                          <Tooltip title="Delete">
                            <IconButton color="primary" onClick={remove.onTrue}>
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    </div>
                  )}
                  <Scrollbar>
                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                      <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                          <TableHeadCustom
                            order={table.order}
                            orderBy={table.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={tableData.length}
                            numSelected={table.selected.length}
                            onSort={table.onSort}
                            onSelectAllRows={(checked) =>
                              table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                              )
                            }
                          />
                          <TableBody>
                            {dataFiltered
                              .slice(
                                table.page * table.rowsPerPage,
                                table.page * table.rowsPerPage + table.rowsPerPage
                              )
                              .map((row, index) => (
                                <UserTableRow
                                  key={row.id}
                                  row={row}
                                  selected={table.selected.includes(row.id)}
                                  onSelectRow={() => table.onSelectRow(row.id)}
                                  isMaterial={isMaterial}
                                  serialNumber={table.page * table.rowsPerPage + index + 1} 
                                />
                              ))}
                            <TableEmptyRows
                              height={denseHeight}
                              emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                            />
                            <TableNoData notFound={notFound} />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
function applyFilter({ inputData, comparator, filters }) {
  const { name, status, role } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);
  if (name) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }
  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }
  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }
  return inputData;
}
export default ProjectsListPayroll;