import { Box, MenuItem, Typography } from "@mui/material";
import DropDownAddValueCustom from "src/components/drop-down-add-value/drop-down-add-value-custom";
import { RHFSelect } from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";

export default function ActivityAssigntaskRow({ onTradeAdd }) {
  const discount = useBoolean();
  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ pb: 2, pt: 2 }} className="heading1">
          Assign Task
        </Typography>
        <Box
          mt={2}
          mb={2}
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
        >

          <DropDownAddValueCustom
            name="trade"
            label={`Select Trade`}
            onClick={onTradeAdd}
          // options={projectStageOptions}
          // reload={() => reloadProjectStageOptions()}
          // url={endpoints.risk_management.risk_register.identification.project_stages}
          />

          <RHFSelect name="assign_scope_to_subcontractors?" label="Assign Scope To Subcontractors?">
            <MenuItem value="Yes">
              Yes
            </MenuItem>
            <MenuItem value="No">
              No
            </MenuItem>
          </RHFSelect>
        </Box>
      </Box>
    </>
  );
}
