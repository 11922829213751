import { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  DialogActions,
  Button, IconButton
} from '@mui/material';
import { RHFTextField } from '../hook-form';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from '../hook-form/form-provider';
import { postRequest } from 'src/services';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { handleErrorMessages } from '../risk-management/risk-register/feching';
import Iconify from '../iconify';

const TradeModal = ({ isOpen, handleClose, reload, PercentIcon }) => {
  const [loading, setLoading] = useState(false);
  const NewUserSchema = Yup.object().shape({});
  
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues: {
      
    },
  });

  const { reset, handleSubmit } = methods;
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const onClose = () => {
    handleClose();
    reset();
  };
  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);
    try {
      const responseData = await postRequest('', formData);
      if (responseData.metadata && responseData.metadata.success) {
        onClose();
        reload();
        enqueueSnackbar('Contingency created successfully', { variant: 'success' });
      } else {
        handleErrorMessages(responseData.metadata.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      enqueueSnackbar('An error occurred while submitting the form.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  });

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
          <span
            className='cardColor'
            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
            &nbsp;&nbsp; Create Trade  &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={handleClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            mt={2}
            mb={2}
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(2, 1fr)',
              lg: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="trade_code" label="Trade Code " />
            <RHFTextField name="trade_name" label="Trade Name " />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            className="dark-button"
            disabled={loading}
            loading={loading}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default TradeModal;