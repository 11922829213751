// exportCsvData.js

export const getCsvData = (tableData = {}) => {
    return tableData?.map((row) => {
        const filteredRow = {};
        const fields = {
            id: row?.id,
            po_number: row?.purchase_order_number,
            vendor_name: row?.vendor?.name,
            project_name: row?.project?.name,
            po_date: row?.po_date,
            payment_terms: row?.bill_to_terms,
            client: row.contractor?.first_name + row.contractor?.last_name,
            date: row?.contracted_to_date,
            amount: row?.total,
            balance: row?.balance,
            remarks: row?.remarks,
            status: row?.status === 0 ? 'Yes' : 'No',
        };

        Object.keys(fields).forEach((key) => {
            if (key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};
