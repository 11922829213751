import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import {
    IconButton,
    Box,
    useTheme,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import EmployeeAddDialog from './new-employee-add';
import { useSearchParams } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { endpoints } from 'src/utils/axios';
import { getRequest } from 'src/services';
import { RHFAutocomplete } from 'src/components/hook-form';

const EmployeeUserAdd = ({ value, label, options = [], url = '', reload = () => { }, onRiskOwnerChange }) => {
    const [usersID, setUsersID] = useState([]);
    const searchParams = useSearchParams();
    const theme = useTheme();
    const add = useBoolean();
    const { enqueueSnackbar } = useSnackbar();

    const NewUserSchema = Yup.object().shape({
        label: Yup.string().required(`${label} is required`),
    });

    const defaultValues = useMemo(
        () => ({
            employee_id: value || "",
        }),
        [value]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        control,
        setValue,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        EmployeeOptions();
    }, []);

    const EmployeeOptions = async () => {
        try {
            const data = await getRequest(endpoints.accounting.expenses.get_employee_list);
            const transformedOptions = data?.map(user => ({
                id: user.id,
                name: `${user.first_name} ${user.last_name}`,
            })) || [];
            setUsersID(transformedOptions);
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
    };

    const assignedToValue = useWatch({ control, name: 'employee_id' });

    const handleOwnerChange = (event, value) => {
        setValue('employee_id', value ? value.id : '');
        onRiskOwnerChange?.(value ? value.id : null);
    };

    const handleUserAdded = () => {
        EmployeeOptions();
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
            <RHFAutocomplete
                sx={{ width: '100%' }}
                name="employee_id"
                label={label}
                options={usersID}
                value={usersID.find(user => user.id === assignedToValue) || null}
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleOwnerChange}
            />
            <IconButton
                onClick={add.onTrue}
                className='cardColor'
                sx={{
                    margin: '5px 10px',
                    fontSize: 25,
                    padding: '7px',
                    borderRadius: '50%',
                    '&:hover': {
                        backgroundColor: '#7AB13A',
                    },
                    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Iconify icon="mingcute:add-line" />
            </IconButton>
            {/* Modal */}
            <EmployeeAddDialog
                label={label}
                open={add.value}
                onClose={add.onFalse}
                reload={handleUserAdded}
            />
        </Box>
    );
};

export default EmployeeUserAdd;
