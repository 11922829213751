// src/components/content/RiskRegister.js
import { useEffect, useState } from 'react';
import Header from '../header';
import {
  Box,
  Button,
  Card,
  CardContent, IconButton,
  Table,
  TableBody, TableContainer, Tooltip
} from '@mui/material';
import Iconify from 'src/components/iconify';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import { LoadingButton } from '@mui/lab';
import UserTableRow from './documents-table-row';
import DocumentsCreate from './documents-create-form';


const Documents = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);

  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'Sr No', label: 'Sr No' },
    { id: 'Document Name', label: 'Document Name' },
    { id: 'Tag & Folders', label: 'Tag & Folders' },
    { id: 'Date', label: 'Date' },
    { id: 'Created / Uploaded by', label: 'Created / Uploaded by' },
    { id: 'Type of Document', label: 'Type of Document' },
    { id: 'Remarks / Note', label: 'Remarks / Note' },
    { id: 'attachments', label: 'Attachments' },
    { id: 'Actions', label: 'Actions' },
    
  ];
  const table = useTable();
  const remove = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [tagfodlers, settagfodlers] = useState([]);
  const [createdby, setcreatedby] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;


  useEffect(() => {
    getDocuments();
    getTagAndFolders()
    getCreatedBy()
  }, []);

  const getDocuments = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.documents.get_list);
    console.log('🚀 ~ getDocuments ~ data:', data);
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };
  const getTagAndFolders = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.documents.get_tag_fodlers);
    console.log('🚀 ~ getTagAndFolders ~ data:', data);
    setLoading(false);
    if (data) {
      settagfodlers(data);
    }
  };


  const getCreatedBy = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.documents.get_all_users);
    const transformedOptions = data?.map(user => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
    })) || [];
    console.log("🚀 ~ transformedOptions ~ transformedOptions:", transformedOptions)
    setLoading(false);
    if (data) {
      setcreatedby(transformedOptions);
    }
  };

  const deleteCallBack = () => {
    confirm.onFalse();
    getDocuments();
  };

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const handleDeleteRow = async (id) => {
    setDeleteLoading(true)
    await deleteRequest(`${endpoints.accounting.documents.delete}/${id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
    setDeleteLoading(false)
  }
  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log('🚀 ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_change_request.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getDocuments();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };


  const handleEditRow = (item) => {
    setSelectedItem(item); // Set the selected item for editing
    create.onTrue();
    //setIsModalOpen(true); // Open the modal
  };

  return (
    <>
      <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
        {' '}
        <CardContent>
          {loading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
              <Header name="DOCUMENTS" />
              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '20px',
                  justifyContent: 'space-between',
                  gap: '12px',
                }}
              >
                <div></div>

                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: '20px',
                    justifyContent: 'flex-end',
                    gap: '12px',
                  }}
                >
                  <Button
                    onClick={() => {
                      setSelectedItem({});
                      create.onTrue();
                    }}
                    className="dark-button"
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                  >
                    Create
                  </Button>
                </Box>
              </Box>

              <Card
                sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                }}
              >
                  <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    {actionVisible && (
                      <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                        <TableSelectedAction
                          dense={table.dense}
                          numSelected={table.selected.length}
                          rowCount={tableData.length}
                          onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                              setActionVisible(checked),
                              checked,
                              tableData.map((row) => row.id)
                            )
                          }
                          action={
                            <Tooltip title="Delete">
                              <IconButton color="primary" onClick={remove.onTrue}>
                                <Iconify icon="solar:trash-bin-trash-bold" />
                              </IconButton>
                            </Tooltip>
                          }
                        />
                      </div>
                    )}

                    <Scrollbar>
                      <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                        <TableHeadCustom
                          order={table.order}
                          orderBy={table.orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={tableData.length}
                          numSelected={table.selected.length}
                          onSort={table.onSort}
                          onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                              checked,
                              tableData.map((row) => row.id)
                            )
                          }
                        />
                        <TableBody>
                          {dataFiltered
                            .slice(
                              table.page * table.rowsPerPage,
                              table.page * table.rowsPerPage + table.rowsPerPage
                            )
                            .map((row,index) => (
                              <UserTableRow
                                //key={row.id}
                                row={row}
                                selected={table.selected.includes(row.id)}
                                onSelectRow={() => table.onSelectRow(row.id)}
                                onDeleteRow={() => handleDeleteRow(row.id)}
                                onEditRow={() => handleEditRow(row)}
                                serialNumber={table.page * table.rowsPerPage + index + 1} // Calculate the serial number

                              />
                            ))}

                          <TableEmptyRows
                            height={denseHeight}
                            emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                          />

                          <TableNoData notFound={notFound} />
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
        </CardContent>
      </Card>
      <DocumentsCreate
        open={create.value}
        onClose={create.onFalse}
        reload={() => getDocuments()}
        currentItem={selectedItem}
        closeform={() => handleCloseForm()}
        tagfodlers={tagfodlers} 
        createdby={createdby} 
      />


      <ConfirmDialog
        open={remove.value}
        onClose={remove.onFalse}
        title="Delete"
        content={<>Are you sure want to delete these items?</>}
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className="dark-button"
            onClick={() => {
              handleBulkDelete();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default Documents;
