import { useEffect, useState } from 'react';
import Header from '../header';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';

import { LoadingButton } from '@mui/lab';
import UserTableRow from './bank-table-row';
import BankInfoCreate from './bank-Info-create';


const BankInformation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true); 
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);

  const [showCreateView, setShowCreateView] = useState(false);

  const handleCreateClick = () => {
    setShowCreateView(true);
  };

  const handleBackClick = () => {
    setShowCreateView(false);
  };

  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const handleNavigate = () => {
    navigate('/accounts-management/create-bankinformation');  
  };


  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.no' },
    { id: 'Bank Name', label: 'Bank Name' },
    { id: 'Account Number', label: 'Account Number' },
    { id: 'Account Type', label: 'Account Type' },
    { id: 'Balance', label: 'Balance' },
    { id: 'Description', label: 'Description' },
    { id: 'Last Sync Date', label: 'Last Sync Date' },
    { id: 'Address', label: 'Address' },
    { id: 'Phone', label: 'Phone' },
    { id: 'Amount', label: 'Amount' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const remove = useBoolean();
  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  

  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
  
  useEffect(() => {
    getBankInformation();
  }, []);
  

  const getBankInformation = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.accounting.bank_information.get_list);
    console.log(' getBankInformation ~ data:', data);
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };


  const handleEditRow = async (item) => {
    try {
      setLoading(true);
      const data = await getRequest(`${endpoints.accounting.bank_information.show_list}/${item?.id}`);
      setSelectedItem(data);
      navigate("/accounts-management/create-bankinformation", { state: data });
      console.log('These are the items sent to be edited:', data);
    } catch (error) {
      console.error('Error fetching bank information:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCallBack = () => {
    confirm.onFalse();
    getBankInformation();
  };
  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); 
  };
  const handleDeleteRow = async (id) => {
    await deleteRequest(
      `${endpoints.accounting.bank_information.delete}/${id}`,
      deleteCallBack
    );
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  };
  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log(':rocket: ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.risk_change_request.bulk_delete,
          { ids: Ids }
        );
        console.log(':rocket: ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getBankInformation();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };
  return (
    <>
     
        <div>
          <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
            {' '}
            <CardContent>
              {loading ? (
                <Box height={700}>
                  <LoadingScreen />
                </Box>
              ) : (
                <>
                  <Header name="BANK & CREDIT CARD INFORMATION" />
                  <Box
                    sx={{
                      display: 'flex',
                      paddingBottom: '20px',
                      justifyContent: 'space-between',
                      gap: '12px',
                    }}
                  >
                    <div></div>
                    <Box
                      sx={{
                        display: 'flex',
                        paddingBottom: '20px',
                        justifyContent: 'flex-end',
                        gap: '12px',
                      }}
                    >
                      <Button
                        onClick={handleNavigate}
                        className="dark-button"
                        variant="contained"
                        startIcon={<Iconify icon="mingcute:add-line" />}
                      >
                        Create
                      </Button>
                    </Box>
                  </Box>
                  <Card
                    sx={{
                      boxShadow: '0px 0px 30px 0px #64748B1A',
                    }}
                  >
                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                      {actionVisible && (
                        <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                          <TableSelectedAction
                            dense={table.dense}
                            numSelected={table.selected.length}
                            rowCount={tableData.length}
                            onSelectAllRows={(checked) =>
                              table.onSelectAllRows(
                                setActionVisible(checked),
                                checked,
                                tableData.map((row) => row.id)
                              )
                            }
                            action={
                              <Tooltip title="Delete">
                                <IconButton color="primary" onClick={remove.onTrue}>
                                  <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                              </Tooltip>
                            }
                          />
                        </div>
                      )}
                      <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                          <TableHeadCustom
                            order={table.order}
                            orderBy={table.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={tableData.length}
                            numSelected={table.selected.length}
                            onSort={table.onSort}
                            onSelectAllRows={(checked) =>
                              table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                              )
                            }
                          />
                          <TableBody>
                            {dataFiltered
                              .slice(
                                table.page * table.rowsPerPage,
                                table.page * table.rowsPerPage + table.rowsPerPage
                              )
                              .map((row,index) => (
                                <UserTableRow
                                  key={row.id}
                                  row={row}
                                  selected={table.selected.includes(row.id)}
                                  onSelectRow={() => table.onSelectRow(row.id)}
                                  onDeleteRow={() => handleDeleteRow(row.id)}
                                  onEditRow={() => handleEditRow(row)}
                                  serialNumber={table.page * table.rowsPerPage + index + 1} 

                                />
                              ))}
                            <TableEmptyRows
                              height={denseHeight}
                              emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                            />
                            <TableNoData notFound={notFound} />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                      count={dataFiltered.length}
                      page={table.page}
                      rowsPerPage={table.rowsPerPage}
                      onPageChange={table.onChangePage}
                      onRowsPerPageChange={table.onChangeRowsPerPage}
                      dense={table.dense}
                      onChangeDense={table.onChangeDense}
                    />
                  </Card>
                </>
              )}
            </CardContent>
          </Card>
          <BankInfoCreate
            open={create.value}
            onClose={create.onFalse}
            reload={() => getBankInformation()}
            currentItem={selectedItem}
            closeform={() => handleCloseForm()}
          />
         
          <ConfirmDialog
            open={remove.value}
            onClose={remove.onFalse}
            title="Delete"
            content={<>Are you sure want to delete these items?</>}
            action={
              <LoadingButton
                loading={deleteLoading}
                variant="contained"
                className="dark-button"
                onClick={() => {
                  handleBulkDelete();
                }}
              >
                Delete
              </LoadingButton>
            }
          />
        </div>
     
    </>
  );
};
export default BankInformation;
