import { useEffect, useState, useMemo } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
import { currencyOptions } from './currencies-list';

const FinanceCreateForm = ({ open, onClose, reload, currentItem, closeform }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const NewUserSchema = Yup.object().shape({
        //statement_id: Yup.string()
        //    .required('Statement ID is required')
        //    .matches(/^[A-Za-z0-9]+$/, 'Statement ID must be alphanumeric'),
        //statement_type: Yup.string()
        //    .required('Statement Type is required'),
        //reporting_period: Yup.string()
        //    .required('Reporting Period is required'),
        //company_entity: Yup.string()
        //    .required('Company/Entity is required'),
        //department_division_filter: Yup.string()
        //    .required('Department/Division is required'),
        //account_grouping: Yup.string()
        //    .required('Account Grouping is required'),
        //currency_selection: Yup.object()
        //    .shape({
        //        label: Yup.string().required(),
        //        value: Yup.string().required(),
        //    })
        //    .nullable()
        //    .required('Currency Selection is required'),
        //rounding_level: Yup.string()
        //    .required('Rounding Level is required'),
        //include_subtotals_by_category: Yup.boolean(),
        //comparative_analysis: Yup.boolean(),
        //variance_analysis: Yup.boolean(),
        //auto_calculation_of_ratios: Yup.boolean(),
        //suggestion: Yup.string()
        //    .max(500, 'Remarks/Notes cannot exceed 500 characters'),
    });

    const roleOptions =
        [
            { label: "Sales Department", value: "sales_department" },
            {
                label: "Marketing Department", value: "marketing_department"
            },
            { label: "Operations Department", value: "operations_department" },
            { label: "IT Department", value: "it_department" },
            { label: "All Departments", value: "all_department" },
        ]

    const defaultValues = useMemo(
        () => ({
            statement_id: currentItem?.statement_id || '',
            statement_type: currentItem?.statement_type || '',
            reporting_period: currentItem?.reporting_period || '',
            company_entity: currentItem?.company_entity || '',
            department_division_filter: currentItem?.department_division_filter || '',
            account_grouping: currentItem?.account_grouping?.value || '',
            currency_selection: currentItem?.currency_selection || null,
            rounding_level: currentItem?.rounding_level || '',
            include_subtotals_by_category: currentItem?.include_subtotals_by_category || false,
            comparative_analysis: currentItem?.comparative_analysis || false,
            variance_analysis: currentItem?.variance_analysis || false,
            auto_calculation_of_ratios: currentItem?.auto_calculation_of_ratios || false,
            suggestion: currentItem?.suggestion || '',
        }),
        [currentItem]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });
    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [currentItem, reset, defaultValues]);

    const onSubmit = handleSubmit(async (data) => {
        console.log('Form data submitted:', data);
        setLoading(true);
        const formData = new FormData();
        formData.append('statement_id', data?.statement_id);
        formData.append('statement_type', data.statement_type?.value);
        formData.append('reporting_period', data.reporting_period?.value);
        formData.append('company_entity', data?.company_entity);
        formData.append('department_division_filter[]', data.department_division_filter);

        formData.append('account_grouping', data.account_grouping?.value);
        formData.append('currency_selection', data.currency_selection?.value);
        formData.append('rounding_level', data.rounding_level?.value);
        formData.append('include_subtotals_by_category', data.include_subtotals_by_category === 'true' ? 1 : 0);

        formData.append('comparative_analysis', data.comparative_analysis === 'true' ? 1 : 0);
        formData.append('variance_analysis', data.variance_analysis === 'true' ? 1 : 0);
        formData.append('auto_calculation_of_ratios', data.auto_calculation_of_ratios === 'true' ? 1 : 0);
        formData.append('notes_annotations', data.notes_annotations);
        try {
            const response = await postRequest(
                currentItem?.id
                    ? `${endpoints.accounting.finance_statement.post_list}/${currentItem.id}?_method=PUT`
                    : endpoints.accounting.finance_statement.post_list,
                formData,
                true
            );
            if (response.metadata && response.metadata.success) {
                reset();
                onClose();
                closeform();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response?.message || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });


    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
                    <span
                        className='cardColor'
                        style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                        &nbsp;&nbsp;  Finance Statement  &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <IconButton onClick={onClose}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        borderRadius: '1px',
                    }}
                >
                    <Box
                        mt={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >
                        <RHFTextField name="statement_id" label="Statement ID" />
                        <RHFAutocomplete
                            name="statement_type"
                            label="Statement Type"
                            options={[
                                { label: "Income Statement (Profit & Loss)", value: "income_statement" },
                                { label: "Balance Sheet", value: "balance_sheet" },
                                { label: "Cash Flow Statement", value: "cash_flow_statement" },
                                { label: "Trial Balance", value: "trial_balance" },
                                { label: "Statement of Changes in Equity", value: "statement_of_changes_in_equity" },
                                { label: "Custom Statement (user-defined combination)", value: "custom_statement" }
                            ]}

                            getOptionLabel={(option) => option.label || ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option}>{option.label}</li>}
                            error={!!methods.formState.errors.approval_status}
                            helperText={methods.formState.errors.approval_status?.message}
                        />
                        <RHFAutocomplete
                            name="reporting_period"
                            label="Reporting Period"
                            //defaultValue={null} 
                            options={[
                                { label: "This Month", value: "this_month" },
                                { label: "Last Month", value: "last_month" },
                                { label: "This Quarter", value: "this_quarter" },
                                { label: "Last Quarter", value: "last_quarter" },
                                { label: "This Year", value: "this_year" },
                                { label: "Last Year", value: "last_year" }
                            ]}
                            getOptionLabel={(option) => option.label || ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option}>{option.label}</li>}
                            error={!!methods.formState.errors.approval_status}
                            helperText={methods.formState.errors.approval_status?.message}
                        />
                        <RHFTextField name="company_entity" label="Company/ Entity" />

                        <FormControl sx={{ width: 230 }}>
                            <InputLabel>Department / Division</InputLabel>
                            <Select
                                name="department_division_filter"
                                label="Department / Division"
                                multiple
                                value={methods.watch('department_division_filter') || []}
                                onChange={(event) => methods.setValue('department_division_filter', event.target.value)}
                                input={<OutlinedInput label="Department / Division" />}
                                renderValue={(selected) => selected.join(', ')}
                                sx={{ width: '400px' }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: { maxHeight: 240 },
                                    },
                                }}
                            >
                                {roleOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <Checkbox disableRipple size="small" checked={methods.watch('department_division_filter').includes(option.value)} />
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <RHFAutocomplete
                            name="account_grouping"
                            label="Account Grouping"
                            options={[
                                {
                                    label: "By Account Type (e.g., Assets, Liabilities, Equity)",
                                    value: "account_type"
                                },
                                {
                                    label: "By Category (e.g., Operating Expenses, Revenue)",
                                    value: "category"
                                },
                                {
                                    label: "By Department (e.g., Sales, Marketing)",
                                    value: "department"
                                },
                                {
                                    label: "By Project (e.g., for project-specific financial tracking)",
                                    value: "project"
                                }
                            ]}
                            getOptionLabel={(option) => option.label || ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option}>{option.label}</li>}
                            error={!!methods.formState.errors.approval_status}
                            helperText={methods.formState.errors.approval_status?.message}
                        />
                        <RHFAutocomplete
                            name="currency_selection"
                            label="Currency Selection"
                            options={currencyOptions}
                            getOptionLabel={(option) => option.label || ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option}>{option.label}</li>}
                            error={!!methods.formState.errors.approval_status}
                            helperText={methods.formState.errors.approval_status?.message}
                        />
                        <RHFAutocomplete
                            name="rounding_level"
                            label="Rounding Level"
                            options={[
                                { label: "No Rounding (exact figures)", value: "no_rounding" },
                                { label: "Round to Nearest 1 (e.g., 1234)", value: "nearest_1" },
                                { label: "Round to Nearest 10 (e.g., 1230)", value: "nearest_10" },
                                { label: "Round to Nearest 100 (e.g., 1200)", value: "nearest_100" },
                                { label: "Round to Nearest 1000 (e.g., 1000)", value: "nearest_1000" },
                            ]}
                            getOptionLabel={(option) => option.label || ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option}>{option.label}</li>}
                            error={!!methods.formState.errors.approval_status}
                            helperText={methods.formState.errors.approval_status?.message}
                        />

                    </Box>
                    <Box
                        mt={2}
                        rowGap={1}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(1, 1fr)',
                        }}
                    >
                        <RHFSwitch
                            name="include_subtotals_by_category"
                            labelPlacement="start"
                            label={
                                <>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5, ml: 1.5 }}>
                                        Include Subtotsls by category
                                    </Typography>
                                </>
                            }
                            sx={{ mx: 0, width: 1, justifyContent: 'space-between', border: '1px solid #eee', backgroundColor: '#eee', borderRadius: '5px' }}
                        />
                        <RHFSwitch
                            name="comparative_analysis"
                            labelPlacement="start"
                            label={
                                <>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5, ml: 1.5 }}>
                                        Comparitive Analysis
                                    </Typography>
                                </>
                            }
                            sx={{ mx: 0, width: 1, justifyContent: 'space-between', border: '1px solid #eee', backgroundColor: '#eee', borderRadius: '5px' }}
                        />
                        <RHFSwitch
                            name="variance_analysis"
                            labelPlacement="start"
                            label={
                                <>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5, ml: 1.5 }}>
                                        Variance Analysis
                                    </Typography>
                                </>
                            }
                            sx={{ mx: 0, width: 1, justifyContent: 'space-between', border: '1px solid #eee', backgroundColor: '#eee', borderRadius: '5px' }}
                        />
                        <RHFSwitch
                            name="auto_calculation_of_ratios"
                            labelPlacement="start"
                            label={
                                <>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5, ml: 1.5 }}>
                                        Auto-Calculation of Ratios
                                    </Typography>
                                </>
                            }
                            sx={{ mx: 0, width: 1, justifyContent: 'space-between', border: '1px solid #eee', backgroundColor: '#eee', borderRadius: '5px' }}
                        />
                    </Box>
                    <Box style={{ marginTop: "15px" }}>
                        <RHFTextField name="notes_annotations" label="Remarks /Notes" multiline rows={4} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" className='dark-button'
                        loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
export default FinanceCreateForm;