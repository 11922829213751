// exportCsvData.js

export const getCsvData = (tableData = {}) => {
    return tableData?.map((row) => {
        const filteredRow = {};
        const fields = {
            customer_id: row?.customer_number || '',
            first_name: row?.first_name || '',
            last_name: row?.last_name || '',
            email: row?.email || '',
            company_name: row?.company_name || '',
            phone: row?.phone || '',
            adress: row?.adress || '',
            city: row?.city || '',
            state: row?.state || null,
            zip_code: row?.zip_code || '',
        };
  
        Object.keys(fields).forEach((key) => {
            if (key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};
